import React, { useEffect, useState } from "react";
import CircularProgress from '@material-ui/core/CircularProgress';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

function MediCard(props) {
    const [offset, setoffset] = useState(0)
    const [data, setData] = useState([])
    let [progress, setProgress] = useState(false);
    let [valSearch, setValSearch] = useState('');



    return (
        <>
            {progress && <div className="fullScreen">
                <CircularProgress
                    disableShrink
                    className="progressCircle"
                    value={90}
                    size={100}
                    thickness={1.5}
                />
            </div>}

            <section className="mediCard">
                <div className="top d-flex justify-content-between mb-3">
                    <div className="flex-grow-1 d-flex align-items-center">
                        <span className="roundedIcon">
                            <i className="fas fa-pills"></i>
                        </span>
                        <span className="ml-2 rx">
                            <i className="fas fa-prescription"></i>
                        </span>
                        <span className="ml-2">{props.title}</span>
                    </div>
                    {/* <span className="roundedIcon">
                        <i className="far fa-trash-alt fa-lg"></i>
                    </span> */}
                </div>
                {props.type && <div className="daterow d-flex justify-content-between">
                    <div className="dates d-flex flex-column">
                        <span className="label">
                            Type
                        </span>
                        <span className="mt-2">
                            {props.type}
                        </span>
                    </div>
                    <div className="dates d-flex flex-column">
                        <span className="label">
                            Setting
                        </span>
                        <span className="mt-2">
                            {props.setting}
                        </span>
                    </div>
                </div>
                }
                <div className="daterow d-flex justify-content-between">
                    <div className="dates d-flex flex-column">
                        <span className="label">
                            {props.sdTitle ? props.sdTitle :
                               props.langData?.length > 0
                                    ? props.langData.find(item => item.keyword === "allergy_start_date_lbl")?.[props.lang]
                                    : ""
                            }
                        </span>
                        <span className="mt-2">
                            {props.sd}
                        </span>
                    </div>
                    {props.ed && 
                        <div className="dates d-flex flex-column">
                            <span className="label">
                          
                               { props.langData?.length > 0
                                    ? props.langData.find(item => item.keyword === "allergy_end_date_lbl")?.[props.lang]
                                    : ""}
                           
                            </span>
                            <span className="mt-2">
                                {props.status === "completed" ? props.ed : ""}
                            </span>
                        </div>
                    }
                    <div className="dates d-flex flex-column">
                        <span className="label">
                        { props.langData?.length > 0
                                    ? props.langData.find(item => item.keyword === "allergy_status_lbl")?.[props.lang]
                                    : ""}
                           
                        </span>
                        <span className="mt-2">
                            <b>{props.status}</b>
                        </span>
                    </div>
                </div>
                {props.instruction &&
                    <div className="">
                        <Accordion>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel1a-content"
                                id="panel1a-header"
                            >
                                <div className="paneltitle">
                                    <div></div>
                                    <span>Instruction</span>
                                </div>

                            </AccordionSummary>
                            <AccordionDetails>
                                <p>
                                    {props.instruction}
                                </p>
                            </AccordionDetails>
                        </Accordion>
                    </div>
                }
            </section>
        </>
    )
}

const mapStateToProps = (state) => {
    return {
        formValues: state.form,
        langData: state.langData,
        lang: state.lang,
        state
    }
}

export default withRouter(connect(mapStateToProps, null)(MediCard));


