import React, { useEffect, useState } from "react";
import { withRouter } from 'react-router-dom';
import ErrorIcon from '@material-ui/icons/Error';
import CircularProgress from '@material-ui/core/CircularProgress';
import { jwt } from '../common/jwtAuthentication';
import { genericApi, SignupPatient, logOut } from '../common/apiconfig';
import FormHoc from '../globalcomponents/hoc/formHoc';
import { InputField, PasswordField, AutoOptions } from '../globalcomponents/formElements';
import { Dropdowns } from '../common/constants';
import { connect } from 'react-redux';
import * as constObj from '../common/constants';
import { ErrorLables } from '../common/lables';
import { funcDropdown, roles } from '../common/constants'
import SignupImg from '../img/Signup3.png';
import Tick_signup from '../img/opensquare/Tick_signup.svg'
import Facebook from '../img/facebook.png'
import { GoogleLogin } from 'react-google-login'
import FacebookLogin from 'react-facebook-login';
import PasswordStrengthChecker from '../globalcomponents/passwordstrengthchecker'
import { Button } from "react-bootstrap";

const PatientSignup = (propsobj) => {
  let [apiError, setApiError] = useState(false);
  let [progress, setProgress] = useState(false);
  let [userExsits, setUserExsits] = useState("")
  let [show, setShow] = useState(true)
  let [provider, setProvider] = useState("")
  let [showPassword, setShowPassword] = useState(false)
  let [errMsg, setErrMsg] = useState("")
  let [showForm, setShowForm] = useState(false)


  const hocObj = FormHoc(propsobj);


  const textProps = {
    ...hocObj,
    propsobj
  }

  const autoPropsToLang = {
    default: {
      value: 'English (US)',
      label: 'English (US)',
    },
    'options': Dropdowns.Languages,
    freeSolo: false,
    propsobj,
    keys: ['value']
  }

  const autoPropstorole = {
    ...autoPropsToLang,
    'options': Dropdowns.Contact_Role,
    default: null,
  }

  useEffect(() => {
    // funcDropdown()
    propsobj.UpdateFormField({ name: "profile_username", value: "" })
    propsobj.UpdateFormField({ name: "profile_app", value: propsobj.langData?.length > 0
      ? propsobj.langData.find(item => item.keyword === "signup_us_patient_lbl")?.[propsobj.lang]
      : ""  })
    propsobj.UpdateFormField({ name: "profile_password", value: "" })
    propsobj.UpdateFormField({ name: "patientId", value: "" })
    propsobj.UpdateFormField({ name: "profile_fname", value: "" })
    propsobj.UpdateFormField({ name: "profile_lname", value: "" })
    propsobj.UpdateFormField({ name: "profile_email", value: "" })
    propsobj.UpdateFormField({ name: "profile_lang", value: "" })
    setShow(true)
  }, []);

  const formSubmit = async (event) => {
    event.preventDefault();
    if (propsobj.formValues.profile_email.length > 0) {
      let email = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(propsobj.formValues.profile_email)
      if (propsobj.formValues.profile_email != "" && email == false) {
        let obj = {
          name: 'profile_email',
          flag: true,
          text: "Incorrect email",
        }
        propsobj.updateErrorObj(obj);
        return;
      } else {
        let obj = {
          name: 'profile_email',
          flag: false,
          text: ''
        }
        propsobj.updateErrorObj(obj);
        email = true
      }
    }
    console.log(propsobj.formValues, "propsobj.formValues")
    // if (propsobj.formValues.profile_app === "Patient Advocacy Group") {
    //   propsobj.history.push('/signuppag')
    //   localStorage.setItem("hideheaderitem", false)
    //   return
    // } else {
    let userExits
    let setGo
    setGo = await hocObj.handleSubmit('updatePatientForm', event);
    if (setGo) {
      let form = propsobj.formValues;
      console.log(form, "form")
      // checking confirm password
      if (form.profile_app === "Patient" && form.profile_cpassword.length > 0 && form.profile_password != form.profile_cpassword) {
        let obj = {
          name: 'profile_cpassword',
          flag: true,
          text: "Passwords mismatch",
        }
        propsobj.updateErrorObj(obj);
        return;
      } else {
        let obj = {
          name: 'profile_cpassword',
          flag: false,
          text: ''
        }
        propsobj.updateErrorObj(obj);
      }
      let requestBody
      if (form.profile_app != "Patient") {
        requestBody = {
          emailId: form.profile_email,
          "type": form.profile_app
        }
      }
      else {
        requestBody = {
          emailId: form.profile_email,
          userId: form.profile_email,
          password: form.profile_password,
          provider: provider
        }
      }
      setProgress(true);
      requestBody = JSON.stringify(requestBody);
      let res = form.profile_app != "Patient" ? await SignupPatient('signupuser', requestBody, false, "") : await SignupPatient('signuppatient', requestBody, false, "");
      console.log(res, "ressignup")
      if (res.code == "400") {
        setApiError(true);
        setErrMsg(res.message)
        setTimeout(function () { setApiError(false) }, 1000 * 30);
        setProgress(false);
      } else {
        console.log("Success", res)
        // alert('Thank You, Signup Successful')
        setShow(false)
        setProgress(false);
        propsobj.UpdateFormField({ name: "profile_password", value: "" })
        propsobj.UpdateFormField({ name: "profile_cpassword", value: "" })
        propsobj.UpdateFormField({ name: "profile_email", value: '' })

      }
    }
    // }
  }

  const handleCancel = (event) => {
    event.preventDefault();
    propsobj.history.goBack();
  }
  const responseGoogle = async (response) => {
    //console.log(response, "google");
    // setProgress(true);
    if (response) {
      // propsobj.UpdateFormField({ name: "profile_fname", value: response.profileObj.givenName })
      // propsobj.UpdateFormField({ name: "profile_lname", value: response.profileObj.familyName })
      // propsobj.UpdateFormField({ name: "profile_email", value: response.profileObj.email })
      var data = response.profileObj
      setProvider("google")
      callSignupApi(data.givenName, data.email, data.familyName, "google")
    }
  }
  const responseFacebook = async (response) => {
    //console.log(response);
    if (response) {
      // propsobj.UpdateFormField({ name: "profile_fname", value: response.name })
      // propsobj.UpdateFormField({ name: "profile_email", value: response.email })
      setProvider("facebook")
      callSignupApi(response.name, response.email, "", "facebook")
    }
  }

  const callSignupApi = async (fname, email, lname, provider) => {
    let requestBody = {
      emailId: email,
      userId: email,
      password: "",
      provider: provider
    }
    setProgress(true);
    requestBody = JSON.stringify(requestBody);
    let res = await SignupPatient('signuppatient', requestBody);
    console.log(res, "ressignup")
    if (res.code == "400") {
      setApiError(true);
      setErrMsg(res.message)
      setTimeout(function () { setApiError(false) }, 1000 * 30);
      setProgress(false);
    } else {
      console.log("Success", res)
      setShow(false)
      setProgress(false);
      propsobj.UpdateFormField({ name: "profile_email", value: '' })

    }
  }


  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const autoPropstoApp = {
      'options': [{ name: "Patient", value: propsobj.langData?.length > 0
        ? propsobj.langData.find(item => item.keyword === "signup_us_patient_lbl")?.[propsobj.lang]
        : "" }, { name: "Clinic", value: propsobj.langData?.length > 0
          ? propsobj.langData.find(item => item.keyword === "signup_us_clinic_lbl")?.[propsobj.lang]
          : ""  }, { name: "Hospital", value: propsobj.langData?.length > 0
            ? propsobj.langData.find(item => item.keyword === "signup_us_hospital_lbl")?.[propsobj.lang]
            : ""  }, { name: "PAG", value: propsobj.langData?.length > 0
              ? propsobj.langData.find(item => item.keyword === "signup_us_pag_lbl")?.[propsobj.lang]
              : "" }],
    default: { name: "Patient", value: propsobj.langData?.length > 0
      ? propsobj.langData.find(item => item.keyword === "signup_us_patient_lbl")?.[propsobj.lang]
      : "" },
    freeSolo: false,
    propsobj,
    keys: ['value']
  }

  // console.log(propsobj.formValues.profile_app,"yyyy")
  const patientHeaders = ['Patient','مريض','Paciente','Patiente','Geduldig'];



  return (
    <>
      {progress && <div className="fullScreen">
        <CircularProgress
          disableShrink
          className="progressCircle"
          value={90}
          size={100}
          thickness={1.5}
        />
      </div>}

      <div className="row" style={{ justifyContent: "center", alignItems: "center" }}>
        {apiError && <>
          <div className="hekError">
            <span className='icon'>
              <ErrorIcon />
            </span>
            <span className='texts'>
              {errMsg}
            </span>
          </div>
        </>}
        {show ? <>
          <div className="col-md-12">
            <AutoOptions {...autoPropstoApp} name="profile_app" label= { propsobj.langData?.length > 0
                    ? propsobj.langData.find(item => item.keyword === "signup_sign_up_us_lbl")?.[propsobj.lang]
                    : ""} keyName='value' must={'true'} />
            <div className="social-login mt-3 mb-3">

              <div style={{
                display: "flex",
                aligItems: "center",
                justifyContent: "space-around"
              }}>
                <>
                  <FacebookLogin
                    appId="1631763070619665"
                    // autoLoad={true}
                    size="small"
                    fields="name,email,picture"
                    // cssClass="my-facebook-button-class"
                    icon="fa-facebook"
                    textButton={ propsobj.langData?.length > 0
                      ? propsobj.langData.find(item => item.keyword === "signup_signup_lbl")?.[propsobj.lang]
                      : ""}
                    // onClick={componentClicked}
                    callback={responseFacebook} />
                </>
                <div className="google">
                  <GoogleLogin
                    clientId="188642766391-dpunmqp1q1uohi1ri3o9r9dns6lhl7f4.apps.googleusercontent.com"
                    buttonText={ propsobj.langData?.length > 0
                      ? propsobj.langData.find(item => item.keyword === "signup_signup_lbl")?.[propsobj.lang]
                      : ""}
                    disabled={false}
                    onSuccess={responseGoogle}
                    // onFailure={responseGoogle}
                    cookiePolicy={'single_host_origin'}
                  />
                </div>
                <div style={{ width: "27%" }}><button className="btn btn-primary-blue btn-rounded btn-small btn-block" style={{
                  padding: "6px",
                  fontSize: "14px",
                  textTransform: "capitalize"
                }} onClick={() => setShowForm(!showForm)}> <i className="fa fa-solid fa-envelope mr-1" style={{ paddingRight: "7px" }}></i>{ propsobj.langData?.length > 0
                  ? propsobj.langData.find(item => item.keyword === "signup_signup_lbl")?.[propsobj.lang]
                  : ""}</button></div>
              </div>
            </div>
            {showForm &&
              <form noValidate autoComplete="off" id="updatePatientForm" className="inputForm" onSubmit={formSubmit}>
                <div className="inputBox">
                  <InputField {...textProps} textDirection={propsobj.lang === "ar_AA" ? true : false} name="profile_email" label={ propsobj.langData?.length > 0
            ? propsobj.langData.find(item => item.keyword === "signup_enter_emailid_lbl")?.[propsobj.lang]
            : ""} must={'true'} type="email" />

                  {patientHeaders.includes(propsobj.formValues.profile_app ) &&  <>  
                    <PasswordField {...textProps} textDirection={propsobj.lang === "ar_AA" ? true : false} handleClickShowPassword={handleClickShowPassword} handleMouseDownPassword={handleMouseDownPassword} name="profile_password" showPassword={showPassword} label={ propsobj.langData
            ? propsobj.langData.find(item => item.keyword === "signup_enter_password_lbl")?.[propsobj.lang]
            : ""} must={propsobj.formValues.profile_app === "Patient" ? 'true' : 'false'} type="password" />
                    <>
                      {propsobj.formValues.profile_password.length > 3 &&
                        <PasswordStrengthChecker chkpassword={propsobj.formValues.profile_password} />
                      }
                    </>
                    <InputField {...textProps} name="profile_cpassword" textDirection={propsobj.lang === "ar_AA" ? true : false} label={ propsobj.langData?.length > 0
            ? propsobj.langData.find(item => item.keyword === "signup_enter_confirm_password_lbl")?.[propsobj.lang]
            : ""} must={propsobj.formValues.profile_app === "Patient" ? 'true' : 'false'} type="password" />
                  </>
                  }
                </div>
                <div className="form-group">
                  <button type="submit" className="btn btn-primary-blue btn-rounded btn-large btn-block mt-3">{ propsobj.langData?.length > 0
                    ? propsobj.langData.find(item => item.keyword === "signup_btn_lbl")?.[propsobj.lang]
                    : ""}</button>
                </div>
              </form>
            }
          </div>
          {/* <div className="col-md-12 mt-3">
            <div className="social-login">
              <ul className="list-inline d-flex align-items-center">
                <li className="list-inline-item static-link-txt">or Sign Up using</li>
                <li className="list-inline-item">
                  <FacebookLogin
                    appId="1631763070619665"
                    // autoLoad={true}
                    size="small"
                    fields="name,email,picture"
                    // cssClass="my-facebook-button-class"
                    icon="fa-facebook"
                    textButton="Signup"
                    // onClick={componentClicked}
                    callback={responseFacebook} />
                </li>
                <li className="list-inline-item google">
                  <GoogleLogin
                    clientId="188642766391-dpunmqp1q1uohi1ri3o9r9dns6lhl7f4.apps.googleusercontent.com"
                    buttonText="Signup"
                    disabled={false}
                    onSuccess={responseGoogle}
                    // onFailure={responseGoogle}
                    cookiePolicy={'single_host_origin'}
                  />
                </li>
              </ul>
            </div>
          </div> */}
        </> : <>{propsobj.formValues.profile_app != "Patient" ?
          <div style={{ textAlign: "center", color: "#933df2" }}><img src={Tick_signup} alt="signup" height={50} /><br />{ propsobj.langData?.length > 0
            ? propsobj.langData.find(item => item.keyword === "please_check_email_lbl")?.[propsobj.lang]
            : ""} <br />
            <span>{ propsobj.langData?.length > 0
            ? propsobj.langData.find(item => item.keyword === "complete_signup_lbl")?.[propsobj.lang]
            : ""} </span></div> :
          <div style={{ textAlign: "center" }}>
            <div><img src={Tick_signup} alt="signup" height={50} /> </div>
            <div style={{ color: "#933df2", fontSize: "25px" }}><b>{ propsobj.langData?.length > 0
                    ? propsobj.langData.find(item => item.keyword === "signin_congratulation_lbl")?.[propsobj.lang]
                    : ""}</b></div>
            <div>{ propsobj.langData?.length > 0
                    ? propsobj.langData.find(item => item.keyword === "sigin_acc_created_lbl")?.[propsobj.lang]
                    : ""}</div><br /><div style={{ textTransform: "uppercase", color: "#933df2", fontSize: "25px" }}>{ propsobj.langData?.length > 0
                    ? propsobj.langData.find(item => item.keyword === "sigin_login_proceed_lbl")?.[propsobj.lang]
                    : ""}</div></div>}
        </>}
      </div>
    </>
  )
}

const mapStateToProps = (state) => {
  return {
    formValues: state.form,
    langData: state.langData,
    lang: state.lang,
    state
  }
}
const mapDispatchToProps = (dispatch) => {
  return {
    UpdateFormField: (obj) => {
      return dispatch(
        {
          type: 'UpdateFormField',
          value: obj.value,
          name: obj.name
        }
      )
    },
    UpdateEntireForm: (obj) => {
      return dispatch(
        {
          type: 'UpdateEntireForm',
          value: obj.value,
        }
      )
    },
    clearForm: () => {
      return dispatch(
        {
          type: 'ClearForm',
        }
      )
    },
    updateErrorObj: (obj) => {
      return dispatch(
        {
          type: 'updateErrorObj',
          flag: obj.flag,
          name: obj.name,
          text: obj.text
        }
      )
    },
    updateEntireErrorObj: (errobj, errtextobj) => {
      return dispatch(
        {
          type: 'updateEntireErrorObj',
          errobj: errobj,
          errtextobj: errtextobj,
        }
      )
    }

  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(PatientSignup));