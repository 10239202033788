import React, { useEffect, useState } from "react";
import CircularProgress from '@material-ui/core/CircularProgress';
import { Route, Switch, Link, BrowserRouter as Router, Redirect, NavLink } from 'react-router-dom';
import MHistoryHome from './dashboard/mHistoryHome';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Menus, NavSubMenu } from '../navMenu';
import AuditTrail from './auditTrail';

import PatientDetail from './patientDetail';


// const menus = [
//     {
//         to: "/patient/detail",
//         text: "Patient Detail",
//     },
//     {
//         to: "/patient/medicalhistory",
//         text: "Medical Histor",
//     },
//     {
//         to: "/patient/audittrail",
//         text: "Audit Trail",
//     },
// ]




function DetailHome(props) {
    const [offset, setoffset] = useState(0)
    const [data, setData] = useState([])
    let [progress, setProgress] = useState(false);
    let [valSearch, setValSearch] = useState('');

    const menus = [
        {
            to: "/patient/detail",
            text: props.langData?.length > 0 ? props.langData.find(item => item.keyword === "patient_details_lbl")?.[props.lang] : '' ,
        },
        {
            to: "/patient/medicalhistory",
            text: props.langData?.length > 0 ? props.langData.find(item => item.keyword === "medical_history_lbl")?.[props.lang] : '',
        },
        {
            to: "/patient/audittrail",
            text: props.langData?.length > 0 ? props.langData.find(item => item.keyword === "audit_trail_lbl")?.[props.lang] : '',
        },]

    return (
        <>
            {progress && <div className="fullScreen">
                <CircularProgress
                    disableShrink
                    className="progressCircle"
                    value={90}
                    size={100}
                    thickness={1.5}
                />
            </div>}

            <section className="mb-3 mt-3">
                <Menus menus={menus} />
            </section>

            <Switch>
                <Route path="/patient/detail">
                    <PatientDetail />
                </Route>
                <Route path="/patient/medicalhistory">
                    <MHistoryHome />
                </Route>
                <Route path="/patient/audittrail">
                    <AuditTrail />
                </Route>
                <Redirect to="/patient/detail" />
            </Switch>
        </>
    )

}


const mapStateToProps = (state) => {
    return {
      formValues: state.form,
      langData: state.langData,
      lang: state.lang,
      state
    }
  }

export default withRouter(connect(mapStateToProps)(DetailHome));

