import React, { useEffect, useState } from "react";
import MaterialTable from 'material-table';
import { apiCall } from '../../common/apiconfig';
import { removeDuplicates } from '../../../common/constants'
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import CircularProgress from '@material-ui/core/CircularProgress';
import { HekmaTextSearchField } from '../formElements';
import InputAdornment from '@material-ui/core/InputAdornment';
import * as R_Items from '../recentItems';
import SearchIcon from '@material-ui/icons/Search';
import { checkPermission } from '../../common/utils';
import Materialtable from '../../../globalcomponents/materialtable'
function ContactGrid(props) {
  let isAddContact = checkPermission('Contact_Create', 'Create');
  const [offset, setoffset] = useState(0)
  const [data, setData] = useState([])
  let [progress, setProgress] = useState(false);
  let [valSearch, setValSearch] = useState('');


  async function fetchData(searchKey) {
    try {

      if (searchKey.length && searchKey != undefined) {
        const getContacts = `
        sponsorAccountUserId
        sponsorAccountId
        SponsorAccountName
        firstName
        lastName
        email
        phone
        role
        roleId
        position
        language
        invitedBy
        invitedDate
        activated
        portalAccessInd
        primaryContactInd
        createdBy
        createdTs
        modifiedBy
        modifiedTs`;
        let requestBody = {
          query: `query {
          getAccountUserSearch(searchStr: "${searchKey}") {
                      ${getContacts}
                }
            }`
        };

        requestBody = JSON.stringify(requestBody);
        setProgress(true);
        let res = await apiCall('getAccountUserSearch', requestBody);
        setProgress(false);
        if (!res) {
          // Error handling
          console.log('Error on response material grid!')

        } else {
          if (res.data.getAccountUserSearch.length) {
            var data = removeDuplicates(res.data.getAccountUserSearch, item => item.sponsorAccountUserId)
            setData(data)
          } else {
            setData([])
          }
        }
      }
    } catch (error) {
    }
  }

  useEffect(() => {
    if (props.state.headerSearchValue) {
      fetchData(props.state.headerSearchValue)
      // setValSearch(props.state.headerSearchValue)
    } else {
      fetchData(props.formValues.contactSearchText != "" ? props.formValues.contactSearchText : process.env.REACT_APP_ENVTYPE === "prod" ? "*" : "");
      setValSearch(props.formValues.contactSearchText != "" ? props.formValues.contactSearchText : "")
    }

    // props.clearForm()
  }, []);

  const handlePageClick = (offset) => {
    setoffset(offset)
  }

  const handleClick = async (event) => {
    event.preventDefault();
    props.UpdateFormField({ name: "addContactFromContactSearch", value: true })
    props.UpdateFormField({ value: "", name: "accountName" })
    props.UpdateFormField({ value: "", name: "sponsorAccountId" })
    props.UpdateFormField({ name: "contactaddformclear", value: true })
    //used for navigation in details page
    props.UpdateFormField({ name: "siteUserHeading", value: "" });
    props.history.push('/account/addContact');
  }

  const handleRowClick = async (event, rowData) => {
    // save clicked rowdata in redux, make sure the logic to empty back
    await props.setSidebarActiveEl('contacts');
    let dataToR_Items = {
      id: rowData.sponsorAccountUserId,
      sponsorAccountId: rowData.sponsorAccountId,
      firstName: rowData.firstName
    }

    // setProgress(true);
    let response = await R_Items.updateRecentItems(dataToR_Items, R_Items.rI_contacts);
    if (response) {
      let contactItems = await R_Items.getRecentSearchOrItems(R_Items.rI_contacts);
      if (contactItems) {
        let obj = {
          obj: R_Items.rI_contacts,
          value: contactItems
        }
        props.updateRecentItems(obj);
      }
    }
    // setProgress(false);
    await props.UpdateEntireForm(rowData);
    // if (props.formValues.primaryContactSearch == true) {
    //   props.UpdateFormField({ value: rowData.firstName + ' ' + rowData.lastName, name: "primaryContact" })
    //   props.UpdateFormField({ name: "primaryContactSearch", value: false })
    //   props.history.goBack()
    // } else {
    await props.UpdateFormField({ name: "siteUserHeading", value: "" });
    props.history.push('/account/contact/view');
    // }
  }
  const searchHandle = (event) => {
    event.preventDefault();
    let value = event.target.value;
    setValSearch(value);
  }
  const searchSubmit = async (event) => {
    event.preventDefault();
    if (valSearch.length) {
      fetchData(valSearch);
      await props.UpdateFormField({ name: "contactSearchText", value: valSearch })
      await props.updateHeaderSearchValue({ value: "" })
    }
  }
  const clearSearch = (event) => {
    event.preventDefault()
    fetchData('');
    setValSearch("")
  }
  const Columns = [
    {
      title: 'ID', field: 'sponsorAccountUserId',
      cellStyle: {
        color: '#244271',
        fontWeight: 500,
        textAlign: 'left'
      },
    },
    {
      title: 'Name', field: 'firstName',
      cellStyle: {
        color: '#244271',
        fontWeight: 500
      },
      render: rowData => rowData.firstName + ' ' + rowData.lastName
    },
    { title: 'Role', field: 'role' },
    { title: 'Email', field: 'email' },
    { title: 'Phone', field: 'phone' },
  ]

  return (
    <>
      {progress && <div className="fullScreen">
        <CircularProgress
          disableShrink
          className="progressCircle"
          value={90}
          size={100}
          thickness={1.5}
        />
      </div>}
      <div className="d-flex mb-3 justify-content-end">
        <h1 className="mr-auto primary-header">{props.formValues.primaryContactSearch ? "SELECT CONTACT" : "CONTACTS"}</h1>
        {isAddContact === "1" ?
          <div className="">
            <button onClick={handleClick} className="btn btn-primary-blue btn-rounded">
              <i className="fa fa-plus-circle mr-1"></i> ADD CONTACT</button>
          </div> : ""}
      </div>
      <div className="listing-wrap hekmaGrid">
        <div className="card">
          <div className="hekmaGridBox">
            <div className="col-xs-12 col-sm-12 col-md-5">
              <form noValidate autoComplete="off" onSubmit={searchSubmit}>
                <HekmaTextSearchField
                  name="accountgridsearch"
                  // label="Search Account"
                  placeholder="Search Contacts"
                  value={valSearch}
                  handleChange={searchHandle}
                  className="gridSearchField"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start" onClick={searchSubmit} style={{ cursor: "pointer" }}>
                        <SearchIcon />
                      </InputAdornment>
                    ),

                    endAdornment: (
                      <InputAdornment position="end" onClick={clearSearch} style={{ cursor: "pointer" }}>
                        X
                      </InputAdornment>
                    ),
                  }}
                />
              </form>
            </div>
            <Materialtable
              columns={Columns}
              data={data}
              handleRowClick={handleRowClick}
            />
          </div>
        </div>
      </div>
    </>
  )

}

const mapStateToProps = (state) => {
  return {
    formValues: state.form,
    state
  }
}
const mapDispatchToProps = (dispatch) => {
  return {
    UpdateFormField: (obj) => {
      return dispatch(
        {
          type: 'UpdateFormField',
          value: obj.value,
          name: obj.name
        }
      )
    },
    UpdateEntireForm: (obj) => {
      return dispatch(
        {
          type: 'UpdateEntireForm',
          value: obj,
        }
      )
    },
    updateHeaderSearchValue: (obj) => {
      return dispatch(
        {
          type: 'headerSearch',
          value: obj.value,
        }
      )
    },
    updateRecentItems: (obj) => {
      return dispatch(
        {
          type: 'updateRecentItems',
          value: obj,
        }
      )
    },
    setSidebarActiveEl: (name) => {
      return dispatch(
        {
          type: 'sidebarActiveEl',
          value: name,
        }
      )
    },
  }
}
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ContactGrid));


