import React, { useEffect, useState } from "react";
import { withRouter } from 'react-router-dom';
import Icon from '@material-ui/core/Icon';
import { Button } from '@material-ui/core'
import CircularProgress from '@material-ui/core/CircularProgress';
import { apiCall, logOut } from '../../../common/apiconfig';
import FormHoc from '../../hoc/formHoc';
import { InputField, AutoOptions } from '../../formElements';
import { Dropdowns } from '../../../common/constants';
import { connect } from 'react-redux';
import HekmaDatePicker from '../../../siteSrc/components/HekmaDatePicker'
import moment from 'moment'

const AddAllergy = (propsobj) => {
  console.log(propsobj.formValues,"IE")
  let [apiError, setApiError] = useState(false);
  let [progress, setProgress] = useState(false);

  useEffect(() => {
    propsobj.UpdateFormField({ value: "", name: "MC_allergyname" })
    propsobj.UpdateFormField({ value: "", name: "MC_category" })
    propsobj.UpdateFormField({ value: "", name: "MC_severity" })
    propsobj.UpdateFormField({ value: "", name: "MC_reaction" })
    propsobj.UpdateFormField({ value: "", name: "MC_startdate" })
    propsobj.UpdateFormField({ value: "", name: "MC_stopdate" })
    propsobj.UpdateFormField({ value: "", name: "MC_reactionseverity" })

  }, []);

  const hocObj = FormHoc(propsobj);

  const textProps = {
    ...hocObj,
    propsobj
  }

  const autoPropsTotype = {
    default: null,
    'options': Dropdowns.Protocol_Setup_IE_Criteria_Type,
    freeSolo: false,
    propsobj,
    keys: ['value']
  }

  const autoPropstostatus = {
    ...autoPropsTotype,
    default: {
      value: 'Active',
      label: 'Active',
    },
    'options': Dropdowns.Protocol_Setup_IE_Criteria_Status,
  }


  const formSubmit = async (event) => {
    event.preventDefault();
    let setGo = await hocObj.handleSubmit('addIECriteria', event);
    if (setGo) {
      setProgress(true);
      let form = propsobj.formValues;
      console.log(form)
      let requestBody = `
      mutation 
      {
        createStudyEvent(effectiveDate: "${moment(form.IE_effectiveDate).format('YYYY-MM-DD')}", studyId: "${form.studyId}" ,eventType: "${form.IE_type[0]}",type: "${form.IE_type}", description: ${JSON.stringify(form.IE_description)},status:"${form.IE_status == "" ? "Active" : form.IE_status}", severity:"") {
          code
          type
          message
          attributes
        }  
      }
          `;

      requestBody = JSON.stringify({ query: requestBody });
      let res = await apiCall('createStudyEvent', requestBody, form);
      setProgress(false);

      if (res && res.data.createStudyEvent.code === '200') {
        // propsobj.UpdateFormField({value:propsobj.formValues.accountName,name:"accountName"})
        // propsobj.clearForm();
        propsobj.history.push('/account/studyDetails/IEcriteria');
      } else {
        console.log('Error on response add account file!')
        //propsobj.history.push('/account/updateContact'); // temp enabling to check update sponsor
      }
    }
  }

  const handleCancel = (event) => {
    event.preventDefault();
    // propsobj.UpdateFormField({value:propsobj.formValues.accountName,name:"accountName"})
    propsobj.history.goBack();
  }

  const goBack = (event) => {
    event.preventDefault();
    propsobj.history.goBack()
  }

  return (
    <>
      {progress && <div className="fullScreen">
        <CircularProgress
          disableShrink
          className="progressCircle"
          value={90}
          size={100}
          thickness={1.5}
        />
      </div>}
      {/* <div onClick={goBack} className="subNavText"><img src={BackIcon} alt="goback" />  BACK TO I-E CRITERIA LIST</div><br /> */}

      <div className="d-flex mt-4 justify-content-end">
        <div className="mr-auto secondary-header">{propsobj.langData?.length > 0 ? propsobj.langData.find(item => item.keyword === "add_allergy_lbl")?.[propsobj.lang] : ''}</div>
      </div>

      <div className="">
        <form noValidate autoComplete="off" id="addIECriteria" className="row" onSubmit={formSubmit}>
          <div className="col-md-6">
            <div className="panel-primary-wrap">
              <div className="card">
                <div className="card-body">
                  {/* <div className="card-page-header">NAME INFORMATION</div> */}
                  <div className="inputBox">
                    <InputField {...textProps} textDirection={propsobj.lang === "ar_AA" ? true : false} name="MC_allergyname" label={propsobj.langData?.length > 0 ? propsobj.langData.find(item => item.keyword === "add_allergy_name_lbl")?.[propsobj.lang] : ''} must={'true'} />
                    <InputField {...textProps} textDirection={propsobj.lang === "ar_AA" ? true : false} name="MC_category" label={propsobj.langData?.length > 0 ? propsobj.langData.find(item => item.keyword === "add_allergy_category_lbl")?.[propsobj.lang] : ''} must={'true'} />
                    <AutoOptions {...autoPropstostatus} textDirection={propsobj.lang === "ar_AA" ? true : false}name="MC_severity" label={propsobj.langData?.length > 0? propsobj.langData.find(item => item.keyword === "add_allergy_severity_lbl")?.[propsobj.lang] : ''} keyName='value' must={'true'} />
                    <InputField {...textProps} textDirection={propsobj.lang === "ar_AA" ? true : false} name="MC_reaction" label={propsobj.langData?.length > 0 ? propsobj.langData.find(item => item.keyword === "add_allergy_reaction_lbl")?.[propsobj.lang] : ''} must={'true'} />
                    <HekmaDatePicker textDirection={propsobj.lang === "ar_AA" ? true : false} name="MC_startdate" label={propsobj.langData?.length > 0 ? propsobj.langData.find(item => item.keyword === "add_allergy_start_date_lbl")?.[propsobj.lang] : ''} must={'true'} minDate={new Date()}/>
                    <HekmaDatePicker textDirection={propsobj.lang === "ar_AA" ? true : false} name="MC_stopdate" label={propsobj.langData?.length > 0 ? propsobj.langData.find(item => item.keyword === "add_allergy_stop_date_lbl")?.[propsobj.lang] : ''} must={'true'} minDate={propsobj.formValues.MC_startdate}/>
                 
                    <InputField {...textProps} name="MC_reactionseverity" textDirection={propsobj.lang === "ar_AA" ? true : false} label={propsobj.langData?.length > 0 ? propsobj.langData.find(item => item.keyword === "add_allergy_reaction_severity_lbl")?.[propsobj.lang] : ''} must={'true'} />
                   
                  </div>
                </div>
              </div>
            </div>
            <div className="buttonContainer">
              <button type="submit" className="btn btn-primary-blue btn-rounded btn-small mr-1">{propsobj.langData?.length > 0 ? propsobj.langData.find(item => item.keyword === "add_allergy_submit_lbl")?.[propsobj.lang] : ''}</button>
              <button className="btn btn-primary-grey btn-rounded btn-small" onClick={handleCancel}>{propsobj.langData?.length > 0 ? propsobj.langData.find(item => item.keyword === "add_allergy_cancel_lbl")?.[propsobj.lang] : ''}</button>
            </div>
          </div>
        </form>
      </div>
    </>
  )
}

const mapStateToProps = (state) => {
  return {
    formValues: state.form,
    langData: state.langData,
    lang: state.lang,
    state
  }
}
const mapDispatchToProps = (dispatch) => {
  return {
    UpdateFormField: (obj) => {
      return dispatch(
        {
          type: 'UpdateFormField',
          value: obj.value,
          name: obj.name
        }
      )
    },
    clearForm: () => {
      return dispatch(
        {
          type: 'ClearForm',
        }
      )
    },
    updateErrorObj: (obj) => {
      return dispatch(
        {
          type: 'updateErrorObj',
          flag: obj.flag,
          name: obj.name,
          text: obj.text
        }
      )
    },
    updateEntireErrorObj: (errobj, errtextobj) => {
      return dispatch(
        {
          type: 'updateEntireErrorObj',
          errobj: errobj,
          errtextobj: errtextobj,
        }
      )
    },
  }
}
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(AddAllergy));



