import React, { useEffect, useState } from "react";
import CircularProgress from '@material-ui/core/CircularProgress';
import ProfileData from '../../../../globalcomponents/patient360/profileData';
import { oldgenericApi, logOut } from '../../../../common/apiconfig';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';

function DashboardHome(props) {
  const [data, setData] = useState('')
  console.log(props.langData,"lll");
  console.log(props.lang,"mmm")
  let [progress, setProgress] = useState(false);
  let [id, setId] = useState('')
  useEffect(() => {
    fetchData();
  }, [])
  // useEffect(() => {
  //   fetchData();
  // }, [data])

  async function fetchData() {
    let requestBody = `
        query {
            getPatientDetails {
              patientId
              subjectId
              alternateId
              firstName
              middleName
              lastName
              email
              phone
              address1
              address2
              city
              state
              zip
              country
              language
              createdBy
              createdTs
              modifiedBy
              modifiedTs
            } 
                            }
                        `;

    requestBody = JSON.stringify({ query: requestBody });
    setProgress(true);

    let res = await oldgenericApi('getPatientDetails', requestBody);
    setProgress(false);

    //if token not validated allow user to get new token by showing link expired, click now to activate again
    if (!res) {
      //if (false) {
      console.log('Error 119 at profile js')
      //setApiError(true);
      // setTimeout(function () { setApiError(false) }, 1000 * 30);
    } else {
      let data = res.data.getPatientDetails;
      console.log(data, "patient")
      callGetPatientStudyData()
      setData(data)
      await props.UpdateFormField({ name: "patientdata", value: data })
      // localStorage.setItem("patientEmail", data.email)
    }
  }

  const callGetPatientStudyData = async () => {
    let requestBody = {
      query: `query {        
         getStudyProgress(patientId:"${localStorage.getItem('loginUserId')}") {
        studyID
        accountID
        siteID
        scheduleID
      }
   }`
    };
    requestBody = JSON.stringify(requestBody);
    let res = await oldgenericApi('getStudyProgress', requestBody);
    if (!res) {
      // Error handling
      //console.log('Error on getting patient data!')

    } else {
      if (res.data.getStudyProgress.length > 0) {
        console.log(res.data.getStudyProgress, "res.data.getStudyProgress")
        props.UpdateFormField({ name: "patientstudyInfo", value: res.data.getStudyProgress })
      }
    }
  }

  const oneUpHealth = () => {
    // props.UpdateFormField({ name: "patientdata", value: data })
    props.history.push('/patient/1uphealth')
  }
  return (
    <>
      {progress && <div className="fullScreen">
        <CircularProgress
          disableShrink
          className="progressCircle"
          value={90}
          size={100}
          thickness={1.5}
        />
      </div>}
      <div className="d-flex justify-content-end mt-4">
        <h1 className="mr-auto primary-header">
          {/* Hi there! */}
          {props.langData?.length > 0 ? props.langData.find(item => item.keyword === "dashboard_hi_there_lbl")?.[props.lang] : ""}

          </h1> 
        <button className="btn btn-primary-blue btn-rounded btn-small" style={{ float: "right" }} onClick={oneUpHealth}>
          {/* <a href="/patient/1uphealth"> */}
          {props.langData?.length > 0 ? props.langData.find(item => item.keyword === "dashboard_add_health_record_lbl")?.[props.lang] : ""}
          {/* </a> */}
        </button>

      </div>
      {/* <PatientDropdown/> */}
      <div className="" style={{ fontSize: '14px', color: '#666' }}>
        {/* Welcome to Hekma! Get all health data in minutes! */}
        {props.langData?.length > 0 ? props.langData.find(item => item.keyword === "dashboard_welcome_lbl")?.[props.lang] : ""}! {props.langData ? props.langData.find(item => item.keyword === "dashboard_all_health_data_lbl")?.[props.lang] : ""}

 
        </div>
      {/* {id != null ?  */}
      <div id="back-to-top-anchor" />
      {typeof data === 'object' && data !== null &&
        <ProfileData data={data} app="Patient" />
      }
      {/* : ""}  */}
    </>
  )

}

const mapStateToProps = (state) => {
  return {
    formValues: state.form,
    langData:state.langData,
    lang:state.lang,
    state
  }
}
const mapDispatchToProps = (dispatch) => {
  return {
    UpdateFormField: (obj) => {
      return dispatch(
        {
          type: 'UpdateFormField',
          value: obj.value,
          name: obj.name
        }
      )
    },
    clearForm: () => {
      return dispatch(
        {
          type: 'ClearForm',
        }
      )
    },
    updateErrorObj: (obj) => {
      return dispatch(
        {
          type: 'updateErrorObj',
          flag: obj.flag,
          name: obj.name,
          text: obj.text
        }
      )
    },
    updateEntireErrorObj: (errobj, errtextobj) => {
      return dispatch(
        {
          type: 'updateEntireErrorObj',
          errobj: errobj,
          errtextobj: errtextobj,
        }
      )
    }
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(DashboardHome));



