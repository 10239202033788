import React, { useEffect, useState } from "react";
import { withRouter } from 'react-router-dom';
import CircularProgress from '@material-ui/core/CircularProgress';
import { ViewRow } from '../../globalcomponents/formElements';
import { connect } from 'react-redux';
import Icon from '@material-ui/core/Icon';
import { Button } from '@material-ui/core';
import { loginApi, oldgenericApi } from '../../common/apiconfig';
import Loader from '../../common/loader'
// import { checkPermission } from '../../common/utils';


const Profile = (propsobj) => {
  console.log(propsobj.formValues, "profile")
  // let isEditIE = checkPermission('IE_Criteria_Edit', 'Update');  
  let [apiError, setApiError] = useState(false);
  let [progress, setProgress] = useState(false);
  const [data, setData] = useState([])
  useEffect(() => {
    const callUserAPi = async () => {
      let requestBody = `
      query {
        getPatientDetails {
          patientId
          subjectId
          alternateId
          firstName
          middleName
          lastName
          email
          phone
          address1
          address2
          city
          state
          zip
          country
          language
             }
         }
     `;

      requestBody = JSON.stringify({ query: requestBody });
      setProgress(true);

      let res = await oldgenericApi('getPatientDetailss', requestBody);
      setProgress(false);

      //if token not validated allow user to get new token by showing link expired, click now to activate again
      if (!res) {
        console.log('Error')
      } else {
        let data = res.data.getPatientDetails;
        setData(data)
        console.log(data, "patientdata")
        propsobj.UpdateFormField({ name: "patientdata", value: data })
        propsobj.UpdateFormField({ name: "patientId", value: data.patientId })
        propsobj.UpdateFormField({ name: "userfname", value: data.firstName })
        propsobj.UpdateFormField({ name: "userlname", value: data.lastName })
        propsobj.UpdateFormField({ name: "usermname", value: data.middleName })
        propsobj.UpdateFormField({ name: "userlang", value: data.language })
        propsobj.UpdateFormField({ name: "userphone", value: data.phone })
        propsobj.UpdateFormField({ name: "useremail", value: data.email })
        // propsobj.UpdateFormField({ name: "useraccountName", value: data.SponsorAccountName })
        // propsobj.UpdateFormField({ name: "usersponsorAccountId", value: data.sponsorAccountUserId })
      }


    }
    callUserAPi()
  }, [])
  const handleClick = async (event) => {
    event.preventDefault();
    propsobj.history.push('/patient/updateprofile');
  }

  const { userfname = '', userlname = '', usermname = '', userlang = "", userrole = "", userphone = "", useraccountName = "", useremail = "", usersponsorAccountId = "" } = propsobj.formValues;

  return (
    <>
      <Loader progress={progress} />
      <div className="row mt-3">
        {/* <div className="col-md-3">

        </div> */}
        <div className="col-md-6">
          <div className="d-flex">

            {/* <div class="secondary-header mr-auto">Profile</div> */}
            <h1 className="mr-auto primary-header"> { propsobj.langData?.length > 0
                    ? propsobj.langData.find(item => item.keyword === "profile_lbl")?.[propsobj.lang]
                    : ""} </h1>
            <div className="">
              <button onClick={handleClick} className="btn btn-small btn-primary-blue btn-shadow btn-rounded">
                <i className="fas fa-pencil-alt"></i>&nbsp;  { propsobj.langData?.length > 0
                    ? propsobj.langData.find(item => item.keyword === "profile_edit_lbl")?.[propsobj.lang]
                    : ""} </button>
            </div>


          </div>
          {/* <div className="col-md-12"> */}
          <div className="panel-primary-wrap">
            <div className="card">
              <div className="card-body">
                {/* <div className="card-page-header"><span>Profile</span><span style={{ float: "right" }}><button onClick={handleClick} className="btn btn-small btn-primary-blue btn-shadow btn-rounded">
                    <i className="fas fa-pencil-alt"></i>&nbsp; EDIT</button></span></div> */}
                <form className=" h-form-1">
                  <div className="inputBox">
                    <ViewRow text={ propsobj.langData?.length > 0
                    ? propsobj.langData.find(item => item.keyword === "profile_firstname_lbl")?.[propsobj.lang]
                    : ""}  textvalue={data.firstName} />
                    <ViewRow text={ propsobj.langData?.length > 0
                    ? propsobj.langData.find(item => item.keyword === "profile_middlename_lbl")?.[propsobj.lang]
                    : ""}  textvalue={data.middleName} />
                    <ViewRow text={ propsobj.langData?.length > 0
                    ? propsobj.langData.find(item => item.keyword === "profile_ln_lbl")?.[propsobj.lang]
                    : ""}  textvalue={data.lastName} />
                    {/* <ViewRow text="Accountname" textvalue={useraccountName} />    */}
                    <ViewRow text={ propsobj.langData?.length > 0
                    ? propsobj.langData.find(item => item.keyword === "profile_language_lbl")?.[propsobj.lang]
                    : ""}  textvalue={data.language} />
                    {/* <ViewRow text="Role" textvalue={userrole} /> */}
                    <ViewRow text={ propsobj.langData?.length > 0
                    ? propsobj.langData.find(item => item.keyword === "profile_phone_lbl")?.[propsobj.lang]
                    : ""} textvalue={data.phone} />
                    <ViewRow text={ propsobj.langData?.length > 0
                    ? propsobj.langData.find(item => item.keyword === "profile_email_lbl")?.[propsobj.lang] 
                    : ""} textvalue={data.email} />

                  </div>
                </form>
                {/* </div> */}
              </div>
            </div>
          </div>

        </div>
      </div>

    </>
  )
}

const mapStateToProps = (state) => {
  return {
    formValues: state.form,
    langData: state.langData,
    lang: state.lang,
  }
}
const mapDispatchToProps = (dispatch) => {
  return {
    UpdateFormField: (obj) => {
      return dispatch(
        {
          type: 'UpdateFormField',
          value: obj.value,
          name: obj.name
        }
      )
    },
  }
}
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Profile));



