import React, { useEffect, useState } from "react";
import { withRouter } from 'react-router-dom';
import ErrorIcon from '@material-ui/icons/Error';
import CircularProgress from '@material-ui/core/CircularProgress';
import { jwt } from '../../common/jwtAuthentication';
import { genericApi, oldgenericApi, logOut } from '../../common/apiconfig';
import FormHoc from '../../globalcomponents/hoc/formHoc';
import { InputField, AutoOptions } from '../../globalcomponents/formElements';
import { Dropdowns } from '../../common/constants';
import { connect } from 'react-redux';
import * as constObj from '../../common/constants';
import { ErrorLables } from '../../common/lables';
import { funcDropdown, roles } from '../../common/constants'
import SignupImg from '../../img/Signup3.png';
import Google from '../../img/Google.png'
import Facebook from '../../img/facebook.png'
import { GoogleLogin } from 'react-google-login'
import FacebookLogin from 'react-facebook-login';
import PasswordStrengthChecker from '../../globalcomponents/passwordstrengthchecker'

const PatientSignup = (propsobj) => {
  let [apiError, setApiError] = useState(false);
  let [progress, setProgress] = useState(false);
  let [userExsits, setUserExsits] = useState("")
  let [provider, setProvider] = useState("")

  const hocObj = FormHoc(propsobj);

  const textProps = {
    ...hocObj,
    propsobj
  }

  const autoPropsToLang = {
    default: {
      value: 'English (US)',
      label: 'English (US)',
    },
    'options': Dropdowns.Languages,
    freeSolo: false,
    propsobj,
    keys: ['value']
  }

  const autoPropstorole = {
    ...autoPropsToLang,
    'options': Dropdowns.Contact_Role,
    default: null,
  }

  useEffect(() => {
    propsobj.UpdateFormField({ name: "profile_username", value: "" })
    propsobj.UpdateFormField({ name: "profile_password", value: "" })
    async function fetchData() {
      //////////////////
      let queryParam = new URLSearchParams(propsobj.location.search);
      let accessToken = queryParam.get('token');
      jwt.setCookie('jwtToken', accessToken, 1);
      await funcDropdown()
      if (accessToken && accessToken.length) {
        //if(true){    
        jwt.setCookie('jwtToken', accessToken, 1);//update token

        let requestBody = `
                query {
                    getPatientDetails {
                      patientId
                      subjectId
                      alternateId
                      firstName
                      middleName
                      lastName
                      email
                      phone
                      address1
                      address2
                      city
                      state
                      zip
                      country
                      language

                    } 
                                    }
                                `;

        requestBody = JSON.stringify({ query: requestBody });
        setProgress(true);

        let res = await oldgenericApi('getPatientDetails', requestBody);
        setProgress(false);

        //if token not validated allow user to get new token by showing link expired, click now to activate again
        if (!res) {
          //console.log('Error 119 at profile js')
        } else {
          let data = res.data.getPatientDetails;
          propsobj.UpdateFormField({ name: "patientId", value: data.patientId })
          propsobj.UpdateFormField({ name: "profile_fname", value: data.firstName })
          propsobj.UpdateFormField({ name: "profile_lname", value: data.lastName })
          propsobj.UpdateFormField({ name: "profile_email", value: data.email })
          propsobj.UpdateFormField({ name: "profile_lang", value: data.language })
          propsobj.UpdateFormField({ name: "profile_username", value: "" })
          propsobj.UpdateFormField({ name: "profile_password", value: "" })
        }
      }
    }
    fetchData();
    propsobj.UpdateFormField({ name: "hidestudytab", value: false })
    return () => {
      propsobj.UpdateFormField({ name: "hidestudytab", value: true })
      jwt.setCookie('jwtToken', '', 0);
    }
  }, []);

  const formSubmit = async (event) => {
    event.preventDefault();
    let userExits
    let setGo
    let requestBody = {
      query: `query {
                  checkLoginIdExist(userId:"${propsobj.formValues.profile_username}")
                }`
    };
    setProgress(true);
    requestBody = JSON.stringify(requestBody);
    let res = await oldgenericApi('checkLoginIdExist', requestBody);
    setProgress(false);
    console.log(res, "checkLoginIdExist")
    if (!res) {

    } else {
      userExits = res.data.checkLoginIdExist
    }
    if (userExits) {
      setUserExsits(propsobj.formValues.profile_username + " username already exists")
    } else {
      setGo = await hocObj.handleSubmit('updatePatientForm', event);
      setUserExsits('')
    }
    console.log(setGo, "SetGo",userExits,"userExits")
    if (setGo) {
      let form = propsobj.formValues;
      console.log(form, "form")
      // checking confirm password
      if (form.profile_cpassword.length > 0 && form.profile_password != form.profile_cpassword) {
        let obj = {
          name: 'profile_cpassword',
          flag: true,
          text: "Passwords mismatch",
        }
        propsobj.updateErrorObj(obj);
        return;
      } else {
        let obj = {
          name: 'profile_cpassword',
          flag: false,
          text: ''
        }
        propsobj.updateErrorObj(obj);
      }

      let requestBody = `
            mutation {
    updatePatientLoginCredentials(
                    patientId: "${form.patientId}",
                    firstName: "${form.profile_fname}", 
                    lastName :"${form.profile_lname}",
                    email :"${form.profile_email}",
                    language:"${(form.profile_lang ==" " || form.profile_lang ==null || !form.profile_lang) ? "English (US)" : form.profile_lang}",
                    userId:"${form.profile_username}",
                    password:"${form.profile_password}",
                    provider:"${provider}"
                   ) {
                  code
                  type
                  message
                  attributes
              }
            }
          `;
      setProgress(true);
      requestBody = JSON.stringify({ query: requestBody });
      let res = await oldgenericApi('updatePatientLoginCredentials', requestBody);
      setProgress(false);
      if (!res) {
        //console.log('Error on update profile');
        //setApiError(true);
        //setTimeout(function () { setApiError(false) }, 1000 * 30);
      } else {
        let requestBody1 = `
        mutation {
          createEHRIntegratorUserId(
            hekmaPatientId: "${form.patientId}",
            ehrSytemIntegrator: "1uphealth", 
            isBulkUseCase :false,
               ) {
              code
              type
              message
              attributes
          }
        }
      `;
        setProgress(true);
        requestBody = JSON.stringify({ query: requestBody1 });
        let res = await oldgenericApi('createEHRIntegratorUserId', requestBody);
        setProgress(false);
        if (!res) {
          //console.log('Error on 1uphealth add patient');
          //setApiError(true);
          //setTimeout(function () { setApiError(false) }, 1000 * 30);
        } else {

          jwt.setCookie('jwtToken', '', 0);
          // var url = window.location.hostname
          // window.location.replace(`${url}/login`);
          logOut()
        }
      }
    }
  }

  const handleCancel = (event) => {
    event.preventDefault();
    propsobj.history.goBack();
  }
  const responseGoogle = async (response) => {
    //console.log(response, "google");
    // setProgress(true);
    if (response) {
      propsobj.UpdateFormField({ name: "profile_fname", value: response.profileObj.givenName })
      propsobj.UpdateFormField({ name: "profile_lname", value: response.profileObj.familyName })
      propsobj.UpdateFormField({ name: "profile_email", value: response.profileObj.email })
      setProvider("google")
    }
  }
  const responseFacebook = async (response) => {
    //console.log(response);
    if (response) {
      propsobj.UpdateFormField({ name: "profile_fname", value: response.name })
      propsobj.UpdateFormField({ name: "profile_email", value: response.email })
      setProvider("facebook")
    }
  }

  return (
    <>
      {progress && <div className="fullScreen">
        <CircularProgress
          disableShrink
          className="progressCircle"
          value={90}
          size={100}
          thickness={1.5}
        />
      </div>}

      <div className="row" style={{ justifyContent: "center", alignItems: "center",margin:'15px'}}>
        <div className="col-md-6">
          <form noValidate autoComplete="off" id="updatePatientForm" className="inputForm" onSubmit={formSubmit}>

            <div className="panel-primary-wrap">
              <div className="card">
                <div className="card-body">
                  <div className="card-page-header google">SIGNUP <span style={{ float: "right", marginTop: "9px" }}>
                    {/* <img src={Google} style={{ marginRight: "15px" }} />&nbsp;&nbsp;&nbsp; */}
                    <GoogleLogin
                      clientId="188642766391-dpunmqp1q1uohi1ri3o9r9dns6lhl7f4.apps.googleusercontent.com"
                      buttonText="Login"
                      onSuccess={responseGoogle}
                      // onFailure={responseGoogle}
                      cookiePolicy={'single_host_origin'}
                    />
                    <FacebookLogin
                      appId="1631763070619665"
                      // autoLoad={true}
                      size="small"
                      fields="name,email,picture"
                      // cssClass="my-facebook-button-class"
                      icon="fa-facebook"
                      textButton="Login"
                      // onClick={componentClicked}
                      callback={responseFacebook} />
                    {/* <img src={Facebook} />*/}
                  </span>
                  </div>
                  <div className="inputBox">

                    <InputField {...textProps} name="profile_fname" label="First Name" must={'true'} type="text" />

                    <InputField {...textProps} name="profile_lname" label="Last Name" must={'true'} type="text" />
                    <div style={{ position: "relative" }}>
                      <InputField {...textProps} name="profile_username" label="User Name" must={'true'} type="text" />
                      <span style={{ color: "#f44336", fontSize: "12px", fontWeight: "400" }}>{userExsits}</span>
                    </div>

                    <InputField {...textProps} name="profile_email" label="Email" must={'true'} type="email" />

                    <InputField {...textProps} name="profile_password" label="Password" must={'true'} type="password" />
                    {propsobj.formValues.profile_password.length > 3 &&
                      <PasswordStrengthChecker chkpassword={propsobj.formValues.profile_password} />
                    }
                    <InputField {...textProps} name="profile_cpassword" label="Confirm Password" must={'true'} type="password" />

                    <AutoOptions {...autoPropsToLang} name="profile_lang" label="Language" keyName='value' must={'true'} />
                  </div>
                </div>
              </div>
            </div>

            <div className="buttonContainer col-12 ml-auto">
              <button type="submit" className="btn btn-primary-blue btn-rounded btn-small">Save</button>
              {/* <button className="btn btn-primary-grey btn-rounded btn-small" onClick={handleCancel}>CANCEL</button> */}
            </div>
          </form>
        </div>
        <div className="col-md-6" style={{ textAlign: "center" }}>
          {/* <div className="card">
            <div className="card-body"> */}
          <p><span style={{ fontSize: "25px", color: "#003680" }}>Hekma’s mission</span> is to deliver powerful real time AI driven insights for smarter and efficient ways to monitor clinical trial and healthcare ...
          </p>
          <img src={SignupImg} />
          {/* </div>
          </div> */}
        </div>
      </div>
    </>
  )
}

const mapStateToProps = (state) => {
  return {
    formValues: state.form,
    state
  }
}
const mapDispatchToProps = (dispatch) => {
  return {
    UpdateFormField: (obj) => {
      return dispatch(
        {
          type: 'UpdateFormField',
          value: obj.value,
          name: obj.name
        }
      )
    },
    UpdateEntireForm: (obj) => {
      return dispatch(
        {
          type: 'UpdateEntireForm',
          value: obj.value,
        }
      )
    },
    clearForm: () => {
      return dispatch(
        {
          type: 'ClearForm',
        }
      )
    },
    updateErrorObj: (obj) => {
      return dispatch(
        {
          type: 'updateErrorObj',
          flag: obj.flag,
          name: obj.name,
          text: obj.text
        }
      )
    },
    updateEntireErrorObj: (errobj, errtextobj) => {
      return dispatch(
        {
          type: 'updateEntireErrorObj',
          errobj: errobj,
          errtextobj: errtextobj,
        }
      )
    }

  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(PatientSignup));