import React, { useEffect, useState } from "react";
import { SidebarMenus } from '../../components/navMenu';
import * as R_Items from '../recentItems';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import $ from 'jquery';

// const menus = [
//     {
//         to: "/patient/dashboard",
//         // to:"#",
//         text: "Dashboard",
//         clsNames: "fa fa-fw fa-tachometer-alt"
//     },
//     {
//         to: "/patient/resources",
//         text: "Resources",
//         // name: "resources",
//         clsNames: "far fa-fw fa-user-circle"
//     }
// ]

const Sidebar = (props) => {
    let [contacts, setContacts] = useState([]);
    let [accounts, setAccounts] = useState([]);
    let [studies, setStudies] = useState([]);
    const menus = [
        {
            to: "/patient/dashboard",
            // to:"#",
            text: props.langData?.length > 0
            ? props.langData.find(item => item.keyword === "dashboard_lbl")?.[props.lang]
            : "",
            clsNames: "fa fa-fw fa-tachometer-alt"
        },
        {
            to: "/patient/resources",
            text: props.langData?.length > 0
            ? props.langData.find(item => item.keyword === "resources_lbl")?.[props.lang]
            : "",
            // name: "resources",
            clsNames: "far fa-fw fa-user-circle"
        },]
    useEffect(() => {
        //fetchData();
        $("#sidebarToggle, #sidebarToggleTop").on('click', function (e) {
            $("body").toggleClass("sidebar-toggled");
            $(".sidebar").toggleClass("toggled");
            // if ($(".sidebar").hasClass("toggled")) {
            //     $('.sidebar .collapse').collapse('hide');
            // };
        });
    }, [props.state.contactsI, props.state.studiesI, props.state.accountsI]);

    async function fetchData() {
        let contactItems = await R_Items.getRecentSearchOrItems(R_Items.rI_contacts);
        if (contactItems) {
            setContacts(contactItems);
        }

        let actItems = await R_Items.getRecentSearchOrItems(R_Items.rI_accounts);
        if (actItems) {
            setAccounts(actItems);
        }

        let studyItems = await R_Items.getRecentSearchOrItems(R_Items.rI_studies);
        if (studyItems) {
            setStudies(studyItems);
        }
    }

    const loadData = (arrObj, type, whatToShow) => {
        let lists = arrObj.map((item, i) => {
            return <a className="collapse-item" key={i} onClick={() => {
                RedirectToPage(item, type)
            }}>{item[whatToShow]}</a>
        })
        return lists;
    }

    const RedirectToPage = (item, type) => {
        if (type == 'contacts') {
            props.history.push('/account/contact/view',[item]);
        }

        if (type == 'studies') {
            props.history.push('/account/studyDetails',[item]);
        }

        if (type == 'accounts') {
            props.history.push('/account/accountDetails',[item]);
        }
    }

    return (
        <>

            {/* <!-- Sidebar --> */}
            <ul className="navbar-nav bg-gradient-primary sidebar sidebar-dark accordion" id="accordionSidebar">
                {/* <!-- Sidebar - Brand -->
            <!-- Sidebar Toggler (Sidebar) --> */}
                <div className="sidebar-brand d-flex align-items-center d-none d-md-inline">
                    <button className="border-0" id="sidebarToggle"></button>
                </div>
                
                <SidebarMenus menus={menus} />

                {/* <!-- Divider --> */}
                <hr className="sidebar-divider" />
                {/* <!-- Nav Item - Pages Collapse Menu --> */}
                {/* <li className="nav-item">
                    <a className="nav-link mb-0" href="#" data-toggle="collapse" data-target="#collapsePages" aria-expanded="true" aria-controls="collapsePages">
                        <i className="fa fa-clipboard-list"></i>
                        <span>Recent Items</span>
                    </a>

                    <div id="collapsePages" className="collapse show h-collapse recentItems" aria-labelledby="headingPages" data-parent="#accordionSidebar">
                        <div className="py-2 collapse-inner h-parent">

                            {contacts.length > 0 && <>
                                <a className="btn btn-link btn-link-txt btn-block" href="#level-2" role="button" aria-expanded="false" aria-controls="collapseExample">
                                    Contacts
                            </a>
                                <div className="collapse show" id="level-2">
                                    <div className="collapse-inner multi-level text-truncate">
                                        {loadData(contacts, 'contacts','id')}
                                    </div>
                                </div>
                            </>}



                            {accounts.length > 0 && <>
                                <a className="btn btn-link btn-link-txt btn-block" href="#level-2" role="button" aria-expanded="false" aria-controls="collapseExample">
                                    Accounts
                            </a>
                                <div className="collapse show" id="level-2">
                                    <div className="collapse-inner multi-level text-truncate">
                                        {loadData(accounts, 'accounts','id')}
                                    </div>
                                </div>
                            </>}



                            {studies.length > 0 && <>
                                <a className="btn btn-link btn-link-txt btn-block" href="#level-2" role="button" aria-expanded="false" aria-controls="collapseExample">
                                    Studies
                            </a>
                                <div className="collapse show" id="level-2">
                                    <div className="collapse-inner multi-level text-truncate">
                                        {loadData(studies, 'studies','id')}
                                    </div>
                                </div>
                            </>}

                        </div>
                    </div>
                </li> */}
            </ul>
            {/* <!-- End of Sidebar --> */}
        </>

    )
}
//export default Sidebar;
const mapStateToProps = (state) => {
    return {
        langData: state.langData,
        lang: state.lang,
        state
    }
}
const mapDispatchToProps = (dispatch) => {
    return {}
}
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Sidebar));


