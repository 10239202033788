import React, { useEffect, useState } from "react";
import Materialtable from '../../../../globalcomponents/materialtable';
import { apiCall } from '../../../common/apiconfig';
import { removeDuplicates } from '../../../../common/constants';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import CircularProgress from '@material-ui/core/CircularProgress';
import { HekmaTextSearchField } from '../../formElements';
import InputAdornment from '@material-ui/core/InputAdornment';
// import * as R_Items from '../recentItems';
import SearchIcon from '@material-ui/icons/Search';
import { checkPermission } from '../../../common/utils';

function Users(props) {
  let isAddUser = checkPermission('User_Create', 'Create');
  const [offset, setoffset] = useState(0)
  const [data, setData] = useState([])
  let [progress, setProgress] = useState(false);
  let [valSearch, setValSearch] = useState('');


  async function fetchData(searchKey) {
    const getContacts = `
        sponsorAccountUserId
        sponsorAccountId
        SponsorAccountName
        firstName
        middleName
        lastName
        email
        phone
        role
        position
        language
        invitedBy
        invitedDate
        activated
        primaryContactInd
        createdBy
        createdTs
        modifiedBy
        modifiedTs`;
    if (searchKey != undefined) {
      let requestBody = {
        query: `query {
          getAccountPortalUserSearch(searchStr: "${searchKey}") {
                      ${getContacts}
                }
            }`
      };

      requestBody = JSON.stringify(requestBody);
      setProgress(true);
      let res = await apiCall('getAccountPortalUserSearch', requestBody);
      setProgress(false);
      if (!res) {
        // Error handling
        console.log('Error on response material grid!')

      } else {
        if (res.data.getAccountPortalUserSearch.length) {
          var data = removeDuplicates(res.data.getAccountPortalUserSearch, item => item.sponsorAccountUserId)
          setData(data)
        } else {
          setData([])
        }

      }
    }
  }
  useEffect(() => {
    // fetchData(props.searchKey);
    fetchData(process.env.REACT_APP_ENVTYPE === "prod" ? "*" : "");
    
    // props.clearForm()
  }, []);

  const handlePageClick = (offset) => {
    setoffset(offset)
  }

  const handleClick = async (event) => {
    event.preventDefault();
    props.UpdateFormField({ name: "addContactFromContactSearch", value: true })
    props.UpdateFormField({ value: "", name: "accountName" })
    props.UpdateFormField({ value: "", name: "sponsorAccountId" })
    props.UpdateFormField({ name: "contactaddformclear", value: true })
    props.history.push('/account/users/add');
  }

  const handleRowClick = async (event, rowData) => {
    // save clicked rowdata in redux, make sure the logic to empty back

    console.log('rowData.sponsorAccountUserId::', typeof rowData.sponsorAccountUserId)

    let dataToR_Items = {
      id: rowData.sponsorAccountUserId,
      name: rowData.sponsorAccountUserId,
      other: rowData.sponsorAccountUserId
    }


    setProgress(true);
    //await R_Items.updateRecentItems(rowData.sponsorAccountUserId,R_Items.rI_contacts);
    // await R_Items.updateRecentItems(dataToR_Items,R_Items.rI_contacts);

    setProgress(false);
    await props.UpdateEntireForm(rowData);
    props.history.push('/account/users/view');
  }
  const searchHandle = (event) => {
    event.preventDefault();
    let value = event.target.value;
    setValSearch(value);
  }
  const searchSubmit = (event) => {
    event.preventDefault();
    if (valSearch.length) {
      fetchData(valSearch);
    }
  }
  const clearSearch = (event) => {
    event.preventDefault()
    fetchData('');
    setValSearch("")
  }
  const Columns = [
    {
      title: 'ID', field: 'sponsorAccountUserId',
      cellStyle: {
        color: '#244271',
        fontWeight: 500,
        textAlign: 'left'
      },
    },
    {
      title: 'Name', field: 'firstName',
      cellStyle: {
        color: '#244271',
        fontWeight: 500
      },
      render: rowData => rowData.firstName + ' ' + rowData.lastName
    },
    { title: 'Role', field: 'role' },
    { title: 'Email', field: 'email' },
    { title: 'Phone', field: 'phone' },
  ]

  return (
    <>
      {progress && <div className="fullScreen">
        <CircularProgress
          disableShrink
          className="progressCircle"
          value={90}
          size={100}
          thickness={1.5}
        />
      </div>}
      <div className="d-flex mb-3 justify-content-end">
        <h1 className="mr-auto primary-header">USERS</h1>
        {isAddUser === "1" ?
          <div className="">
            <button onClick={handleClick} className="btn btn-primary-blue btn-rounded">
              <i className="fa fa-plus-circle mr-1"></i> ADD USER</button>
          </div> : ""
        }
      </div>
      <div className="listing-wrap hekmaGrid">
        <div className="card">
          <div className="hekmaGridBox">
            <div className="col-xs-12 col-sm-12 col-md-5">
              <form noValidate autoComplete="off" onSubmit={searchSubmit}>
                <HekmaTextSearchField
                  name="accountgridsearch"
                  // label="Search Account"
                  placeholder="Search Users"
                  value={valSearch}
                  handleChange={searchHandle}
                  className="gridSearchField"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start" onClick={searchSubmit} style={{ cursor: "pointer" }}>
                        <SearchIcon />
                      </InputAdornment>
                    ),

                    endAdornment: (
                      <InputAdornment position="end" onClick={clearSearch} style={{ cursor: "pointer" }}>
                        X
                      </InputAdornment>
                    ),
                  }}
                />
              </form>
            </div>

            <Materialtable
              columns={Columns}
              data={data}
              handleRowClick={handleRowClick}
            />
          </div>
        </div>
      </div>
    </>
  )

}

const mapStateToProps = (state) => {
  return {
    formValues: state.form,
  }
}
const mapDispatchToProps = (dispatch) => {
  return {
    UpdateFormField: (obj) => {
      return dispatch(
        {
          type: 'UpdateFormField',
          value: obj.value,
          name: obj.name
        }
      )
    },
    UpdateEntireForm: (obj) => {
      return dispatch(
        {
          type: 'UpdateEntireForm',
          value: obj,
        }
      )
    },
  }
}
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Users));


