import React, { useEffect, useState } from "react";
import CircularProgress from '@material-ui/core/CircularProgress';

function DiseaseTopBar(props) {
    const [offset, setoffset] = useState(0)
    const [data, setData] = useState([])
    let [progress, setProgress] = useState(false);
    let [valSearch, setValSearch] = useState('');


   

    const handleClick = async (event, btnName) => {
        props.handleChange(event, btnName)
    }

    return (
        <>
            {progress && <div className="fullScreen">
                <CircularProgress
                    disableShrink
                    className="progressCircle"
                    value={90}
                    size={100}
                    thickness={1.5}
                />
            </div>}
            
            <div className="mhsearchBox pb-3 d-flex align-items-center diseaseTopBar">
                <span className="secondary-header">{props.title}</span>
                <div className="totaldisease ml-3">
                    <span className="roundedIcon">
                        {/* <i className={props.icon} style={{ color: props.icolor }}></i> */}
                        <img style={{ color: props.icolor }} src={props.icon} alt="img" />
                    </span>
                    <span className="ml-2 number">{props.total}</span>
                    <span className="ml-2">{props.des1}</span>
                </div>
                <div className="totaldisease ml-3 status">
                    <div className="statuscard">
                        <span className="d-flex align-items-center"><i className="fas fa-circle mr-1 " style={{ color: props.color1 }}></i> {props.text1}</span>
                        <span className="number">{props.num1}</span>
                    </div>
                    <div className="statuscard">
                        <span className="d-flex align-items-center"><i className="fas fa-circle mr-1 " style={{ color: props.color2 }}></i>{props.text2}</span>
                        <span className="number">{props.num2}</span>
                    </div>
                    {props.text3 &&
                        <div className="statuscard">
                            <span className="d-flex align-items-center"><i className="fas fa-circle mr-1 " style={{ color: props.color3 }}></i> {props.text3}</span>
                            <span className="number">{props.num3}</span>
                        </div>
                    }
                </div>
             {props.app === "Patient" &&
                    <div className="ml-auto">
                        <button className="btn btn-primary-blue btn-rounded" onClick={(e) => handleClick(e, props.addBtnText)}>
                            <i className="fa fa-plus-circle mr-1"></i>{props.addBtnText}</button>
                    </div>
                } 
            </div>

        </>
    )

}

export default DiseaseTopBar;


