import React, { useEffect, useState } from "react";
import Header from './header/header';
import Headerwithicon from './header/headerWithIcon';

import Sidebar from './sidebar/sidebar';
import SiteHome from './contentBody/siteHome';

const MainPage = (props) => {
    const location = props.location;
    const removeSidebar = ["/activateContact", "/activatePatient"];
    return (
        <>
            <div id="wrapper">
                {!removeSidebar.includes(location.pathname) && <Sidebar />}
                <div className="contentBody">
                    {removeSidebar.includes(location.pathname) ? <Headerwithicon/> : <Header />}
                    <SiteHome />
                </div>
            </div>
        </>
    )
}
export default MainPage;