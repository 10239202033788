import React, { useEffect, useState } from "react";
import CircularProgress from '@material-ui/core/CircularProgress';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

function AuditTrail(props) {
    const [offset, setoffset] = useState(0)
    const [data, setData] = useState([])
    let [progress, setProgress] = useState(false);
    let [valSearch, setValSearch] = useState('');

    return (
        <>
            {progress && <div className="fullScreen">
                <CircularProgress
                    disableShrink
                    className="progressCircle"
                    value={90}
                    size={100}
                    thickness={1.5}
                />
            </div>}

            <span>{props.langData?.length > 0 ? props.langData.find(item => item.keyword === "audit_trail_lbl")?.[props.lang] : ''} </span>
        </>
    )

}

const mapStateToProps = (state) => {
    return {
      formValues: state.form,
      langData: state.langData,
      lang: state.lang,
      state
    }
  }

export default withRouter(connect(mapStateToProps)(AuditTrail));


