import React, { useEffect, useState } from 'react'
import { InputField, AutoOptions, AutoOptionsMultiple } from '../../../globalcomponents/formElements';
import IconButton from '@material-ui/core/IconButton';
import SearchIcon from '@material-ui/icons/Search';
import FormHoc from '../../../globalcomponents/hoc/formHoc';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import HospitalList from '../hospital/hospitalList';
import { appConfig } from "../../../common/constants";
import Loader from '../../../common/loader'
import Instance from '../../../img/instance.png'

const Hospital = (propsobj) => {
    console.log(propsobj, "props")
    let [progress, setProgress] = useState(false)
    let [showHospitals, setShowHospitals] = useState(false)
    let [keyField, showKeyField] = useState(false)
    let [hospitalKeyAvailable, setHospitalkeyAvailable] = useState("")

    let propsValues = { ...propsobj };
    const hocObj = FormHoc(propsValues);

    const textProps = {
        ...hocObj,
        propsobj: propsValues
    }
    const showHospitalList = (event) => {
        event.preventDefault();
        setShowHospitals(true)
    }
    const autoPropstoPT = {
        freeSolo: false,
        propsobj,
        keys: ['value'],
        'options': [{ value: "training", label: "Training" }, { value: "prediction", label: "Prediction" }],
        default: null,
    }

    useEffect(async () => {
        if(propsobj.formValues?.hospitalId){         
            const requestOptions = {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ "hospital-id": propsobj.formValues?.hospitalId })
            };
            setProgress(true)
            fetch(appConfig.AIApi + 'extract_hospital_key', requestOptions)
                .then(response => response.json())
                .then((data) => {
                    setProgress(false)
                    console.log(data, "extract_hospital_key")
                    if(data.status==="Success"){
                        showKeyField(false)
                    }else{
                        showKeyField(true)
                    }
                })
        }
        if (propsobj.formValues?.hospitalData && propsobj.formValues.pt) {
            fetch(appConfig.BaseUrl + `hospitalflow?hospital_id=${propsobj.formValues?.hospitalData.sponsorAccountId}&type=GET&process_type=${propsobj.formValues.pt}`,
                {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    }
                })
                .then(res => {
                    if (res.status !== 200 && res.status !== 201) {
                        throw new Error('Failed!');
                    }
                    return res.json();
                })
                .then(responseData => {
                    let data = responseData
                    console.log(data, "work")
                    if (data?.fileUpload === "N") {
                        propsobj.history.push('/admin/hospitalflow/dataupload')
                    } else if (data?.dataPreprocess === "N") {
                        propsobj.history.push('/admin/hospitalflow/datamapping')
                    } else if (data?.finalBuild === "N") {
                        propsobj.history.push('/admin/hospitalflow/training')
                    } else {

                    }
                    //if data is empty show next button
                })

        }
    }, [propsobj.formValues?.hospitalId,propsobj.formValues?.pt])

    const saveHospitalKey = (e) => {
        e.preventDefault()
        const requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ "hospital-id": propsobj.formValues?.hospitalData.sponsorAccountId, "key-name": propsobj.formValues?.hospitalkey })
        };
        setProgress(true)
        fetch(appConfig.AIApi + 'key_creation', requestOptions)
            .then(response => response.json())
            .then((data) => {
                setProgress(false)
                console.log(data, "data")
            })
    }

    const startworkflowApi = (id) => {
        fetch(appConfig.BaseUrl + `hospitalflow?hospital_id=${id}&type=POST&process_type=${propsobj.formValues.pt}`,
            {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                }
            })
            .then(res => {
                if (res.status !== 200 && res.status !== 201) {
                    throw new Error('Failed!');
                }
                return res.json();
            })
            .then(responseData => {
                console.log(responseData, "work")
                return responseData
            })
    }


    const next = async (e) => {
        e.preventDefault()
        startworkflowApi(propsobj.formValues?.hospitalData.sponsorAccountId)
        propsobj.history.push('/admin/hospitalflow/dataupload')
    }

    return (
        <>
            <Loader progress={progress} />
            {showHospitals ? <HospitalList setShowHospitals={setShowHospitals} /> :
                <div className='row'>

                    <div className='col-md-6'>
                        <div className="d-flex mb-3 justify-content-end">
                            <h1 className="mr-auto primary-header">Select Hospital</h1>
                        </div>
                        <div className="panel-primary-wrap">
                            <div className="card">
                                <div className="card-body">
                                    {/* <div className="card-page-header">NAME INFORMATION</div> */}
                                    <div className="inputBox">
                                        <InputField {...textProps} name="hospitalName" label="Hospital ID" must={'false'} type="text" />
                                        <div className="searchButtonWidth">
                                            <IconButton aria-label="Search" className="searchicon"
                                                onClick={showHospitalList}>
                                                <SearchIcon />
                                            </IconButton>
                                        </div>
                                        {keyField && <div className='row'>
                                            <div className='col-md-10'>
                                                <InputField {...textProps} name="hospitalkey" label="Hospital Key" must={'false'} type="text" />
                                            </div>
                                            <div className='col-md-2' style={{
                                                textAlign: "right",
                                                marginTop: "17px"
                                            }}>
                                                <button className="btn btn-primary-blue btn-rounded btn-small mr-1" onClick={saveHospitalKey}>Save</button>
                                            </div>

                                        </div>
                                        }
                                        <AutoOptions {...autoPropstoPT} name="pt" label="Process Type" keyName='value' must={'false'} />
                                    </div>
                                    {/* <input  directory="" webkitdirectory="" type="file" /> */}
                                </div>
                            </div>
                        </div>
                        <div className="buttonContainer mb-3 fr">
                            <button className="btn btn-primary-blue btn-rounded btn-small mr-1" onClick={(e) => next(e)} disabled={hospitalKeyAvailable}>Next</button>

                        </div>
                    </div>
                    <div className='col-md-6' style={{ textAlign: "center" }}>
                        <img src={Instance} height="80%" />
                    </div>
                </div>
            }
        </>
    )
}
const mapStateToProps = (state) => {
    return {
        formValues: state.form,
        state
    }
}
const mapDispatchToProps = (dispatch) => {
    return {
        UpdateFormField: (obj) => {
            return dispatch(
                {
                    type: 'UpdateFormField',
                    value: obj.value,
                    name: obj.name
                }
            )
        }
    }
}
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Hospital));
