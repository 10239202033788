export const ErrorLables = {
  email: 'This field is required and enter valid e-mail!',
  forgot_email: 'Please enter valid e-mail!',
  required: 'Please enter this field',
  ssousername: 'Please enter username!',
  ssopassword: "Please enter password!",
  password: 'Please enter password!',
  cpassword: 'Please enter confirm password!',
  invalidPassword: 'Please enter valid password!',
  username: 'Please enter username!',
  fname: 'Please enter first name',
  lname: 'Please enter last name',
  mname: 'Please enter middle name',
  lang: 'This field is required',
  roles: 'Please select roles',
  role: 'Please select role',
  accountName: 'This field is required',
  phone: 'This field is required',
  firstName: 'This field is required',
  lastName: 'This field is required',
  role: 'This field is required',
  language: 'This field is required',
  SponsorAccountName: 'Please enter account name',
  contactAccountName:'This field is required',
  // accName:"Please enter account name",
  accountType: "This field is required",
  parAccount: "Please enter",
  status: "This field is required",
  updateaccount_status:"This field is required",
  duns: "",
  // phone:"Please enter",
  // email:"Please enter",
  primaryContact: "",
  address1: "This field is required",
  address2: "",
  city: "This field is required",
  state: "This field is required",
  country: "This field is required",
  zip: "This field is required",

  accountsearch: '',

  //add study
  studyNumber: "This field is required",
  parentAccountId: "This field is required",
  studyTitle: "This field is required",
  nameofIMP: "This field is required",
  devPhase: "This field is required",
  status: "This field is required",
  primaryEndPoint: "This field is required",
  secondaryEndPoint: "This field is required",
  expEndPoint: "This field is required",
  startDt: "This field is required",
  endDt: "This field is required",
  studyDuration: "This field is required",
  stoppingCriteria: "This field is required",
  numberOfSitesPlanned: "This field is required and allows only positive numbers",
  numberOfSubPlanned: "This field is required and allows only positive numbers",
  route: "This field is required and allowed only alphabets",
  regiman: "This field is required",
  dosage: "This field is required",
  statMethod: "This field is required",
  protocolSynopsisActual: "This field is required",
  protocolFinalActual: "This field is required",
  fdaSubmissionActual: "This field is required",
  firstSite_OpenActual: "This field is required",
  fpiActualActual: "This field is required",
  lpoActualActual: "This field is required",
  dbLockActual: "This field is required",
  csrActualActual: "This field is required",
  protocolSynopsisReceived: "This field is required",
  protocolFinalReceived: "This field is required",
  fdaSubmissionReceived: "This field is required",
  firstSite_OpenReceived: "This field is required",
  fpiReceivedReceived: "This field is required",
  lpoReceivedReceived: "This field is required",
  dbLockReceived: "This field is required",
  csrReceivedReceived: "This field is required",
  interimAnalysis: "This field is required",
  studyFlow: "This field is required",
  //get study fields
  datepicker: 'This datepicker field is required',
  datepicker1: 'This datepicker1 field is required',
  parentAccount:'This field is required',
  siteName: 'This field is required',
  pagName: 'This field is required',
  //ie criteria
  IE_type: "This field is required",
  IE_description: "This field is required",
  IE_effectiveDate: "This field is required",
  IE_severity: "This field is required",
   //add rbm
   rbm_type:"This field is required",
   rbm_description:"This field is required",
   rbm_severity:"This field is required",
   rbm_status:"This field is required",
  //add AE
  AE_subjectid: "This field is required",
  AE_term: "This field is required",
  AE_severity: "This field is required",
  AE_causality: "This field is required",
  AE_outcome: "This field is required",
  AE_startDate: "This field is required",
  AE_stopdate: "This field is required",
  //add site
  addsite_onboardStatus: "This field is required",
  addsite_limitization: "This field is required",
  onboardStatus: "This field is required",
  limitization: "This field is required",
  position: "This field is required",
  //add courses
  name: "This field is required",
  summary: "This field is required",
  category: "This field is required",
  type: "This field is required",
  language: "This field is required",
  version: "This field is required",
  // cpassword: "This field is required",
  addpag_status: "This field is required",
  newEvent: "This field is required",
  roleName: "This field is required",
  studySponsor: "This field is required",
  userfname: "This field is required",
  userlname: "This field is required",
  usermname: "This field is required",
  userlang: "This field is required",
  userrole: "This field is required",
  userposition: "This field is required",
  userphone: "This field is required",
  useraccountName: "This field is required",
  useremail: "Please enter valid e-mail!",
  usersponsorAccountId: "This field is required",
  armName:"This field is required", 
  armType:"This field is required",
  armDescription:"This field is required",
  armStatus:"This field is required",
  Q_title:"This field is required",
  Q_Description:"This field is required",
  // Q_studyID:"This field is required",
  Q_status:"This field is required",
  Q_severity:"This field is required",
  Q_category:"This field is required",
  // Q_createdby:"This field is required",
  // Q_resolvedDate:"This field is required",
  // Q_assignedto:"This field is required",
  // Q_responsefromsite:"This field is required"
}

