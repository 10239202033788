import React, { useEffect, useState } from "react";
import LoginForm from './login/loginForm';
import { Route, Link, Switch, Redirect, useHistory, BrowserRouter as Router } from 'react-router-dom';
import { Security, SecureRoute, LoginCallback } from '@okta/okta-react';
import { OktaAuth } from '@okta/okta-auth-js';
import { oktaAuthConfig, oktaSignInConfig } from './config';
import OktaLogin from './login/oktasignin'
import SmallLogo from '../../img/Hekma-Logo-New1.svg';
import '../sass/hekma-theme.scss'
const oktaAuth = new OktaAuth({
    //    redirectUri: window.location.origin + '/login/callback',
    clientId:process.env.REACT_APP_OKTACLIENTID,
    issuer: `${process.env.REACT_APP_OKTAURL}/oauth2/default`,
    // redirectUri: 'http://localhost:8080/implicit/callback',
    scopes: ['openid', 'profile', 'email'],
    pkce: true,
});
const HomePage = () => {
    const history = useHistory();

    const customAuthHandler = () => {
        history.push('/login');
    };

    return (
        <>
           <div className="login-wrapper d-flex align-items-center">
            <div className="container-fluid ">
                <div className="row">
                    <div className="col-lg-6 col-md-6 col-sm-6 ">
                      <div className="logo-primary logo-container">
                         <img src={SmallLogo} alt="logo-small"  width="220px" height="60px" background-color="#e5edf8"/>
                      </div>
                        <div className="d-flex align-items-center bg-login-left"></div>

                        <div className="text-start mb-0"style={{fontSize:"25px"}}>Connecting <b>Underrepresented Populations</b><br></br>to Clinical Trials...</div>
                    </div>

                    <div className="col-lg-6 col-md-6 col-sm-6 align-items-center p-7rem border border-white login-container">
                            {/* <Route path='/login' render={() => <LoginForm />} /> */}
                            <Security
                                oktaAuth={oktaAuth}
                                onAuthRequired={customAuthHandler}
                            >
                                <Switch>
                                    <Route path='/login' render={() => <LoginForm />} />
                                    <Route exact path='/securelogin' render={() => <OktaLogin config={oktaSignInConfig} />} />
                                    {/* <Route path='/login/callback' component={LoginForm} /> */}
                                    <Redirect to="/login" />
                                </Switch>
                            </Security>
                            {/* <Login  config={oktaSignInConfig}/> */}
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
export default HomePage;


