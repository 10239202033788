import React from 'react';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Badge from '@material-ui/core/Badge';
import { Paginationn } from '../../../sponsorApp/common/pagination'
import moment from 'moment'
import { useSelector } from 'react-redux';


function TabContainer(props) {
  return (
    <>
      {props.children}
    </>
  );
}
export default function CenteredTabs({ data, appointments, activeStatus, activeApp, handlePageClickReq, handlePageClickApp, offsetReq, offsetApp, callView, openCalendar }) {
  const [value, setValue] = React.useState(0);
  const lang = useSelector((state) => state.lang);
  const langData = useSelector(state => state.langData);
  const handleChange = (event, newValue) => {
    setValue(newValue);
    if (newValue === 1) {
      callView()
    }
  };
  return (
    <React.Fragment className="">
      <Tabs
        value={value}
        onChange={handleChange}
        indicatorColor="primary"
        textColor="primary"
        centered
      >
        <Tab label={<Badge badgeContent={activeStatus.length} color="secondary">{langData ? langData.find(item => item.keyword === "alerts_request_lbl")?.[lang] : ""}</Badge>} />
        <Tab label={<Badge badgeContent={activeApp.length} color="secondary">{langData ? langData.find(item => item.keyword === "alerts_appointments_lbl")?.[lang] : ""}</Badge>} />
      </Tabs>
      {value === 0 &&
        <form>
          <TabContainer>
            {data.length > 0 ? (data.slice(offsetReq, 5 + offsetReq)).map((item, i) => (
              <a className="dropdown-item" id={item.id}>
                <div className={item.requestStatus === "Created" ? "createdcss" : "viewedcss"}>{item.refType3}- {langData ? langData.find(item => item.keyword === "alerts_appointments_req_lbl")?.[lang] : ""}
                </div>
              </a>
            )) : <div style={{ textAlign: 'center', marginTop: "10px" }}>{langData ? langData.find(item => item.keyword === "alerts_no_req_found_lbl")?.[lang] : ""}</div>}
            {data.length > 5 &&
              <Paginationn
                handlePageClick={handlePageClickReq}
                data={data.length}
                offset={offsetReq}
              />
            }
          </TabContainer></form>}

      {value === 1 &&
        <form>
          <TabContainer>
            {appointments.length > 0 ? (appointments.slice(offsetApp, 5 + offsetApp)).map((item, i) => (
              <a className="dropdown-item" id={item.id} onClick={(e) => openCalendar(e)}>
                <div className={item.status === ("Created" || "Updated") ? "createdcss" : "viewedcss"}>{item.fromName} -
                  {/* ({item.startDateTime + ' - ' + item.endDateTime})  */}
                  {moment(item.startDateTime).format("YYYY-MM-DD")} (<b>{moment(item.startDateTime).format('hh:mm a')} - {moment(item.endDateTime).format('hh:mm a')}</b>)
                  - {langData ? langData.find(item => item.keyword === "alerts_appointments_booked_lbl")?.[lang] : ""}
                </div>
              </a>
            )) : <div style={{ textAlign: 'center', marginTop: "10px" }}>{langData ? langData.find(item => item.keyword === "alerts_no_appointments_found_lbl")?.[lang] : ""}</div>}
            {appointments.length > 5 &&
              <Paginationn
                handlePageClick={handlePageClickApp}
                data={appointments.length}
                offset={offsetApp}
              />
            }
          </TabContainer></form>}
    </React.Fragment>
  );
}