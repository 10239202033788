import React, { useEffect, useState } from "react";
import { withRouter } from 'react-router-dom';
import CircularProgress from '@material-ui/core/CircularProgress';
import FormHoc from '../../../globalcomponents/hoc/formHoc';
import { InputField, AutoOptions, AutoOptionsMultiple } from '../../../globalcomponents/formElements';
import { connect } from 'react-redux';
import { oldgenericApi, securityApi, genericApi } from '../../../common/apiconfig';
import { PatientStudyInfo } from '../common/createSessionPatient'

const ReqAppointment = (propsobj) => {
  console.log(propsobj.formValues, "appointment")
  // let { accountID, siteID, studyID } = propsobj.formValues?.studyprogressdata ?  propsobj.formValues?.studyprogressdata[0]: []
  let [progress, setProgress] = useState(false);
  let [options, setOptions] = useState([])
  let [fullSite, setfullSite] = useState("")
  let [data, setData] = useState('')
  let [doctor, setDoctor] = useState('')

  useEffect(async () => {
    // callGetPatientInfo()
    var data = await PatientStudyInfo()
    if (data.length > 0) {
      callDoctorListAPi(propsobj.formValues.patientStudyInfo?.studyID, propsobj.formValues.patientStudyInfo?.siteID)
    }else{
      alert('No study assigned yet to create appointment')
    }
    propsobj.UpdateFormField({ name: "patientissues", value: "" })
  }, []);
  // const callGetPatientInfo = async () => {
  //   setProgress(true);
  //   let requestBody = {
  //     query: `query {        
  //        getStudyProgress(patientId:"${localStorage.getItem('loginUserId')}") {
  //       studyID
  //       accountID
  //       siteID
  //     }
  //  }`
  //   };
  //   requestBody = JSON.stringify(requestBody);
  //   let res = await oldgenericApi('getStudyProgress', requestBody);
  //   if (!res) {
  //     // Error handling
  //     //console.log('Error on getting patient data!')

  //   } else {
  //     if (res.data.getStudyProgress.length > 0) {
  //       var data = res.data.getStudyProgress[0]
  //       var sessioncreated = await CreateSession(data.accountID, data.siteID, data.studyID)
  //       if (sessioncreated) {
  //         setProgress(false);
  //         callDoctorListAPi(data.studyID, data.siteID)
  //       } else {
  //         alert('session not created')
  //       }
  //     }
  //   }
  // }


  const callDoctorListAPi = async (studyID, siteID) => {
    let requestBody = {
      query: `query {
        getStudySiteContact(studyId: "${studyID}", siteId: "${siteID}") {
          studyId
          siteId
          contactId
          contactName
          role
          primaryContactInd
          email
          phone
          status
          createdBy
          createdTs
          modifiedBy
          modifiedTs
                }
              }`
    };

    requestBody = JSON.stringify(requestBody);
    setProgress(true);
    let res = await genericApi('getStudySiteContact', requestBody);
    setProgress(false);
    if (!res) {
      // Error handling
      //console.log('Error site users fetch!')

    } else {
      if (res.data.getStudySiteContact.length) {
        setData(res.data.getStudySiteContact)
        setOptions(res.data.getStudySiteContact)
      } else {
        setData([])
      }
    }
  }

  const hocObj = FormHoc(propsobj);

  const textProps = {
    ...hocObj,
    propsobj
  }

  const autoPropstoaccount = {
    'options': options,
    default: null,
    freeSolo: false,
    propsobj,
    keys: ['contactName']
  }

  const formSubmit = async (event) => {
    event.preventDefault();
    let setGo = await hocObj.handleSubmit('reqApp', event);
    if (setGo) {
      setProgress(true);
      let form = propsobj.formValues;
      console.log(form, "Form", doctor)
      let requestBody = `
          mutation 
          {
            createRequest(fromKey:"${form.patientdata.patientId}",fromType:"patient",
            toKey:"${doctor.contactId}",toType:"doctor",
            refKey1:"${doctor.studyId}",refType1:"study",
            refKey2:"${doctor.siteId}",refType2:"site",
            refKey3:"${form.patientdata.firstName + ' ' + form.patientdata.lastName}",refType3:"${doctor.contactName + ' - ' + doctor.role}",
            requestDescription:${JSON.stringify(form.patientissues)},
            type:"Doctor Appointment") {
              code
              type
              message
              attributes
            }  
          }
              `;

      requestBody = JSON.stringify({ query: requestBody });
      let res = await oldgenericApi('createRequest', requestBody, form);
      setProgress(false);

      if (res && res.data.createRequest.code === '200') {
        setDoctor('')
        propsobj.UpdateFormField({ name: "patientissues", value: "" })
        propsobj.onClose()
      } else {
        console.log('Error on response!')
      }
    }
  }

  const handleCancel = (event) => {
    event.preventDefault();
    // propsobj.UpdateFormField({value:propsobj.formValues.accountName,name:"accountName"})
    propsobj.history.goBack();
  }

  const getDoctor = (value) => {
    console.log(value.selected, "ss")
    setDoctor(value.selected)
    // setsiteId(value.selected.sponsorAccountId)
    // setfullSite(value.selected)
    // setsiteName(value.selected.accountName)
  }
  return (
    <>
      {progress && <div className="fullScreen">
        <CircularProgress
          disableShrink
          className="progressCircle"
          value={90}
          size={100}
          thickness={1.5}
        />
      </div>}
      {/* 
      <div onClick={goBack} className="subNavText"><img src={BackIcon} alt="goback" />  BACK TO SITE LIST</div><br/> */}


      <div className="">
        <form noValidate autoComplete="off" id="reqApp" className="row" onSubmit={formSubmit}>
          {/* <div className="panel-primary-wrap">
            <div className="card">
              <div className="card-body"> */}
          <div className="inputBox col-md-12">
            <AutoOptionsMultiple {...autoPropstoaccount} textDirection={propsobj.lang === "ar_AA" ? true : false} autoValue={doctor.contactName} name="doctorName" label={propsobj.langData?.length>0 ? propsobj.langData.find(item => item.keyword === "request_select_doctor_lbl")?.[propsobj.lang] : ''}  keyName='contactName' must="true" patientapp={true}
              callback={getDoctor}
            />
            <InputField {...textProps} multiline={true} textDirection={propsobj.lang === "ar_AA" ? true : false} row={1} rowsMax={2} name="patientissues" label={propsobj.langData?.length>0 ? propsobj.langData.find(item => item.keyword === "request_err_concerns_lbl")?.[propsobj.lang] : ''}  must={'true'} type="text" />
            {/* </div>
              </div>
            </div> */}
          </div>
          <div className="buttonContainer col-md-12 mb-2">
            <button type="submit" className="btn btn-primary-blue btn-rounded btn-small ">{propsobj.langData?.length>0 ? propsobj.langData.find(item => item.keyword === "request_lbl")?.[propsobj.lang] : ''}</button>
          </div>
        </form>
      </div>
    </>
  )
}

const mapStateToProps = (state) => {
  return {
    formValues: state.form,
    langData: state.langData,
    lang: state.lang,
    state
  }
}
const mapDispatchToProps = (dispatch) => {
  return {
    UpdateFormField: (obj) => {
      return dispatch(
        {
          type: 'UpdateFormField',
          value: obj.value,
          name: obj.name
        }
      )
    },
    clearForm: () => {
      return dispatch(
        {
          type: 'ClearForm',
        }
      )
    },
    updateErrorObj: (obj) => {
      return dispatch(
        {
          type: 'updateErrorObj',
          flag: obj.flag,
          name: obj.name,
          text: obj.text
        }
      )
    },
    updateEntireErrorObj: (errobj, errtextobj) => {
      return dispatch(
        {
          type: 'updateEntireErrorObj',
          errobj: errobj,
          errtextobj: errtextobj,
        }
      )
    },
  }
}
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ReqAppointment));



