import React, { useEffect, useState, useContext } from "react";
import { withRouter } from 'react-router-dom';
import ErrorIcon from '@material-ui/icons/Error';
import CircularProgress from '@material-ui/core/CircularProgress';
import { apiCall, logOut, getCountry, getState, oldgenericApi } from '../../../common/apiconfig';
import FormHoc from '../../../globalcomponents/hoc/formHoc';
import { InputField, AutoOptions, AutoOptionsNew } from '../../../globalcomponents/formElements';
import { Dropdowns } from '../../../common/constants';
import { connect } from 'react-redux';
import HekmaDatePicker from '../HekmaDatePicker'
import IconButton from '@material-ui/core/IconButton';
import SearchIcon from '@material-ui/icons/Search';

const AddPatient = (propsobj) => {
  console.log(propsobj, "newapp")
  let phoneReg = /^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/

  let countryObj = {
    value: 'United States',
    label: 'United States',
    error: false,
    helperText: '',
  }
  let stateObj = {
    value: '',
    label: '',
    error: false,
    helperText: '',
  }
  let [apiError, setApiError] = useState(false);
  let [progress, setProgress] = useState(false);
  let [country, setCountry] = useState([]);
  let [state, setState] = useState([])
  let [countryField, setCountryField] = useState(countryObj);
  let [stateField, setStateField] = useState(stateObj);
  let [phoneCheck, setPhoneError] = useState('')

  useEffect(() => {
    // propsobj.clearForm();
    loadCountry();
    loadStates('US');// US country code

    propsobj.UpdateFormField({ value: 'US', name: "countryCode" })
    propsobj.UpdateFormField({ name: "fName", value: "" })
    propsobj.UpdateFormField({ name: "mname", value: "" })
    propsobj.UpdateFormField({ name: "lName", value: "" })
    propsobj.UpdateFormField({ name: "phone", value: "" })
    propsobj.UpdateFormField({ name: "email", value: "" })
    propsobj.UpdateFormField({ name: "patientid", value: "" })
    propsobj.UpdateFormField({ name: "alternateid", value: "" })
    propsobj.UpdateFormField({ name: "address1", value: "" })
    propsobj.UpdateFormField({ name: "address2", value: "" })
    propsobj.UpdateFormField({ name: "city", value: "" })
    propsobj.UpdateFormField({ name: "country", value: "" })
    propsobj.UpdateFormField({ name: "countryCode", value: "" })
    propsobj.UpdateFormField({ name: "zip", value: "" })
    propsobj.UpdateFormField({ name: "hidestudytab", value: false })
    //to call api without study - need to clear this token
    propsobj.UpdateFormField({ name: "inviteviastudy", value: false })
    return () => {
      propsobj.UpdateFormField({ name: "hidestudytab", value: true })
    }
  }, []);

  const autoPropstoCountry = {
    'options': country,
    propsobj,
    keys: ['value'],
    error: countryField.error,
    helperText: countryField.helperText
  }
  const autoPropstoState = {
    'options': state,
    propsobj,
    keys: ['value'],
    error: stateField.error,
    helperText: stateField.helperText
  }

  async function loadCountry() {
    setProgress(true);
    let countries = await getCountry();
    setProgress(false);
    setCountry(countries);
  }

  async function loadStates(id) {
    setProgress(true);
    let states = await getState(id);
    setProgress(false);
    setState(states);
  }

  const handleCountry = async (obj) => {
    let stateObj = {
      value: '',
      label: '',
      error: false,
      helperText: '',
    }
    setStateField(stateObj);


    if (obj === null) {
      let countryObj = {
        value: '',
        label: '',
        error: false,
        helperText: '',
      }
      setCountryField(countryObj);
      propsobj.UpdateFormField({ value: "", name: "countryCode" })
      return;
    }

    if (obj) {
      if (!obj.selected) {
        let countryObj = {
          value: '',
          label: '',
          error: obj.flag,
          helperText: obj.text,
        }
        setCountryField(countryObj);
        propsobj.UpdateFormField({ value: '', name: obj.name })
        propsobj.UpdateFormField({ value: "", name: "countryCode" })
        return;
      } else {
        let countryObj = {
          value: obj.value,
          label: obj.label,
          error: obj.flag,
          helperText: obj.text,
        }
        setCountryField(countryObj);
        propsobj.UpdateFormField({ value: obj.selected.id, name: "countryCode" })
        propsobj.UpdateFormField({ value: obj.value, name: obj.name })
        setProgress(true);
        let states = await getState(obj.selected.id);
        setProgress(false);
        setState(states);
      }
    }
  }

  const handleState = async (obj) => {
    if (obj === null) {
      let stateObj = {
        value: '',
        label: '',
        error: false,
        helperText: '',
      }
      setStateField(stateObj);
      return;
    }

    if (obj) {
      if (!obj.selected) {
        let stateObj = {
          value: '',
          label: '',
          error: obj.flag,
          helperText: obj.text,
        }
        setStateField(stateObj);
        propsobj.UpdateFormField({ value: '', name: obj.name })
        return;
      } else {
        let stateObj = {
          value: obj.value,
          label: obj.label,
          error: obj.flag,
          helperText: obj.text,
        }
        setStateField(stateObj);
        propsobj.UpdateFormField({ value: obj.value, name: obj.name })
      }
    }
  }

  const hocObj = FormHoc(propsobj);

  const textProps = {
    ...hocObj,
    propsobj
  }

  const autoPropsToStatus = {
    default: {
      value: 'Active',
      label: 'Active',
    },
    'options': Dropdowns.Account_Status,
    freeSolo: false,
    propsobj,
    keys: ['value']
  }
  const autoPropsToLang = {
    default: {
      value: 'English (US)',
      label: 'English (US)',
    },
    'options': Dropdowns.Languages,
    freeSolo: false,
    propsobj,
    keys: ['value']
  }

  const caregiverRelationship = {
    'options': [{
      value: 'Family',
      label: 'Family',
    },
    {
      value: 'Friend',
      label: 'Friend',
    },
    {
      value: 'Others',
      label: 'Others',
    }],
    freeSolo: false,
    propsobj,
    keys: ['value']
  }
  const primaryRelationship = {
    'options': [{
      value: 'Child',
      label: 'Child',
    },
    {
      value: 'Sibling',
      label: 'Sibling',
    },
    {
      value: 'Spouse',
      label: 'Spouse',
    },
    {
      value: 'Others',
      label: 'Others',
    }],
    freeSolo: false,
    propsobj,
    keys: ['value']
  }

  const formSubmit = async (event) => {
    event.preventDefault();
    let setGo = await hocObj.handleSubmit('addPatient', event);
    let phoneCheck = phoneReg.test(propsobj.formValues.phone)
    if (propsobj.formValues.phone != "" && propsobj.formValues.userphone != null && phoneCheck == false) {
      setPhoneError("Invalid Phone Number")
    } else {
      setPhoneError("")
      phoneCheck = true
    }
    if (setGo && phoneCheck) {
      setProgress(true);
      let form = propsobj.formValues;
      let requestBody = `
      mutation {
        addSitePagPatient(sitePagId:"${form.autosite.sponsorAccountId}",subjectId:"${form.patientid}",alternateId:"${form.alternateid}",firstName:"${form.fName}",middleName:"${form.mname}",lastName:"${form.lName}", address1:"${form.address1}",address2:"${form.address2}",countryCode:"${form.countryCode == "" ? "US" : form.countryCode}",city:"${form.city}",state:"${form.state}",zip:"${form.zip}",email:"${form.email}",phone:"${form.phone}",language:"English(US)"){
          code
          type
          message
          attributes
        }}`;

      requestBody = JSON.stringify({ query: requestBody });
      let res = await oldgenericApi('addSitePagPatient', requestBody, form);
      setProgress(false);
      if (res && res.data.addSitePagPatient.code === "200") {
        //console.log('patient added successfully');
        propsobj.history.goBack()
        // propsobj.UpdateFormField({ value: res.data.addSitePagPatient, name: "sponsorAccountId" })
      } else {
        //console.log('Error on adding patient!')
        //props.history.push('/account/updateContact'); // temp enabling to check update sponsor
      }
    }
  }

  const handleCancel = (event) => {
    event.preventDefault();
    propsobj.history.goBack();
  }

  return (
    <>
      {progress && <div className="fullScreen">
        <CircularProgress
          disableShrink
          className="progressCircle"
          value={90}
          size={100}
          thickness={1.5}

        />
      </div>}

      <div className="d-flex mb-3 justify-content-end">
        <h1 className="mr-auto primary-header">ONBOARD PATIENT</h1>
      </div>
      <div className="">
        <form noValidate autoComplete="off" id="addPatient" className="row" onSubmit={formSubmit}>
          <div className="col-md-6">
            <div className="row">
              <div className="col-md-12">
                <div className="panel-primary-wrap">
                  <div className="card">
                    <div className="card-body">
                      <div className="card-page-header">PERSONAL INFORMATION</div>
                      <div className="inputBox">
                        <InputField {...textProps} name="fName" label="First Name" must={'true'} type="text" />
                        <InputField {...textProps} name="mname" label="Middle Name" must={'false'} type="text" />
                        <InputField {...textProps} name="lName" label="Last Name" must={'true'} type="text" />
                        <div style={{ position: "relative" }}>
                          <InputField {...textProps} name="phone" label="Phone" must={'true'} type="text" />
                          {phoneCheck.length > 0 && <span style={{ color: "#f44336", fontSize: "12px", fontWeight: "400" }}>{phoneCheck}</span>}
                        </div>
                        <InputField {...textProps} name="email" label="Email" must={'true'} type="email" />
                        <InputField {...textProps} name="patientid" label="Patient ID" must={'false'} type="text" />
                        <InputField {...textProps} name="alternateid" label="Alternate ID" must={'false'} type="text" />

                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-12">
                <div className="panel-primary-wrap">
                  <div className="card">
                    <div className="card-body">
                      <div className="card-page-header">ADDRESS INFORMATION</div>
                      <div className="inputBox">
                        <InputField {...textProps} name="address1" label="Address Line 1" must={'true'} type="text" />
                        <InputField {...textProps} name="address2" label="Address Line 2" must={'false'} type="text" />
                        <InputField {...textProps} name="city" label="City" must={'true'} type="text" />

                        <AutoOptionsNew {...autoPropstoCountry} autoValue={countryField} name="country" label="Country" keyName='value' must={'true'} callback={handleCountry} />

                        <AutoOptionsNew {...autoPropstoState} autoValue={stateField} name="state" label="State" keyName='value' must={'true'} callback={handleState} />

                        <InputField {...textProps} name="zip" label="Zipcode" must={'true'} type="text" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>

            </div>

          </div>
          <div className="col-md-6">
            <div className="row">
              <div className="col-md-12">
                <div className="panel-primary-wrap">
                  <div className="card">
                    <div className="card-body">
                      <div className="card-page-header">DEMOGRAPHICS</div>
                      <div className="inputBox">
                        <InputField {...textProps} name="gender" label="Gender" must={'false'} type="text" />
                        <InputField {...textProps} name="height" label="Height" must={'false'} type="text" />
                        <InputField {...textProps} name="weight" label="Weight" must={'false'} type="text" />

                        <HekmaDatePicker name="dob" label="Date of birth" must={'false'} minDate={propsobj.formValues.startDt} />
                        <InputField {...textProps} name="ethnicity" label="Ethnicity" must={'false'} type="text" />
                        <AutoOptions {...autoPropsToLang} name="user_lang" label="Language" keyName='value' must={'false'} />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-12">
                <div className="panel-primary-wrap">
                  <div className="card">
                    <div className="card-body">
                      <div className="card-page-header">PRIMARY CONTACT DETAILS</div>
                      <div className="inputBox">
                        <AutoOptions {...primaryRelationship} name="relationship" label="Relationship" keyName='value' must={'false'} />
                        <InputField {...textProps} name="pname" label="Name" must={'false'} type="text" />
                        <div style={{ position: "relative" }}>
                          <InputField {...textProps} name="pphone" label="Phone" must={'false'} type="text" />
                          {/* {phoneCheck.length > 0 && <span style={{ color: "#f44336", fontSize: "12px", fontWeight: "400" }}>{phoneCheck}</span>} */}
                        </div>

                        <InputField {...textProps} name="paddress1" label="Address" must={'false'} type="text" />
                        <InputField {...textProps} name="pgender" label="Gender" must={'false'} type="text" />
                        <InputField {...textProps} name="period" label="Period" must={'false'} type="text" />

                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-12">
                <div className="panel-primary-wrap">
                  <div className="card">
                    <div className="card-body">
                      <div className="card-page-header">CARE GIVER DETAILS</div>
                      <div className="inputBox">
                        <AutoOptions {...caregiverRelationship} name="relationship" label="Relationship" keyName='value' must={'false'} />
                        <InputField {...textProps} name="cname" label="Name" must={'false'} type="text" />
                        <div style={{ position: "relative" }}>
                          <InputField {...textProps} name="cphone" label="Phone" must={'false'} type="text" />
                          {/* {phoneCheck.length > 0 && <span style={{ color: "#f44336", fontSize: "12px", fontWeight: "400" }}>{phoneCheck}</span>} */}
                        </div>

                        <InputField {...textProps} name="caddress1" label="Address" must={'false'} type="text" />
                        <InputField {...textProps} name="cgender" label="Gender" must={'false'} type="text" />
                        <InputField {...textProps} name="period" label="Period" must={'false'} type="text" />

                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="buttonContainer mb-3">
              <button type="submit" className="btn btn-primary-blue btn-rounded btn-small mr-1">Save</button>
              <button className="btn btn-primary-grey btn-rounded btn-small" onClick={handleCancel}>CANCEL</button>
            </div>
          </div>


        </form>
      </div>
    </>
  )
}

const mapStateToProps = (state) => {
  return {
    formValues: state.form,
    state
  }
}
const mapDispatchToProps = (dispatch) => {
  return {
    UpdateFormField: (obj) => {
      return dispatch(
        {
          type: 'UpdateFormField',
          value: obj.value,
          name: obj.name
        }
      )
    },
    updateErrorObj: (obj) => {
      return dispatch(
        {
          type: 'updateErrorObj',
          flag: obj.flag,
          name: obj.name,
          text: obj.text
        }
      )
    },
    updateEntireErrorObj: (errobj, errtextobj) => {
      return dispatch(
        {
          type: 'updateEntireErrorObj',
          errobj: errobj,
          errtextobj: errtextobj,
        }
      )
    }
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(AddPatient));