import { initialFormState } from './constants';

export let ErrorLables = {}

const browserLang = navigator.language;
localStorage.setItem("browserLang", browserLang);
const browserCachedLang = localStorage.getItem("browserLang");
const cachedLang = localStorage.getItem("lang");
const languageMap = {
  en: "en_US",
  "en-US": "en_US",
  "en-ES": "es_ES",
  de: "de_DE",
  fr: "fr_FR",
  ar: "ar_AA"
};
const cachedLangDataString = localStorage.getItem("langData");
let cachedLangData = [];

try {
    cachedLangData = cachedLangDataString ? JSON.parse(cachedLangDataString) : [];
    if (!Array.isArray(cachedLangData)) {
        cachedLangData = [];
    }
} catch (error) {
    console.error("Error parsing langData from localStorage:", error);
    cachedLangData = [];
}

const lang = cachedLang || languageMap[browserCachedLang] || 'en_US';
console.log(lang,'langLabels')

const getLabel = (keyword) => {
  return cachedLangData.find(item => item.keyword === keyword)?.[lang] || "";
};
for (let i in initialFormState) {
  ErrorLables[i] = getLabel('default_required_msg');  
}

const usernameLabel =`${getLabel('sigin_err_username_lbl')}` || 'Please enter username!';
const passwordLabel =`${getLabel('signin_err_password_lbl')}` || 'Please enter password!';
// console.log(usernameLabel,"usernameLabel")

const profileEmailLabel = `${getLabel('sigin_err_enter_email_lbl')}` || 'Please enter valid email'
const profilePasswordLabel = `${getLabel('signin_err_password_lbl')}` || 'Please enter password'
const profileConfPasswordLabel = `${getLabel('signin_err_confirm_password_lbl')}` || 'Please enter confirm password'



ErrorLables = {
    ...ErrorLables,
    username: usernameLabel,
    password: passwordLabel,
    email:"This field is required and enter valid email", 
    fromtext:'Enter valid mail',
    profile_email: profileEmailLabel,
    forgot_email: 'Please enter valid email!',
    cpassword: 'Password missmatch!',
    required: 'Please enter this field',
    profile_password: profilePasswordLabel,
    profile_cpassword: profileConfPasswordLabel,
    invalidPassword: 'Please enter valid password!',
    useremail: "This field is required and enter valid email",
    user_email: "This field is required and enter valid email",
}
console.log(ErrorLables.username, "username");