import React, { useEffect, useState } from "react";
import { withRouter } from 'react-router-dom';
import Icon from '@material-ui/core/Icon';
import { Button } from '@material-ui/core'
import CircularProgress from '@material-ui/core/CircularProgress';
import { apiCall, oldgenericApi } from '../../common/apiconfig';
import FormHoc from '../../globalcomponents/hoc/formHoc';
import { InputField, AutoOptions } from '../../globalcomponents/formElements';
import { Dropdowns, regExpData } from '../../common/constants';
import { connect } from 'react-redux';
import moment from 'moment';
import Loader from '../../common/loader'

const UpdateProfile = (propsobj) => {
  console.log(propsobj, Dropdowns, "props")
  let [apiError, setApiError] = useState(false);
  let [progress, setProgress] = useState(false);
  let [oldData, setOldData] = useState({});
  let [phoneCheck, setPhoneError] = useState('')

  useEffect(() => {
    setOldData(propsobj.formValues)
  }, [oldData])

  // let propsValues = { ...propsobj };
  const hocObj = FormHoc(propsobj);
  const textProps = {
    ...hocObj,
    propsobj
  }
  const autoPropsToLang = {
    default: null,
    'options': Dropdowns.Languages,
    freeSolo: false,
    propsobj: propsobj,
    keys: ['value']
  }
  const autoPropstoposition = {
    ...autoPropsToLang,
    'options': Dropdowns.Study_Site_Primary_Contact_Role,
    default: null,
  }
  // const autoPropstorole = {
  //   'options': Dropdowns.Roles,
  //   propsobj,
  //   keys: ['roleName'],
  //   error: roleField.error,
  //   helperText: roleField.helperText
  // }

  const formSubmit = async (event) => {
    event.preventDefault();
    let setGo = await hocObj.handleSubmit('updateProfile', event);
    console.log(propsobj.formValues,"propsobj.formValues")
    let phoneCheck = regExpData.phoneReg.test(propsobj.formValues.userphone)
    if (propsobj.formValues.userphone != "" && propsobj.formValues.userphone != null && phoneCheck == false) {
      setPhoneError("Invalid number")
    } else {
      setPhoneError("")
      phoneCheck = true
    }
    if (setGo && phoneCheck) {
      setProgress(true);
      let form = propsobj.formValues;
      let alternateId = form.patientdata.alternateId != null ? form.patientdata.alternateId : "" ;
      let requestBody = `
      mutation {
        updatePatient(
          patientId: "${form.patientId}",
          subjectId: "${propsobj.formValues.patientdata.subjectId}", 
          alternateId:"${alternateId}", 
          firstName:"${form.userfname}",
          middleName:"${form.usermname}",
          lastName :"${form.userlname}",
          email :"${form.useremail}",
          phone:"${form.userphone}",
          address1:"",
          address2:"",
          city:"",
          state:"",
          zip:"",
          countryCode:"",
          language:"${form.userlang}",
             ) {
            code
            type
            message
            attributes
        }
      }
    `;
      setProgress(true);
      requestBody = JSON.stringify({ query: requestBody });
      let res = await oldgenericApi('updatePatient', requestBody);
      setProgress(false);
      if (!res) {
        console.log('Error on update profile');
        //setApiError(true);
        //setTimeout(function () { setApiError(false) }, 1000 * 30);
      } else {
        if (res.data.updatePatient.code == "200") {
          propsobj.history.goBack()
          localStorage.setItem("loggedInUser", propsobj.formValues.userfname)
        }
      }
    }
  }


  const goBack = (event) => {
    event.preventDefault();
    propsobj.history.goBack()
  }

  return (
    <>
      <Loader progress={progress} />
      {/* <div onClick={backtolist} className="subNavText"><img src={BackIcon} alt="goback" />  BACK TO I-E CRITERIA LIST</div><br/> */}
      <div className="row">
        {/* <div className="col-md-3"></div> */}
        <div className="col-md-6">
          <div className="d-flex justify-content-end">
            {/* <div className="mr-auto secondary-header">UPDATE PROFILE</div> */}
            <h1 className="mr-auto primary-header">{propsobj.langData?.length > 0
                      ? propsobj.langData.find(item => item.keyword === "profile_update_lbl")?.[propsobj.lang]
                      : ""} </h1>
          </div>
          <form noValidate autoComplete="off" id="updateProfile" onSubmit={formSubmit}>

            <div className="panel-primary-wrap">
              <div className="card">
                <div className="card-body">
                  {/* <div className="card-page-header">NAME INFORMATION</div> */}
                  <div className="inputBox">
                    <InputField {...textProps} name="userfname" textDirection={propsobj.lang === "ar_AA" ? true : false} label={propsobj.langData?.length > 0
                      ? propsobj.langData.find(item => item.keyword === "profile_firstname_lbl")?.[propsobj.lang]
                      : ""} must={'true'} type="text" />

                    <InputField {...textProps} name="usermname" textDirection={propsobj.lang === "ar_AA" ? true : false} label={propsobj.langData?.length > 0
                      ? propsobj.langData.find(item => item.keyword === "profile_middlename_lbl")?.[propsobj.lang]
                      : ""} must={'false'} type="text" />
 
                    <InputField {...textProps} name="userlname" textDirection={propsobj.lang === "ar_AA" ? true : false} label={propsobj.langData?.length > 0 
                      ? propsobj.langData.find(item => item.keyword === "profile_ln_lbl")?.[propsobj.lang]
                      : ""} must={'true'} type="text" />

                    {/* <InputField {...textProps} name="useraccountName" label="Account Name" must={'true'} type="text" disabled={true} /> */}

                    {/* <AutoOptions {...autoPropstoposition} name="userposition" label="Position" keyName='value' must={'true'} /> */}

                    <AutoOptions {...autoPropsToLang} textDirection={propsobj.lang === "ar_AA" ? true : false} name="userlang" label={propsobj.langData?.length > 0
                      ? propsobj.langData.find(item => item.keyword === "profile_language_lbl")?.[propsobj.lang]
                      : ""} keyName='value' must={'true'} />
                    {/* <AutoOptionsNew {...autoPropstorole} autoValue={roleField} name="userrole" label="User Access" keyName='roleName' must={'false'} callback={handleRole} /> */}
                    {/* <InputField {...textProps} name="userrole" label="Role" must={'false'} type="text" disabled={true} /> */}
                    <div style={{ position: "relative" }}>
                      <InputField {...textProps} textDirection={propsobj.lang === "ar_AA" ? true : false} name="userphone" label={propsobj.langData?.length > 0
                      ? propsobj.langData.find(item => item.keyword === "profile_phone_lbl")?.[propsobj.lang]
                      : ""} must={'true'} type="text" />
                      {phoneCheck.length > 0 && <span className="phoneerr">{phoneCheck}</span>}
                    </div>
                    <InputField {...textProps} textDirection={propsobj.lang === "ar_AA" ? true : false} name="useremail" label={propsobj.langData?.length > 0
                      ? propsobj.langData.find(item => item.keyword === "profile_email_lbl")?.[propsobj.lang]
                      : ""} must={'true'} type="email" />
                  </div>

                </div>

              </div>
            </div>
            <div className="buttonContainer">
              <button type="submit" className="btn btn-primary-blue btn-rounded btn-small mr-1">{propsobj.langData?.length > 0
                      ? propsobj.langData.find(item => item.keyword === "profile_save_lbl")?.[propsobj.lang]
                      : ""}</button>
              <button className="btn btn-primary-grey btn-rounded btn-small" onClick={goBack}>{propsobj.langData?.length > 0
                      ? propsobj.langData.find(item => item.keyword === "add_disease_cancel_lbl")?.[propsobj.lang]
                      : ""}</button>
            </div>

          </form>
        </div>
      </div>
    </>

  )
}

const mapStateToProps = (state) => {
  return {
    formValues: state.form,
    langData: state.langData,
    lang: state.lang,
    state
  }
}
const mapDispatchToProps = (dispatch) => {
  return {
    UpdateFormField: (obj) => {
      return dispatch(
        {
          type: 'UpdateFormField',
          value: obj.value,
          name: obj.name
        }
      )
    },
    clearForm: () => {
      return dispatch(
        {
          type: 'ClearForm',
        }
      )
    },
    updateErrorObj: (obj) => {
      return dispatch(
        {
          type: 'updateErrorObj',
          flag: obj.flag,
          name: obj.name,
          text: obj.text
        }
      )
    },
    updateEntireErrorObj: (errobj, errtextobj) => {
      return dispatch(
        {
          type: 'updateEntireErrorObj',
          errobj: errobj,
          errtextobj: errtextobj,
        }
      )
    },
  }
}
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(UpdateProfile));



