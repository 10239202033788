import React, { useEffect, useState } from "react";
import Materialtable from '../../../../../globalcomponents/materialtable';
import { apiCall } from '../../../../common/apiconfig';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import CircularProgress from '@material-ui/core/CircularProgress';
import Radio from '@material-ui/core/Radio';
import { checkPermission } from '../../../../common/utils';

function Users(props) {
  let isAddUsersSite = checkPermission('Site_Users_Create', 'Create');
  console.log(isAddUsersSite, "isAddUsersSite")
  const [offset, setoffset] = useState(0)
  const [data, setData] = useState([])
  let [progress, setProgress] = useState(false);
  const [phone, setPhone] = useState("")

  async function fetchData() {
    let requestBody = {
      query: `query {
        getStudySiteContact(studyId:"${props.formValues.studyId}",siteId:"${props.formValues.siteId}") {
          studyId
          siteId
          contactId
          contactName
          role
          primaryContactInd
          email
          phone
          status
          createdBy
          createdTs
          modifiedBy
          modifiedTs
                }
              }`
    };

    requestBody = JSON.stringify(requestBody);
    setProgress(true);
    let res = await apiCall('getStudySiteContact', requestBody);
    setProgress(false);
    if (!res) {
      // Error handling
      console.log('Error on response material grid!')

    } else {
      if (res.data.getStudySiteContact.length) {
        setData(res.data.getStudySiteContact)
        props.UpdateFormField({ name: "sitecontacts", value: JSON.stringify(res.data.getStudySiteContact) })
      } else {
        setData([])
        props.UpdateFormField({ name: "sitecontacts", value: "" })
      }
    }
  }
  useEffect(() => {
    fetchData();
  }, []);

  const handlePageClick = (offset) => {
    setoffset(offset)
  }

  const handleClick = async (event) => {
    event.preventDefault();
    props.history.push('/account/studyDetails/site/siteDetails/addUsers');
  }

  const handleRowClick = async (event, rowData) => {
    await props.UpdateEntireForm(rowData);
    props.history.push('/account/studyDetails/site/siteDetails/users/view');
  }

  const Columns = [

    {
      title: 'User Name', field: 'contactName',
      cellStyle: {
        color: '#244271',
        fontWeight: 500
      },
      render: rowData => <span style={{ cursor: "pointer" }} onClick={(e) => { oncontactClick(e, rowData) }}>{rowData.contactName}</span>
    },
    { title: 'Role', field: 'role' },
    {
      title: 'Primary Contact', field: 'primaryContactInd', render: rowData => <Radio
        checked={rowData.primaryContactInd == "Y"}
        onClick={(e) => { updatePrimaryContact(e, rowData) }}
        value="Y"
        color="primary"
        name="radio-button-demo"
      />,
      cellStyle: {
        // width:"15%"
        textAlign: "center"
      }
    },
    { title: 'Email', field: 'email', cellStyle: { width: "28%" } },
    { title: 'Phone', field: 'phone' }

    // { title: 'Phone', field: 'phone', render: rowData => <input name="phone" value={rowData.phone} onChange={(e)=>changePhone(e,rowData)} /> },
  ]
  const updatePrimaryContact = async (e, rowData) => {
    e.preventDefault()
    var siteusersdata
    if (rowData.primaryContactInd == e.target.value) {
      siteusersdata = data.map(item => item.contactId === rowData.contactId ? { ...item, primaryContactInd: "N" } : { ...item, primaryContactInd: "N" })
    } else {
      siteusersdata = data.map(item => item.contactId === rowData.contactId ? { ...item, primaryContactInd: e.target.value } : { ...item, primaryContactInd: "N" })
    }

    // let selectedUserss = '';
    // siteusersdata.map(contact => {
    //   selectedUserss += `{ studyId: "${contact.studyId}",siteId:"${contact.siteId}", contactId: "${contact.contactId}",role:"${contact.role}",primaryContactInd:"${contact.primaryContactInd}"}`;
    // });
    // let usersSelected = selectedUserss.replace(/}\s*{/g, '},{');
    let requestBody = {
      query: `mutation {
      updateStudySiteContact(studyId: "${rowData.studyId}",siteId:"${rowData.siteId}", contactId: "${rowData.contactId}",role:"${rowData.role}",primaryContactInd:"Y") {
        code
        type
        message
        attributes
      }
    }`
    };
    requestBody = JSON.stringify(requestBody);
    setProgress(true);
    let res = await apiCall('updateStudySiteContact', requestBody);
    setProgress(false);
    if (!res) {
      // Error handling
      console.log('Error!')

    } else {
      // console.log('updated')
      // setData(siteusersdata) //used for temporary purpose
      await fetchData()
      // props.history.goBack()
    }
  }
  const oncontactClick = async (e, rowData) => {
    e.preventDefault()
    await props.UpdateFormField({ name: "sponsorAccountUserId", value: rowData.contactId });
    await props.UpdateFormField({ name: "siteUserHeading", value: "User Details" });
    props.history.push('/account/studyDetails/site/siteDetails/users/view');
  }

  return (
    <>
      {progress && <div className="fullScreen">
        <CircularProgress
          disableShrink
          className="progressCircle"
          value={90}
          size={100}
          thickness={1.5}
        />
      </div>}
      <div className="d-flex justify-content-end">
        <div className="secondary-header mr-auto">Users</div>
        {isAddUsersSite === "1" ? <div className="">
          <button onClick={handleClick} className="btn btn-primary-blue btn-rounded">
            <i className="fa fa-plus-circle mr-1"></i> ADD USERS</button>
        </div> : ""}

      </div>
      <div className="listing-wrap hekmaGrid">
        <div className="card">
          <div className="hekmaGridBox siteuserstable">
          <Materialtable
              columns={Columns}
              data={data}
              handleRowClick={handleRowClick}
            />
          </div>
        </div>
      </div>
    </>
  )

}

const mapStateToProps = (state) => {
  return {
    formValues: state.form,
  }
}
const mapDispatchToProps = (dispatch) => {
  return {
    UpdateFormField: (obj) => {
      return dispatch(
        {
          type: 'UpdateFormField',
          value: obj.value,
          name: obj.name
        }
      )
    },
    UpdateEntireForm: (obj) => {
      return dispatch(
        {
          type: 'UpdateEntireForm',
          value: obj,
        }
      )
    },
  }
}
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Users));


