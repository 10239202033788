import React, { useEffect, useState, useReducer } from "react";
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import AutoOptHoc from './hoc/autoOptionHoc';
import DatePicker from 'react-date-picker';
import { ErrorLables } from '../common/lables';
import matchSorter from 'match-sorter';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';
import FormControl from '@material-ui/core/FormControl';

export function InputField(props) {
    console.log(props.textDirection,"textdir")
    return (
        <>
            <TextField
                {...props}
                error={props.propsobj.state.formerrobj[props.name]}
                label={props.label}
                // autoComplete="new-password"
                name={props.name}
                type={props.type}
                onChange={props.handleChange}
                placeholder={props.placeholder}
                multiline={props.multiline || false}
                rows={props.rows}
                rowsMax={props.rowsMax}
                fullWidth
                // style={{direction:'rtl'}}
                style={{direction:props.textDirection ? 'rtl':'ltr'}}
                disableClearable
                variant={props.variant != undefined ? props.variant : "standard"}
                disabled={props.disabled}
                value={props.propsobj.formValues[props.name] || ''}
                helperText={props.propsobj.state.formerrtextObj[props.name]}
                inputProps={{
                    onBlur: props.handleBlur,
                    datamust: props.must
                }}
                InputLabelProps={{
                    className: (props.must === 'true') ? 'must' : '',
                    //shrink: true
                }}
            />
        </>
    );
}

export function PasswordField(props) {
    return (
        <>
            <FormControl style={{width:"100%"}}>
                <TextField
                    id="standard-adornment-password"
                    error={props.propsobj.state.formerrobj[props.name]}
                    value={props.propsobj.formValues[props.name] || ''}
                    helperText={props.propsobj.state.formerrtextObj[props.name]}
                    onChange={props.handleChange}
                    label={props.label}
                    name={props.name}
                    fullWidth
                    style={{direction:props.textDirection ? 'rtl':'ltr'}}
                    type={props.showPassword ? 'text' : 'password'}
                    inputProps={{
                        onBlur: props.handleBlur,
                        datamust: props.must
                    }}
                    InputLabelProps={{
                        className: (props.must === 'true') ? 'must' : '',
                        //shrink: true
                    }}
                    InputProps={{
                        endAdornment:
                            <InputAdornment position="end">
                                <IconButton
                                    className="pwdEye"
                                    aria-label="toggle password visibility"
                                    onClick={props.handleClickShowPassword}
                                    onMouseDown={props.handleMouseDownPassword}
                                >
                                    {props.showPassword ? <Visibility /> : <VisibilityOff />}
                                </IconButton>
                            </InputAdornment>,
                    }}

                />
            </FormControl>
        </>
    )
}

export function AutoOptions(props) {
    let propsobj = props.propsobj;
    const autoPropsToHoc = {
        defaultValue: props.default,
        keyName: props.keyName,
        name: props.name,
        propsobj,
        keys: props.keys,
        props
    }

    const renderOption = option => {
        return (
            <>
                {option[autoPropsToHoc.keyName]}
            </>
        )
    }

    let {
        autoHandleChange,
        filterOptions,
        getOptionLabel,
        autoValue,
    } = AutoOptHoc(autoPropsToHoc);

    // try , catch block for update autofilled value. (ex. update contact)
    try {
        if (propsobj.formValues[props.name].length) {
            let defaultVal = {
                value: propsobj.formValues[props.name],
                label: propsobj.formValues[props.name]
            }
            autoValue = defaultVal;
        }
    } catch {
        autoValue = autoValue;
    }

    const callback = (obj) => {
        if (props.callback) props.callback(obj);
    }

    return (
        <Autocomplete
            value={autoValue}
            clearOnEscape={true}
            onChange={(event, val) => { autoHandleChange(event, val, callback) }}
            filterOptions={filterOptions}
            autoHighlight={true}
            debug={true}
            options={props.options}
            getOptionLabel={getOptionLabel}
            renderOption={renderOption}
            freeSolo={props.freeSolo}
            name={props.name}
            disabled={props.disabled}
            disableClearable
            renderInput={params => (
                <TextField
                    {...params}
                    margin="none"
                    fullWidth
                    error={props.propsobj.state.formerrobj[props.name]}
                    helperText={props.propsobj.state.formerrtextObj[props.name]}
                    name={props.name}
                    label={props.label}
                    style={{direction:props.textDirection ? 'rtl':'ltr'}}
                    disabled={props.disabled}
                    inputProps={{
                        ...params.inputProps,
                        datamust: props.must
                    }}
                    InputLabelProps={{
                        className: (props.must === 'true') ? 'must' : '',
                        //shrink: true
                    }}
                />
            )}
        />
    );
}

export function ViewRow(props) {
    return (
        <>
            <div className="form-group">
                <label>{props.text}</label>
                <p className="form-control-plaintext">{props.textvalue}</p>
            </div>
        </>
    )
}


// This can be used when you don't want to use formHOC
export function HekmaTextField(props) {
    return (
        <>
            <TextField
                {...props}
                error={props.state.formerrobj[props.name]}
                label={props.label}
                name={props.name}
                type={props.type}
                onChange={props.handleChange}
                placeholder={props.placeholder}
                fullWidth
                disabled={props.disabled}
                value={props.value || ''}
                helperText={props.state.formerrtextObj[props.name]}
                inputProps={{
                    datamust: props.must,
                    onBlur: props.handleBlur,
                }}
                InputLabelProps={{
                    className: (props.must === 'true') ? 'must' : '',
                }}
                InputProps={props.InputProps}
            />
        </>
    );
}

export function HekmaTextSearchField(props) {
    return (
        <>
            <TextField
                {...props}
                label={props.label}
                name={props.name}
                type={props.type || 'text'}
                onChange={props.handleChange}
                placeholder={props.placeholder}
                style={{
                    width: '100%'
                }}
                value={props.value || ''}
                helperText={''}
                InputProps={props.InputProps}
            />
        </>
    );
}

export function AutoOptionsNew(props) {
    //console.log(props,"site")
    let autoValue = props.autoValue || null;
    const autoHandleChange = async (event, newValue) => {
        if (event) event.preventDefault();
        newValue = newValue === undefined ? value : newValue;
        if (newValue && newValue.inputValue) {// freesolo -> true case
            return {
                [props.keyName]: newValue.inputValue,
            };
        }

        let name = props.name;
        let value = '';
        let flag = false;
        let text = '';

        try {
            if (newValue === null) {// when you select nothing
                value = '';
                newValue = '';
                flag = true;
                text = ErrorLables[name]
            } else {
                value = newValue[props.keyName] === undefined ? newValue : newValue[props.keyName];
                flag = false;
                text = '';
            }
        } catch {
            value = '';
        }

        let obj = {
            name,
            value,
            selected: newValue,
            flag,
            text
        }

        let isItMust = props.must;
        isItMust = (isItMust === 'true') ? true : false;
        if (isItMust) {
            // props.dispatch({...obj,type: 'updateErrorObj'});
            // props.callback(obj);
            props.callback(obj);
            props.propsobj.updateErrorObj(obj);

        } else {
            obj = {
                ...obj,
                flag: false,
                text: ''
            }
            props.callback(obj);
        }
    }

    const filterOptions = (options, { inputValue }) => {
        let keys = props.keys;
        return matchSorter(options, inputValue, { keys: keys })
    }

    const getOptionLabel = option => {
        // e.g value selected with enter, right from the input
        if (props.getOptionLabel) {
            return props.getOptionLabel(option)
        } else {
            if (typeof option === 'string') {
                return option;
            }
            if (option && option.inputValue) {
                return option.inputValue;
            }
            return option && option[props.keyName]
        }
    }

    const renderOption = option => {
        if (props.renderOption) {
            return props.renderOption(option)
        } else {
            return (
                <>
                    {option[props.keyName]}
                </>
            )
        }
    }

    return (
        <Autocomplete
            value={autoValue}
            clearOnEscape={true}
            onChange={autoHandleChange}
            filterOptions={filterOptions}
            autoHighlight={true}
            debug={true}
            disableClearable
            options={props.options}
            disbaled={props.disabled}
            getOptionLabel={getOptionLabel}
            renderOption={renderOption}
            freeSolo={props.freeSolo || false}
            name={props.name}
            renderInput={params => (
                <TextField
                    {...params}
                    margin="none"
                    fullWidth
                    error={props.propsobj.state.formerrobj[props.name]}
                    helperText={props.propsobj.state.formerrtextObj[props.name]}
                    name={props.name}
                    label={props.label}
                    inputProps={{
                        ...params.inputProps,
                        datamust: props.must
                    }}
                    InputLabelProps={{
                        className: (props.must === 'true') ? 'must' : '',
                        // shrink: props.shrink || false
                    }}
                />
            )}
        />
    );
}

// autocomplete for addsite,addpag,addaccountparentselection
export function AutoOptionsMultiple(props) {
    let autoValue = props.autoValue || null;
    const autoHandleChange = async (event, newValue) => {
        if (event) event.preventDefault();
        newValue = newValue === undefined ? value : newValue;
        if (newValue && newValue.inputValue) {// freesolo -> true case
            return {
                [props.keyName]: newValue.inputValue,
            };
        }

        let name = props.name;
        let value = '';
        let flag = false;
        let text = '';

        try {
            if (newValue === null) {// when you select nothing
                value = '';
                flag = true;
                text = ErrorLables[name]
            } else {
                value = newValue[props.keyName] === undefined ? newValue : newValue[props.keyName];
                flag = false;
                text = '';
            }
        } catch {
            value = '';
        }

        let obj = {
            name,
            value,
            selected: newValue,
            flag,
            text
        }

        // let errobj = {
        //     name,
        //     flag,
        //     text
        // }

        let isItMust = props.must;
        isItMust = (isItMust === 'true') ? true : false;
        if (isItMust) {
            props.propsobj.updateErrorObj(obj);
            props.callback(obj);
        } else {
            obj = {
                name,
                value,
                selected: newValue,
                flag: false,
                text: ''
            }
            props.callback(obj);
        }
    }

    const filterOptions = (options, { inputValue }) => {
        let keys = props.keys;
        return matchSorter(options, inputValue, { keys: keys })
    }

    const getOptionLabel = option => {
        // e.g value selected with enter, right from the input
        if (typeof option === 'string') {
            return option;
        }
        if (option && option.inputValue) {
            return option.inputValue;
        }
        return option && option[props.keyName]
    }

    const renderOption = option => {
        return (
            <>

                {props.patientapp ? option.contactName : option.accountName == undefined ? "" : option.accountName + " (" + option.address1 + ' ' + option.address2 + ' ' + option.city + ' ' + option.state + ' ' + option.country + ' ' + option.zip + ' )'}
            </>
        )
    }

    return (
        <Autocomplete
            value={autoValue}
            clearOnEscape={true}
            onChange={autoHandleChange}
            filterOptions={filterOptions}
            autoHighlight={true}
            debug={true}
            options={props.options}
            getOptionLabel={getOptionLabel}
            renderOption={renderOption}
            freeSolo={props.freeSolo || false}
            name={props.name}
            disabled={props.disabled}
            disableClearable
            renderInput={params => (
                <TextField
                    {...params}
                    margin="none"
                    fullWidth
                    style={{direction:props.textDirection ? 'rtl':'ltr'}}
                    //error={props.error || false}
                    //helperText={props.helperText || ''}
                    error={props.propsobj.state.formerrobj[props.name]}
                    helperText={props.propsobj.state.formerrtextObj[props.name]}
                    name={props.name}
                    label={props.label}

                    inputProps={{
                        ...params.inputProps,
                        datamust: props.must
                    }}
                    InputLabelProps={{
                        className: (props.must === 'true') ? 'must' : '',
                        //shrink: true
                    }}
                />
            )}
        />
    );
}

//autodropdown without validation - used for market place results in PIRSidebarHome
export function AutoOptionsWV(props) {
    const autoHandleChange = async (event, newValue) => {
        event.preventDefault();
        props.callback(newValue)
    }
 
    const getOptionLabel = option => {
        // e.g value selected with enter, right from the input
        if (typeof option === 'string') {
            return option;
        }
        if (option && option.inputValue) {
            return option.inputValue;
        }
        return option && option[props.keyName]

    }
    let autoValue = props.autovalue || null;
    return (
        <Autocomplete
            id="asynchronous-demo"
            key={autoValue}
            value={autoValue}
            fullWidth
            // getOptionSelected={(option) => option.value}
            getOptionLabel={getOptionLabel}
            options={props.options}
            clearOnEscape={true}
            filterSelectedOptions
            autoHighlight={true}
            debug={true}
            disableClearable
            onChange={autoHandleChange}
            // filterOptions={filterOptions}
            // // loading={loading}
            // renderOption={renderOption}
            renderInput={(params) => (
                <TextField
                    {...params}
                    label={props.label}
                    variant={props.variant}
                    className="inputfield mb-3"
                    placeholder={props.placeholder}
                />
            )}
        />
    );
}