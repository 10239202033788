import React, { useEffect, useState } from "react";
import CircularProgress from '@material-ui/core/CircularProgress';
import { CustomizedProgressBars } from './roundedProgress';
import Button from '@material-ui/core/Button';
import Oxygen from '../../img/dashboard_icons/blood-oxygen.png'
import BPicon from '../../img/dashboard_icons/BPicon.svg'
import Stepsicon from '../../img/dashboard_icons/footstepsicon.svg'
import BloodGlucose from '../../img/dashboard_icons/Blood-Glucose.svg'
import CaloriesBurnt from '../../img/dashboard_icons/CaloriesBurnt.svg'
import { LocalizationAPiCall } from '../../common/apiconfig'
// import { jwt } from '../../common/jwtAuthentication';
import { connect, useSelector } from 'react-redux';  
import { withRouter } from 'react-router-dom';


function PhysicalActivity(props) {
    const [offset, setoffset] = useState(0)
    const [data, setData] = useState([])
    let [progress, setProgress] = useState(false);
    let [valSearch, setValSearch] = useState('');
//  const lang = useSelector((state) => state.lang);
//   const reduxstateobj = useSelector(state => state);
    
    return (
        <>
            {progress && <div className="fullScreen">
                <CircularProgress
                    disableShrink
                    className="progressCircle"
                    value={90}
                    size={100}
                    thickness={1.5}
                />
            </div>}
            <div className="height100">
                <section className="row">
                    <div className="col d-flex justify-content-between">
                        <h6>
                            {/* Daily Activities */}
                    { props.langData?.length > 0?props.langData.find(item=>item.keyword === "daily_activities_lbl")?.[props.lang] : ""}

                        </h6>
                        {/* <span><i className="fas fa-ellipsis-h"></i></span> */}
                    </div>                </section>
                <section className="height100 d-flex flex-column justify-content-center iconstyle">
                    {/* <section className="d-flex justify-content-center">
                        <Button variant="contained" size="small" style={{boxShadow:"none",borderRadius:"5px",backgroundColor:"#213F6A",color:"white"}}>Today</Button>
                        <Button variant="contained" className="ml-3" size="small" style={{boxShadow:"none",borderRadius:"5px"}}>
                            Weekly
                        </Button>
                    </section> */}
                    <section className="row justify-content-center circular mt2rem">
                        <div className="col-6 justify-content-center align-items-center customizedprogressbar">
                            <CustomizedProgressBars size={80} value={100} color="#59c265" display={props.data.length>0 ? props.data[4].value:0}/>
                            <div className="d-flex justify-content-center align-items-center">
                                <div className="label">
                                    {/* <i class="fas fa-user-clock" style={{color:"#59c265"}}></i><br/> */}
                                    <img src={BPicon} alt="BP"/><br/>
                                    <span className="label">
                                        {/* Blood Pressure */}
                                        { props.langData?.length > 0 && props.langData.map(item=>{if(item.keyword == "dashboard_blood_pressure_lbl"){return item[props.lang]}})} 
                                        </span>
                                </div>
                            </div>
                        </div>
                        <div className="col-6 justify-content-center customizedprogressbar">
                            <CustomizedProgressBars size={80} value={100} color="#02dadb" display={props.data.length>0 ? props.data[1].value:0}/>
                            <div className="d-flex justify-content-center align-items-center">
                                <div className="label">
                                    {/* <i className="fas fa-bed" style={{color:"#02dadb"}}></i><br/> */}
                                    <img src={Stepsicon} alt="steps"/><br/>
                                    <span>
                                        {/* Steps */}
                                        { props.langData?.length > 0 && props.langData.map(item=>{if(item.keyword == "dashboard_steps_lbl"){return item[props.lang]}})} 
                                        </span>
                                </div>
                            </div>
                        </div>
                    </section>
                    <section className="row justify-content-center mt-3 circular">
                        <div className="col-6 justify-content-center customizedprogressbar">
                            <CustomizedProgressBars size={80} value={100} color="#46a3df" display={props.data.length>0 ? props.data[5].value:0}/>
                            <div className="d-flex justify-content-center align-items-center">
                                <div className="label">
                                    {/* <i class="fas fa-oxygen" color="#9c68ba"></i> */}
                                    <img src={BloodGlucose} alt="Blood Glucose"/><br/>
                                    <span className="label">
                                        {/* Blood Glucose */}
                                        { props.langData?.length > 0 && props.langData.map(item=>{if(item.keyword == "dashboard_blood_glucose_lbl"){return item[props.lang]}})} 
                                        </span>
                                </div> 
                            </div>
                        </div>
                        <div className="col-6 justify-content-center customizedprogressbar">
                            <CustomizedProgressBars size={80} value={100} color="#e74b83" display={props.data.length>0 ? `${props.data[3].value}` : 0}/>
                            <div className="d-flex justify-content-center align-items-center">
                                <div className="label">
                                    {/* <i className="fas fa-fire"style={{color:"#e74b83"}}></i> */}
                                    <img src={CaloriesBurnt} alt="Blood Glucose"/><br/>
                                    <span>
                                    
                                        { props.langData?.length > 0 ?props.langData.find(item=>item.keyword === "daily_activities_calories_lbl")?.[props.lang] : ""}

                                        </span>
                                </div>
                            </div>
                        </div>
                    </section>
                </section>

            </div>

        </>
    )

}
const mapStateToProps = (state) => {
    return {
      formValues: state.form,
      langData: state.langData,
      lang:state.lang,
      state
    }
  }
  const mapDispatchToProps = (dispatch) => {
    return {
      localizationdata: (data) => {
        return dispatch({
          type: 'localizationdata',
          value: data
        }
        )
      }
    }
  }
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(PhysicalActivity) );


