import React, { useEffect, useState } from "react";
import Materialtable from '../../../globalcomponents/materialtable';
import { genericApi } from '../../../common/apiconfig';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import CircularProgress from '@material-ui/core/CircularProgress';
// import { checkPermission } from '../../../common/utilis';
import * as R_Items from '../recentItems';

function ResourcesGrid(props) {
  const [offset, setoffset] = useState(0)
  const [data, setData] = useState([])
  let [progress, setProgress] = useState(false);

  useEffect(() => {
    if (props.formValues.patientStudyInfo) {
      fetchData()
    }

  }, [])

  async function fetchData() {

    let requestBody = {
      query: `query {
          getCourseResourceWithSupportAllStatus(studyId:"${props.formValues.patientStudyInfo.studyID}", crs_res_ind:"*",status:"Y") {
            cr {
              ID
              studyId
              name
              crs_res_ind
              summary
              type
              category
              language
              version
              createdBy
              createdTs
              modifiedBy
              modifiedTs
            }
             link {
               ID
               buArea
               buid
               type
               status
               detail1
               detail2
               detail3
               detail4
               addedById
               addedByName
               addedTs
             }
             document {
               ID
               buArea
               buid
               type
               status
               detail1
               detail2
               detail3
               detail4
               addedById
               addedByName
               addedTs
             }
             video {
               ID
               buArea
               buid
               type
               status
               detail1
               detail2
               detail3
               detail4
               addedById
               addedByName
               addedTs
             }
          }
          }`
    };
    requestBody = JSON.stringify(requestBody);
    setProgress(true);
    let res = await genericApi('getCourseResourceWithSupportAllStatus', requestBody);
    //console.log(res)
    setProgress(false);
    if (!res) {
      // Error handling
      //console.log('Error on response material grid!')

    } else {
      if (res.data?.getCourseResourceWithSupportAllStatus.length) {
        var data = res.data.getCourseResourceWithSupportAllStatus
        // var newdata = data.map(v => ({ ...v, ...v.cr }))
        var finaldata = data.map((item) => ({ ...item, ...item.cr })).filter((item) => item.category === "Subject")
        // var finaldata = newdata.filter((item) => item.category === "Subject")
        console.log(finaldata, "finaldata")
        setData(finaldata)
      }
    }
  }

  const handleRowClick = async (event, rowData) => {
    await props.updateCourseData(rowData);
    props.history.push('/patient/viewResource');
  }
  String.prototype.limit = function (length) {
    return this.length > length ? (this.substring(0, length) + '...') : this;
  }
  const Columns = [
    {
      title: props.langData?.length > 0
        ? props.langData.find(item => item.keyword === "resources_name_lbl")?.[props.lang]
        : "", field: 'name',
      cellStyle: {
        color: '#244271',
        fontWeight: 500,
        textAlign: 'left'
      },
      render: rowData => rowData.name.limit(40)
    },
    {
      title: props.langData?.length > 0
        ? props.langData.find(item => item.keyword === "resources_type_lbl")?.[props.lang]
        : "", field: 'type',
      cellStyle: {
        color: '#244271',
        fontWeight: 500,
        width: "15%"
      },
      render: rowData => rowData.type
    },
    {
      title: props.langData?.length > 0
        ? props.langData.find(item => item.keyword === "resources_category_lbl")?.[props.lang]
        : "", field: 'category',
      cellStyle: {
        width: "15%"
      },
      render: rowData => rowData.category
    },
    {
      title: props.langData?.length > 0
        ? props.langData.find(item => item.keyword === "resources_summary_lbl")?.[props.lang]
        : "", field: 'summary', render: data => data.summary.length ? data.summary.limit(50) : '---'
    },
    // {
    //   title: 'Links', field: 'pContact',
    //   cellStyle: { wordBreak: "break-all" },
    //   render: rowdata => {
    //     let output = [];
    //     rowdata.link.map(link => {
    //       output.push(link.detail1);
    //     });
    //     return output.join(', ');
    //   }
    // },


  ]

  return (
    <>
      {progress && <div className="fullScreen">
        <CircularProgress
          disableShrink
          className="progressCircle"
          value={90}
          size={100}
          thickness={1.5}
        />
      </div>}
      <div className="d-flex mb-3 mt-3 justify-content-end">
        <h1 className="mr-auto primary-header">{props.langData?.length > 0
          ? props.langData.find(item => item.keyword === "resources_lbl")?.[props.lang]
          : ""}</h1>
      </div>
      <div className="listing-wrap hekmaGrid">
        <div className="card mb-0">
          <div className="">
            <Materialtable
              columns={Columns}
              data={data}
              handleRowClick={handleRowClick}
            />
          </div>
        </div>
      </div>
    </>
  )

}

const mapStateToProps = (state) => {
  return {
    formValues: state.form,
    langData: state.langData,
    lang: state.lang,
  }
}
const mapDispatchToProps = (dispatch) => {
  return {
    UpdateFormField: (obj) => {
      return dispatch(
        {
          type: 'UpdateFormField',
          value: obj.value,
          name: obj.name
        }
      )
    },
    UpdateEntireForm: (obj) => {
      return dispatch(
        {
          type: 'UpdateEntireForm',
          value: obj,
        }
      )
    },
    updateCourseData: (obj) => {
      return dispatch(
        {
          type: 'updateCourseData',
          value: obj,
        }
      )
    },
    updateRecentItems: (obj) => {
      return dispatch(
        {
          type: 'updateRecentItems',
          value: obj,
        }
      )
    },
  }
}
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ResourcesGrid));


