import React, { useEffect, useState } from "react";
import CircularProgress from '@material-ui/core/CircularProgress';
import LineGraph from './linearWrapper';
import moment from 'moment';
function TestDetailCard(props) {
    const [offset, setoffset] = useState(0)
    const [data, setData] = useState([])
    let [progress, setProgress] = useState(false);
    let [valSearch, setValSearch] = useState('');
    const removeOrdinalSuffix = (dateString) => {
        return dateString.replace(/(\d+)(st|nd|rd|th)/, "$1");
    };
    const formatDate = (dateString) => {
        const cleanedDate = removeOrdinalSuffix(dateString);
        return moment(cleanedDate, "D MMM YYYY").format("ddd, MMM DD");
    };
    return (    
        <>
            {progress && <div className="fullScreen">
                <CircularProgress
                    disableShrink
                    className="progressCircle"
                    value={90}
                    size={100}
                    thickness={1.5}
                />
            </div>}
            <section className="mediCard vaccineCard mb-3" style={{ margin: '0px' }}>
                {props.data?.valueString ?
                    <>
                        {props.data?.date?.map((date, index) => (
                            <div key={index} className="card mb-3 p-3 shadow-sm border rounded">
                                 <div className="labResults text-dark">
                                    {formatDate(date)}
                                </div>
                                <div className="top d-flex justify-content-between mb-2">
                                    <div className="flex-grow-1 d-flex align-items-center">
                                        <span className="mr-2 font-weight-bold">{props.data?.testTitle}</span>
                                    </div>
                                </div>
                                <div className="result-text text-dark">
                                    {props.data?.resultText?.[index]}
                                </div>
                            </div>
                        ))}

                    </> :
                    <>
                        <div className="labResults" style={{ marginRight: "5px" }}>
                        </div>
                        <div className="top d-flex justify-content-between mb-3">
                            <div className="flex-grow-1 d-flex align-items-center">
                                <span className="mr-2">{props.title}</span>
                                {/* <span className="ml-2 ml-auto" style={{ flexShrink: 0 }}>
                            <span><i className="ml-2 far fa-clock"></i></span>
                            <span className="ml-2">{moment(props.data.date).format('MMM Do YYYY')}</span>
                        </span> */}
                            </div>
                        </div>
                        <div className="labResults" style={{ marginRight: "5px" }}>
                            <LineGraph data={props.data.data} data1={props.data.data1} xaxisdata={props.data.xaxis} min={props.data.min} max={props.data.max} name={props.data.testTitle} unit={props.data.unit} />
                        </div>
                    </>
                }

            </section>

        </>
    )
}


export default TestDetailCard;


