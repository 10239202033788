import React, { useEffect, useState, useCallback, useContext, useMemo } from "react";
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import CircularProgress from '@material-ui/core/CircularProgress';
import { ViewRow } from '../../../globalcomponents/formElements';
import { appConfig } from '../../../common/constants'
function PatientDetails(props) {
  //console.log(props.formValues, "patientdata")
  const [offset, setoffset] = useState(0)
  const [data, setData] = useState([])
  let [progress, setProgress] = useState(false);
  let [valSearch, setValSearch] = useState('');
  let [firstName, setFirstName] = useState(props.formValues.patientdata.firstName);
  let [lastName, setLastName] = useState(props.formValues.patientdata.lastName)
  let [familyName, setFamilyName] = useState(props.formValues.patientdata.familyName || "")
  let [city, setCity] = useState(props.formValues.patientdata.city)
  let [state, setState] = useState(props.formValues.patientdata.state)
  let [country, setCountry] = useState(props.formValues.patientdata.country)
  let [zip, setZip] = useState(props.formValues.patientdata.zip)
  let [address1, setAddress1] = useState(props.formValues.patientdata.address1)
  let [gender, setGender] = useState(props.formValues.patientdata.gender)
  let [birthdate, setDob] = useState(props.formValues.patientdata.birthdate || "")
  let [language, setLanguage] = useState(props.formValues.patientdata.language)
  let [phone, setPhone] = useState(props.formValues.patientdata.phone)
  useEffect(() => {
    async function getApiData() {
      setProgress(true)
      let url = `${appConfig.BaseUrl}patient?ids=${props.formValues.patientdata?.subjectId ? props.formValues.patientdata?.subjectId : props.formValues.patientdata?.patientId}&hekmaMail=${props.formValues.patientdata.email}`

      await fetch(url,
        {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
          }
        })
        .then(res => {
          if (res.status !== 200 && res.status !== 201) {
            throw new Error('Failed!');
          }
          return res.json();
        })
        .then(responseData => {
          setProgress(false)
          if (responseData && responseData.data.length > 0) {
            var data = responseData.data[0]
            let firstName = data.name[0].given[0]
            let lastName = data.name[0]?.given[1] || ""
            let familyName = data.name[0].family[0]
            let city = data.address[0].city
            let state = data.address[0].state
            let country = data.address[0].country
            let zip = data.address[0].postalCode || ""
            let address = data.address[0].line[0]
            let gender = data.gender
            let dob = data.birthDate
            let language = data.communication[0].language.text
            let phone = data.telecom[0].value
            setFirstName(firstName)
            setLastName(lastName)
            setFamilyName(familyName)
            setCity(city)
            setState(state)
            setCountry(country)
            setZip(zip)
            setGender(gender)
            setDob(dob)
            setLanguage(language)
            setPhone(phone)
            setAddress1(address)
          }
        })
    }
    getApiData();
  }, []);

  const { email = "", address2 = "" } = props.formValues.patientdata
  return (
    <>
      {progress && <div className="fullScreen">
        <CircularProgress
          disableShrink
          className="progressCircle"
          value={90}
          size={100}
          thickness={1.5}
        />
      </div>}
      <>


        {/* <div className="d-flex ">
          <div class="secondary-header mr-auto">Patient Details</div> 
          <div className="">
          </div>
        </div> */}

        <div className="mt2rem">
          <form noValidate autoComplete="off" id="addAccountForm" className="row h-form-1">
            <div className="col-md-6">
              <div className="row">
                <div className="col-md-12">
                  <div className="panel-primary-wrap">
                    <div className="card">
                      <div className="card-body">
                        <div className="card-page-header">{props.langData?.length > 0 ? props.langData.find(item => item.keyword === "patient_details_lbl")?.[props.lang] : ''}</div>
                        <div className="inputBox">
                          <ViewRow textvalue={firstName + ' ' + lastName} text={props.langData?.length > 0 ? props.langData.find(item => item.keyword === "personal_details_name_lbl")?.[props.lang] : ''} />
                          <ViewRow textvalue={familyName} text={props.langData?.length > 0 ? props.langData.find(item => item.keyword === "personal_details_family_name_lbl")?.[props.lang] : ''} />
                          <ViewRow textvalue={phone} text={props.langData?.length > 0 ? props.langData.find(item => item.keyword === "personal_details_phone_lbl")?.[props.lang] : ''} />
                          <ViewRow textvalue={email} text={props.langData?.length > 0 ? props.langData.find(item => item.keyword === "personal_details_email_lbl")?.[props.lang] : ''} />
                          {/* <ViewRow text="Primary Contact" /> */}
                          <ViewRow text={props.langData?.length > 0 ? props.langData.find(item => item.keyword === "personal_details_study_lbl")?.[props.lang] : ''} />
                          <ViewRow text={props.langData?.length > 0 ? props.langData.find(item => item.keyword === "personal_details_status_lbl")?.[props.lang] : ''} />

                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-12">
                  <div className="panel-primary-wrap">
                    <div className="card">
                      <div className="card-body">
                        <div className="card-page-header">{props.langData?.length > 0 ? props.langData.find(item => item.keyword === "address_info_lbl")?.[props.lang] : ''}</div>
                        <div className="inputBox">
                          <ViewRow textvalue={address1} text={props.langData?.length > 0 ? props.langData.find(item => item.keyword === "address_line1_lbl")?.[props.lang] : ''} />
                          <ViewRow textvalue={address2} text={props.langData?.length > 0 ? props.langData.find(item => item.keyword === "address_line2_lbl")?.[props.lang] : ''} />


                          <ViewRow textvalue={zip} text={props.langData?.length > 0 ? props.langData.find(item => item.keyword === "address_zipcode_lbl")?.[props.lang] : ''} />
                          <ViewRow textvalue={country} text={props.langData?.length > 0? props.langData.find(item => item.keyword === "address_country_lbl")?.[props.lang] : ''} />

                          <ViewRow textvalue={city} text={props.langData?.length > 0 ? props.langData.find(item => item.keyword === "address_city_lbl")?.[props.lang] : ''} />
                          <ViewRow textvalue={state} text={props.langData?.length > 0 ? props.langData.find(item => item.keyword === "address_state_lbl")?.[props.lang] : ''} />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-md-12">
                  <div className="panel-primary-wrap">
                    <div className="card">
                      <div className="card-body">
                        <div className="card-page-header">{props.langData ? props.langData.find(item => item.keyword === "caregiver_lbl")?.[props.lang] : ''} </div>
                        <div className="inputBox">
                          <ViewRow text={props.langData?.length > 0 ? props.langData.find(item => item.keyword === "caregiver_relationship_lbl")?.[props.lang] : ''} />
                          <ViewRow text={props.langData?.length > 0 ? props.langData.find(item => item.keyword === "caregiver_name_lbl")?.[props.lang] : ''} />
                          <ViewRow text={props.langData?.length > 0 ? props.langData.find(item => item.keyword === "caregiver_phone_lbl")?.[props.lang] : ''} />
                          <ViewRow text={props.langData?.length > 0 ? props.langData.find(item => item.keyword === "caregiver_address_lbl")?.[props.lang] : ''} />
                          <ViewRow text={props.langData?.length > 0 ? props.langData.find(item => item.keyword === "caregiver_gender_lbl")?.[props.lang] : ''} />
                          <ViewRow text={props.langData?.length > 0 ? props.langData.find(item => item.keyword === "caregiver_period_lbl")?.[props.lang] : ''} />

                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

            </div>
            <div className="col-md-6">
              <div className="row">
                <div className="col-md-12">
                  <div className="panel-primary-wrap">
                    <div className="card">
                      <div className="card-body">
                        <div className="card-page-header">{props.langData ? props.langData.find(item => item.keyword === "demographics_caps_lbl")?.[props.lang] : ''} </div>
                        <div className="inputBox">
                          <ViewRow textvalue={gender} text={props.langData ? props.langData.find(item => item.keyword === "demographics_gender_lbl")?.[props.lang] : ''} />
                          <ViewRow text={props.langData ? props.langData.find(item => item.keyword === "demographics_height_lbl")?.[props.lang] : ''} />


                          <ViewRow text={props.langData ? props.langData.find(item => item.keyword === "demographics_weight_lbl")?.[props.lang] : ''} />
                          <ViewRow textvalue={birthdate} text={props.langData ? props.langData.find(item => item.keyword === "demographics_date_of_birth_lbl")?.[props.lang] : ''} />
                          <ViewRow text={props.langData ? props.langData.find(item => item.keyword === "demographics_ethinicity_lbl")?.[props.lang] : ''} />
                          <ViewRow textvalue={language} text={props.langData ? props.langData.find(item => item.keyword === "demographics_language_lbl")?.[props.lang] : ''} />

                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-12">
                  <div className="panel-primary-wrap">
                    <div className="card">
                      <div className="card-body">
                        <div className="card-page-header">{props.langData ? props.langData.find(item => item.keyword === "primary_contact_lbl")?.[props.lang] : ''}</div>
                        <div className="inputBox">
                          <ViewRow text={props.langData ? props.langData.find(item => item.keyword === "primary_contact_relationship_lbl")?.[props.lang] : ''} />
                          <ViewRow text={props.langData ? props.langData.find(item => item.keyword === "primary_contact_name_lbl")?.[props.lang] : ''} />
                          <ViewRow text={props.langData ? props.langData.find(item => item.keyword === "primary_phone_lbl")?.[props.lang] : ''} />
                          <ViewRow text={props.langData ? props.langData.find(item => item.keyword === "primary_address_lbl")?.[props.lang] : ''} />
                          <ViewRow text={props.langData ? props.langData.find(item => item.keyword === "primary_gender_lbl")?.[props.lang] : ''} />
                          <ViewRow text={props.langData ? props.langData.find(item => item.keyword === "primary_period_lbl")?.[props.lang] : ''} />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      </>
    </>
  )

}
const mapStateToProps = (state) => {
  return {
    formValues: state.form,
    langData: state.langData,
    lang: state.lang,
    state
  }
}
const mapDispatchToProps = (dispatch) => {
  return {
    UpdateFormField: (obj) => {
      return dispatch(
        {
          type: 'UpdateFormField',
          value: obj.value,
          name: obj.name
        }
      )
    },
    updateErrorObj: (obj) => {
      return dispatch(
        {
          type: 'updateErrorObj',
          flag: obj.flag,
          name: obj.name,
          text: obj.text
        }
      )
    },
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(PatientDetails));


