import React, { useEffect, useState } from "react";
import { apiCall, fileUpload } from '../../../../common/apiconfig';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import CircularProgress from '@material-ui/core/CircularProgress';
import moment from 'moment'
import { Q_status, Q_severity, Q_category, regExpData } from '../../../../common/constants';
import { InputField, AutoOptions } from '../../../formElements';
import FormHoc from '../../../hoc/formHoc';
import HekmaDatePicker from '../../../HekmaDatePicker'
import AllFileAttachments from "../../../../../common/fileattachments";

function Queries(propsobj) {
    const [data, setData] = useState([])
    let [siteData, setSiteData] = useState([])
    let [progress, setProgress] = useState(false);
    let [valSearch, setValSearch] = useState('');
    let [show, setShow] = useState(false)
    let [options, setOptions] = useState([])
    let [text, setText] = useState("Add Query")
    let [queryId, setQueryId] = useState('')
    let [queryNum, setQueryNum] = useState('')
    let [oldData, setOldData] = useState({});

    const [value, setValue] = React.useState(0);
    let [links, setLinks] = React.useState([]);
    let [docFiles, setDocFiles] = React.useState([])
    let [videoFiles, setVideoFiles] = React.useState([])
    let [docsData, setDocsData] = React.useState([]);
    let [videosData, setVideosData] = React.useState([]);
    let [urlErr, setUrlErr] = React.useState('')
    let [docErr, setDocErr] = React.useState('')
    let [videoErr, setVideoErr] = React.useState('')


    useEffect(() => {
        setOldData(propsobj.formValues)
    }, [oldData])

    useEffect(() => {
        getSiteStudyUsers();

    }, []);

    async function getSiteStudyUsers() {
        let requestBody = {
            query: `query {
        getStudySiteContact(studyId:"${propsobj.formValues.studyId}",siteId:"${propsobj.formValues.siteId}") {
          studyId
          siteId
          contactId
          contactName
          role
          primaryContactInd
          email
          phone
          status
                }
              }`
        };

        requestBody = JSON.stringify(requestBody);
        setProgress(true);
        let res = await apiCall('getStudySiteContact', requestBody);
        setProgress(false);
        if (!res) {
            // Error handling
            console.log('Error on response material grid!')

        } else {
            if (res.data.getStudySiteContact.length) {
                let data = res.data.getStudySiteContact
                setSiteData(data);
                var optionsUser = data.map((item, i) => { return { label: item.contactId, value: item.contactName } })
                setOptions(optionsUser)
            } else {
                setSiteData([])
            }
        }
    }
    const hocObj = FormHoc(propsobj);
    const textProps = {
        ...hocObj,
        propsobj
    }
    const autoPropstostatus = {
        'options': Q_status,
        default: {
            value: 'Open',
            label: 'Open',
        },
        freeSolo: false,
        propsobj,
        keys: ['value']
    }
    const autoPropstoseverity = {
        'options': Q_severity,
        default: null,
        freeSolo: false,
        propsobj,
        keys: ['value']
    }
    const autoPropstocategory = {
        'options': Q_category,
        default: null,
        freeSolo: false,
        propsobj,
        keys: ['value']
    }
    const autoPropstousers = {
        'options': options,
        default: null,
        freeSolo: false,
        propsobj,
        keys: ['label']
    }

    const formSubmit = async (event) => {
        event.preventDefault();
        let linksArr = '';
        var linkClearErr = links.length > 0 ? false : true
        links.map(link => {
            if (link.url == "") {
                setUrlErr("Enter URL")
                linkClearErr = false
            }
            else if (regExpData.urlReg.test(link.url)) {
                setUrlErr('')
                linksArr += `{ buArea: "Query",type:"Link", detail1: "${link.url}",detail2:${JSON.stringify(link.description)}}`;
                linkClearErr = true
            } else {
                setUrlErr("Invalid URL")
                linkClearErr = false
            }
        });
        let linkss = linksArr.replace(/}\s*{/g, '},{');
        let docss
        if (docFiles.length == docsData.length) {
            let docsArr = ''
            setDocErr('')
            docsData.map(doc => {
                docsArr += `{buArea: "Query",type:"Document", detail1: "${doc.id}",detail2:"${doc.fileName}",detail3:"${doc.type}"}`;
            })
            docss = docsArr.replace(/}\s*{/g, '},{');
        } else {
            setDocErr("Upload docs to save")
        }
        let videoss
        if (videoFiles.length == videosData.length) {
            let videosArr = ''
            setVideoErr('')
            videosData.map(video => {
                videosArr += `{buArea: "Query",type:"Video", detail1: "${video.id}",detail2:"${video.fileName}",detail3:"${video.type}"}`;
            })
            videoss = videosArr.replace(/}\s*{/g, '},{');
        } else {
            setVideoErr("Upload videos to save")
        }
        var files = []
        // if (linkss.length > 0) {
        files.push(linkss)
        // }
        // if (docss.length > 0) {
        files.push(docss)
        // }
        // if (videoss.length > 0) {
        files.push(videoss)
        // }
        // if (setGo && linkClearErr && (docFiles.length == docsData.length) && (videoFiles.length == videosData.length))
        let setGo = await hocObj.handleSubmit('addQuery', event);
        if (text === "Add Query" ? setGo && linkClearErr && (docFiles.length == docsData.length) && (videoFiles.length == videosData.length) : setGo) {
            setProgress(true);
            let form = propsobj.formValues;

            let requestBody = `
        mutation 
        {
          addStudySiteQuery(studyId: "${form.studyId}", siteId: "${form.siteId}" ,queryTitle:"${form.Q_title}",queryDescription:${JSON.stringify(form.Q_Description)},category:"${form.Q_category}",severity:"${form.Q_severity}",assignedTo:"${form.Q_assignedTo.label}",assignedToName:"${form.Q_assignedTo.value}",sponsorReference1:"",sponsorReference2:"",createdByName:"${localStorage.getItem('loggedInUser')}",supportDetails:[${files}]) {
            code
            type
            message
            attributes
          }  
        }
            `;
            requestBody = JSON.stringify({ query: requestBody });
            let res = await apiCall('addStudySiteQuery', requestBody, form);
            setProgress(false);

            if (res && res.data.addStudySiteQuery.code === '200') {
                propsobj.history.push('/account/studyDetails/site/siteDetails/queries');
                setShow(false)
                propsobj.UpdateFormField({ name: "Q_createddate", value: moment(new Date()).format('YYYY-MM-DD') })
                // propsobj.UpdateFormField({ name: "Q_studyID", value: propsobj.formValues.studyNumber })
                propsobj.UpdateFormField({ name: "Q_status", value: "Open" })
                propsobj.UpdateFormField({ name: "Q_createdby", value: localStorage.getItem("loggedInUser") })
                propsobj.UpdateFormField({ name: "Q_title", value: "" })
                propsobj.UpdateFormField({ name: "Q_Description", value: "" })
                // propsobj.UpdateFormField({ name: "Q_status", value: "" })
                propsobj.UpdateFormField({ name: "Q_severity", value: "" })
                propsobj.UpdateFormField({ name: "Q_category", value: "" })
                propsobj.UpdateFormField({ name: "Q_assignedto", value: "" })
            } else {
                console.log('Error!')
            }
        }
    }







    String.prototype.limit = function (length) {
        return this.length > length ? (this.substring(0, length) + '...') : this;
    }



    const handleCancel = (event) => {
        // event.preventDefault();
        // setShow(false)
        // propsobj.UpdateFormField({value:propsobj.formValues.accountName,name:"accountName"})
        propsobj.history.goBack();
    }


    const getUser = (value) => {
        propsobj.UpdateFormField({ name: "Q_assignedTo", value: value.selected })
    }
    //attach event
    const handleLinksChange = (event, index) => {
        var products = links.slice() //or [...links];
        var newUris = products.map((item, i) => {
            if (i == index) {
                item[event.target.name] = event.target.value
            }
            return item
        })
        setLinks(newUris)

    }
    const addNew = () => {
        if (links.length >= 1) {
            if (links[links.length - 1].url == "") {
                setUrlErr("Enter URL")
            }
            else if (!regExpData.urlReg.test(links[links.length - 1].url)) {
                setUrlErr("Invalid URL")
            } else {
                setUrlErr('')
                var newlinks = [...links, { url: "", description: "" }]
                setLinks(newlinks)
            }
        } else {
            var newlinks = [...links, { url: "", description: "" }]
            setLinks(newlinks)
        }
    }
    const removeLink = (e, index) => {
        var arr = [...links]
        // if (index != 0) {
        arr.splice(index, 1);
        // }
        setLinks(arr)
        if (arr.length == 0) {
            setUrlErr('')
        }
    }

    //uploaded docs or videos to save
    const fileSave = async (files) => {
        if (value == 1) {
            var docsInfo = []
            for (var i = 0; i < files.length; i++) {
                let requestBody = { "filename": files[i].name }
                requestBody = JSON.stringify(requestBody);
                setProgress(true)
                let res = await fileUpload('fileupload', requestBody);
                if (res.code == 200) {
                    var data = JSON.parse(res.attributes)
                    var fdata = {
                        id: data.Id,
                        fileName: files[i].name,
                        type: files[i].type
                    }
                    docsInfo.push(fdata)
                    let blob = new Blob([files[i]]);
                    const response = await fetch(data.URL, {
                        method: "PUT",
                        headers: {
                            'Content-Type': files[i].type,
                        },
                        body: blob,
                    });
                    const status = await response.status;
                    if (status == 200) {
                        setProgress(false)
                    }

                } else {
                    alert('failed to upload')
                }
            }
            setDocsData(docsInfo)
        }
        if (value == 2) {
            var videosInfo = []
            setProgress(true)
            for (var i = 0; i < files.length; i++) {
                let requestBody = { "filename": files[i].name }
                requestBody = JSON.stringify(requestBody);
                setProgress(true)
                let res = await fileUpload('fileupload', requestBody);
                if (res.code == 200) {
                    var data = JSON.parse(res.attributes)
                    var vdata = {
                        id: data.Id,
                        fileName: files[i].name,
                        type: files[i].type
                    }
                    videosInfo.push(vdata)
                    let blob = new Blob([files[i]]);
                    const response = await fetch(data.URL, {
                        method: "PUT",
                        headers: {
                            'Content-Type': files[i].type,
                        },
                        body: blob,
                    });
                    const status = await response.status;
                    if (status == 200) {
                        setProgress(false)
                    }
                } else {
                    alert('failed to upload')
                }
            }
            setVideosData(videosInfo)
        }

    }

    const onChangeFile = (files) => {
        var filteredArray = [];
        var existingdata = (value == 1) ? docFiles : videoFiles
        if (files.length) {
            for (var index = 0; index < files.length; index++) {
                let exists = false;
                for (var i = 0; i < existingdata.length; i++) {
                    if (existingdata[i].name === files[index].name) {
                        exists = true;
                        break;
                    }
                }
                if (!exists) {
                    filteredArray.push(files[index]);
                }
            }
        }
        var data = filteredArray.map(file => Object.assign(file, {
            preview: URL.createObjectURL(file)
        }))
        if (value == 1) {
            setDocFiles([...docFiles, ...data])
        }
        if (value == 2) {
            setVideoFiles([...videoFiles, ...data])
        }
    }
    const removeFile = (e, name) => {
        if (value == 1) {
            var filesData = [...docFiles]
            docFiles.map(file => (file.name === name && filesData.splice(filesData.indexOf(file), 1)))
            var newDocsData = [...docsData]
            docsData.map(file => (file.fileName === name && newDocsData.splice(newDocsData.indexOf(file), 1)))
            setDocFiles(filesData)
            setDocsData(newDocsData)
        }
        if (value == 2) {
            var filesData = [...videoFiles]
            videoFiles.map(file => (file.name === name && filesData.splice(filesData.indexOf(file), 1)))
            setVideoFiles(filesData)
            var newVideosData = [...videosData]
            videosData.map(file => (file.fileName === name && newVideosData.splice(newVideosData.indexOf(file), 1)))
            setVideosData(newVideosData)
        }
    }
    const handleTabsChange = (event, newValue) => {
        setValue(newValue);
    };

    return (
        <>
            {progress && <div className="fullScreen">
                <CircularProgress
                    disableShrink
                    className="progressCircle"
                    value={90}
                    size={100}
                    thickness={1.5}
                />
                {/* <Loader/> */}
            </div>}


            <>
                <div className="d-flex mt-4 justify-content-end">
                    <div className="mr-auto secondary-header"> Add Query</div>
                </div>

                <div className="">
                    <form noValidate autoComplete="off" id="addQuery" className="row" onSubmit={formSubmit}>
                        <div className="col-md-6">
                            <div className="panel-primary-wrap">
                                <div className="card">
                                    <div className="card-body">
                                        <div className="inputBox">
                                            <div className="row">
                                                <div className="col-md-6">
                                                    <InputField {...textProps} name="Q_title" label="Query Title" must={'true'} type="text" disabled={text === "Update Query"} />
                                                </div>
                                                <div className="col-md-6">
                                                    <HekmaDatePicker name="Q_createddate" label="Created Date" must={'true'} maxDate={new Date()} disabled={text === "Update Query"} />
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-md-12">
                                                    <InputField {...textProps} multiline={true} rows={3} rowsMax={2} name="Q_Description" label="Description" must={'true'} type="text" disabled={text === "Update Query"} />
                                                </div>
                                                {/* <div className="col-md-6">
                            <InputField {...textProps} name="Q_studyID" label="StudyID" must={'false'} type="text" disabled />
                          </div> */}
                                            </div>
                                            <div className="row">
                                                <div className="col-md-6">
                                                    <AutoOptions {...autoPropstostatus} name="Q_status" label="Status" keyName='value' must={'true'} disabled={text != "Update Query" || propsobj.formValues.Q_status === "Open"} />
                                                </div>
                                                <div className="col-md-6">
                                                    <AutoOptions {...autoPropstoseverity} name="Q_severity" label="Severity" keyName='value' must={'true'} disabled={text === "Update Query"} />
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-md-6">
                                                    <AutoOptions {...autoPropstocategory} name="Q_category" label="Category" keyName='value' must={'true'} disabled={text === "Update Query"} />
                                                </div>
                                                <div className="col-md-6">
                                                    <InputField {...textProps} name="Q_createdby" label="Created By" must={'false'} type="text" disabled />
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-md-6">
                                                    <InputField {...textProps} name="Q_resolvedDate" label="Resolved Date" must={'false'} type="text" disabled />
                                                </div>
                                                <div className="col-md-6">
                                                    <AutoOptions {...autoPropstousers} name="Q_assignedto" label="Assigned To" keyName='value' must={'true'} callback={getUser} disabled={text === "Update Query" || propsobj.formValues.Q_status === "Resolved"} />
                                                </div>
                                            </div>

                                            <div className="row">
                                                <div className="col-md-12">
                                                    <InputField {...textProps} name="Q_responsefromsite" label="Response from Site" must={'false'} type="text" disabled multiline={true} rows={3} rowsMax={2} />
                                                </div>
                                                <div className="col-md-6">

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>

                        </div>
                        <div className="col-md-12">
                            <div className="panel-primary-wrap">
                                <div className="card">
                                    <div className="card-body">
                                        <div className="inputBox">
                                            {console.log(links, "links")}
                                            <AllFileAttachments type="Query" links={links} handleLinksChange={handleLinksChange} removeLink={removeLink} addNew={addNew} fileSave={fileSave} onChangeFile={onChangeFile} docFiles={docFiles} removeFile={removeFile} docsData={docsData} videoFiles={videoFiles} videosData={videosData} urlErr={urlErr} docErr={docErr} videoErr={videoErr} value={value} handleTabsChange={handleTabsChange} disabled={text !== "Add Query"} text={text} />
                                        </div>
                                    </div></div></div>
                            <div className="buttonContainer">
                                <button type="submit" className="btn btn-primary-blue btn-rounded btn-small mr-1" >Save</button>
                                <button className="btn btn-primary-grey btn-rounded btn-small" onClick={handleCancel}>CANCEL</button>
                            </div>
                        </div>
                    </form>
                </div>
            </>

        </>
    )

}

const mapStateToProps = (state) => {
    return {
        formValues: state.form,
        state
    }
}
const mapDispatchToProps = (dispatch) => {
    return {
        UpdateFormField: (obj) => {
            return dispatch(
                {
                    type: 'UpdateFormField',
                    value: obj.value,
                    name: obj.name
                }
            )
        },
        clearForm: () => {
            return dispatch(
                {
                    type: 'ClearForm',
                }
            )
        },
        updateErrorObj: (obj) => {
            return dispatch(
                {
                    type: 'updateErrorObj',
                    flag: obj.flag,
                    name: obj.name,
                    text: obj.text
                }
            )
        },
        updateEntireErrorObj: (errobj, errtextobj) => {
            return dispatch(
                {
                    type: 'updateEntireErrorObj',
                    errobj: errobj,
                    errtextobj: errtextobj,
                }
            )
        },
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Queries));
