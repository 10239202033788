import { rolesLovApi } from './apiconfig';
export const appConfig = {
    host1:`${process.env.REACT_APP_HOST1}`,
    host2:`${process.env.REACT_APP_HOST2}`,
    host3:`${process.env.REACT_APP_HOST3}`,
    uri: `${process.env.REACT_APP_URI}`,
    datamapping_url:`${process.env.REACT_APP_DATAMAPPING_URI}`,
    securityuri:  `${process.env.REACT_APP_SECURITYURI}`,
    ipInfoApi: `${process.env.REACT_APP_IPINFOAPI}`,
    PIRApi: `${process.env.REACT_APP_PIRAPI}`,
    AIApi:`${process.env.REACT_APP_AIAPI}`,
    AIPatients: `${process.env.REACT_APP_AIPATIENTS}`,
    BaseUrl: `${process.env.REACT_APP_BASEURL}`,
    marketplaceBaseApi: `${process.env.REACT_APP_MARKETPLACEBASEAPI}`,
    devurl:`${process.env.REACT_APP_DEVURL}`,
    stripe_publishable_key:`${process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY}`,
    paginationLimit: 5
}
export const editorConfig = {
    placeholder: "Add Content",
    toolbarButtonSize: "small",
    enableDragAndDropFileToEditor: true,
    showXPathInStatusbar: false,
    showCharsCounter: false,
    showWordsCounter: false,
    // height:220,
    spellcheck: true,
    toolbarSticky: true,
    // toolbarAdaptive: false,
    // image:false,
    removeButtons: ["image", "video"],
    toolbarInlineDisabledButtons: ["image", "video"],
    enter: "div",
    readonly: false // all options from https://xdsoft.net/jodit/doc/
}
export const regExpData = {
    phoneReg: /^\+(\d{1,3})[ -.()]?\d{1,4}[ -.()]?\d{3,5}[ -.()]?\d{3,5}$/,
    passwordReg: /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/,
    urlReg: /^(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&=]*)/
}
export const initialFormState = {
    templateName: "",
    emailtemplatestatus: "",
    ssotokens: "",
    globalsearch: true,
    AIPatientsData: [],
    username: "",
    password: "",
    autostudy: "",
    autosite: "",
    fromtext: "",
    ipSubject: "",
    //add pateint
    fName: "",
    mname: "",
    lName: "",
    phone: "",
    email: "",
    patientid: "",
    alternateid: "",
    address1: "",
    address2: "",
    city: "",
    country: "",
    countryCode: "",
    state: "",
    zip: "",
    hidestudytab: true,
    //course links
    coursesdata: [],
    //add resource link
    courselink: "",
    summary: "",
    name: "",
    summary: "",
    category: "",
    type: "",
    language: "",
    version: "",
    //add user
    user_accountName: "",
    user_firstName: "",
    user_lastName: "",
    user_mname: "",
    user_position: "",
    user_roleId: "",
    user_role: "",
    user_email: "",
    user_phone: "",
    user_roleName: "",
    user_lang: "",
    user_portalaccess: "",
    sponsorAccountId: "",
    //edit user
    sponsorAccountUserId: "",
    middleName: '',
    firstName: '',
    lastName: '',
    SponsorAccountName: '',
    role: '',
    email: '',
    phone: '',
    language: '',
    position: '',
    // ---------
    portaltoken: true,
    pDashboardData: [],

    //signup page
    profile_fname: "",
    profile_lname: "",
    profile_email: "",
    profile_lang: "",
    profile_username: "",
    profile_password: "",
    profile_cpassword: "",
    profile_app:"",

    //user
    userfname: "",
    userlname: "",
    usermname: "",
    userlang: "",
    userrole: "",
    userposition: "",
    userphone: "",
    useraccountName: "",
    useremail: "",
    usersponsorAccountId: "",
    addpatientfromAI: false,
    pirpatienttoken: false,
    //add appointment
    appTitle: "",
    appStartDate: "",
    appEndDate: "",
    //sponsor
    accountSearchText: '',
    contactSearchText: "",
    studySearchText: "",
    segments: [],
    username: "",
    userid: "",
    password: "",
    fname: "",
    lname: "",
    mname: "",
    lang: "",
    roles: "",
    phone: "",
    accountName: "",
    email: "",
    forgot_email: "",
    firstName: "",
    lastName: "",
    middleName: "",
    position: "",
    roleId: "",
    //used in update contact
    role: "",
    ///////////////////
    roleName: "",
    language: "",
    sponsorAccountId: "",
    parentAccountSelection: false,
    parentAccountId: "",
    SponsorAccountName: "",
    // addAccount
    // accName: "",
    accountType: "",
    parAccount: "",
    status: "",
    duns: "",
    website: "",
    // phone:"",
    // email:"",
    primaryContact: "",
    address1: "",
    address2: "",
    city: "",
    state: "",
    country: "",
    countryCode: "US",
    zip: "",
    //editaccount
    primaryContactSearch: false,
    // primaryContact:"",
    //accountsearch
    accountsearch: "",
    //add Study
    studyId: "",
    studyNumber: "",
    parentAccountId: "",
    studyTitle: "",
    nameofIMP: "",
    devPhase: "",
    studySponsor: "",
    status: "",
    primaryEndPoint: "",
    secondaryEndPoint: "",
    expEndPoint: "",
    startDt: "",
    endDt: "",
    studyDuration: "",
    stoppingCriteria: "",
    numberOfSitesPlanned: "",
    numberOfSubPlanned: "",
    route: "",
    regiman: "",
    dosage: "",
    statMethod: "",
    protocolSynopsisActual: "",
    protocolFinalActual: "",
    fdaSubmissionActual: "",
    firstSite_OpenActual: "",
    fpiActualActual: "",
    lpoActualActual: "",
    dbLockActual: "",
    csrActualActual: "",
    protocolSynopsisReceived: "",
    protocolFinalReceived: "",
    fdaSubmissionReceived: "",
    firstSite_OpenReceived: "",
    fpiReceivedReceived: "",
    lpoReceivedReceived: "",
    dbLockReceived: "",
    csrReceivedReceived: "",
    interimAnalysis: "",
    studyFlow: "",
    datepicker: '',
    datepicker1: '',

    pagName: "",
    siteName: "",
    //add ie criteria
    type: "",
    description: "",
    effectiveDate: "",
    severity: "",
    //add AE
    AE_subjectid: "",
    AE_term: "",
    AE_severity: "",
    AE_causality: "",
    AE_outcome: "",
    AE_startDate: "",
    AE_stopdate: "",
    //edit AE
    patientId: "",
    aeTerm: "",
    severity: "",
    causality: "",
    outcome: "",
    startDate: "",
    stopDate: "",
    //add site
    addsite_onboardStatus: "",
    addsite_limitization: "",
    onboardStatus: "",
    limitization: "",
    //courses
    name: "",
    summary: "",
    category: "",
    type: "",
    language: "",
    version: "",
    cpassword: "",
    //addsiteusers
    position: "",
    //events
    tabvalue: 0,
    addpag_status: "",
    newEvent: "",
    //to show all accounts in studypersonal
    AccountnamesArr: [],
    AccountidsArr: [],
    studypersonalcontacts: [],
    //user
    userfname: "", userlname: "", usermname: "", userlang: "", userrole: "", userposition: "", userphone: "", useraccountName: "", useremail: "", usersponsorAccountId: "",
    //using in selectcontacts for assigning multiple contacts to site
    accountContacts: "",
    doctorName: "",
    patientissues: "",
    app_date: "",
    app_starttime: "",
    app_endtime: "",
    app_comments: "",
    app_subject: "",
    app_note: "",
    app_doctor_mail: "",
    app_patient_mail: "",
    studyprogressdata: [],
    patientMultilist: '',
    ach_routing: "",
    ach_accountnum: "",
    ach_accounttype: "",
    ach_bankname: "",
    ach_name: "",
    Market_Price: "",
    videoRecPer: false,
    MC_disease: "",
    MC_startdate: "",
    MC_stopdate: "",
    MC_clinicalstatus: "",
    MC_medication: "",
    MC_instruction: "",
    MC_testname:"",
    MC_value:"",
    MC_unit:"",
    MC_resultdate:"",
    MC_vaccinename:"",
    MC_datetaken:"",
    MC_allergyname:"",
    MC_category:"",
    MC_severity:"",
    MC_reaction:"",
    MC_reactionseverity:"",
    req_price:"",
    req_pricingtype:"",cart:[]
};

export let ErrorObj = {}
for (let i in initialFormState) {
    ErrorObj[i] = false;
}

export const ErrorTexts = { ...initialFormState }

export const timeInfo = [
    { value: "09:00", label: "09:00" },
    { value: "09:15", label: "09:15" },
    { value: "09:30", label: "09:30" },
    { value: "09:45", label: "09:45" },
    { value: "10:00", label: "10:00" },
    { value: "10:15", label: "10:15" },
    { value: "10:30", label: "10:30" },
    { value: "10:45", label: "10:45" },
    { value: "11:00", label: "11:00" },
    { value: "11:15", label: "11:15" },
    { value: "11:30", label: "11:30" },
    { value: "11:45", label: "11:45" },
    { value: "12:00", label: "12:00" },
    { value: "12:15", label: "12:15" },
    { value: "12:30", label: "12:30" },
    { value: "12:45", label: "12:45" },
    { value: "13:00", label: "13:00" },
    { value: "13:15", label: "13:15" },
    { value: "13:30", label: "13:30" },
    { value: "13:45", label: "13:45" },
    { value: "14:00", label: "14:00" },
    { value: "14:15", label: "14:15" },
    { value: "14:30", label: "14:30" },
    { value: "14:45", label: "14:45" },
    { value: "15:00", label: "15:00" },
    { value: "15:15", label: "15:15" },
    { value: "15:30", label: "15:30" },
    { value: "15:45", label: "15:45" },
    { value: "16:00", label: "16:00" },
    { value: "16:15", label: "16:15" },
    { value: "16:30", label: "16:30" },
    { value: "16:45", label: "16:45" },
    { value: "17:00", label: "17:00" },
    { value: "17:15", label: "17:15" },
    { value: "17:30", label: "17:30" },
    { value: "17:45", label: "17:45" },
    { value: "18:00", label: "18:00" },
    { value: "18:15", label: "18:15" },
    { value: "18:30", label: "18:30" },
    { value: "18:45", label: "18:45" },
    { value: "19:00", label: "19:00" },
    { value: "19:15", label: "19:15" },
    { value: "19:30", label: "19:30" },
    { value: "19:45", label: "19:45" },
    { value: "20:00", label: "20:00" },
    { value: "20:15", label: "20:15" },
    { value: "20:30", label: "20:30" },
    { value: "20:45", label: "20:45" },
    { value: "21:00", label: "21:00" },
    { value: "21:15", label: "21:15" },
    { value: "21:30", label: "21:30" },
    { value: "21:45", label: "21:45" },
    { value: "22:00", label: "22:00" },
    { value: "22:15", label: "22:15" },
    { value: "22:30", label: "22:30" },
    { value: "22:45", label: "22:45" },
    { value: "23:00", label: "23:00" },
    { value: "23:15", label: "23:15" },
    { value: "23:30", label: "23:30" },
    { value: "23:45", label: "23:45" },
    { value: "00:00", label: "00:00" },
    { value: "00:15", label: "00:15" },
    { value: "00:30", label: "00:30" },
    { value: "00:45", label: "00:45" },
    { value: "01:00", label: "01:00" },
    { value: "01:15", label: "01:15" },
    { value: "01:30", label: "01:30" },
    { value: "01:45", label: "01:45" },
    { value: "02:00", label: "02:00" },
    { value: "02:15", label: "02:15" },
    { value: "02:30", label: "02:30" },
    { value: "02:45", label: "02:45" },
    { value: "03:00", label: "03:00" },
    { value: "03:15", label: "03:15" },
    { value: "03:30", label: "03:30" },
    { value: "03:45", label: "03:45" },
    { value: "04:00", label: "04:00" },
    { value: "04:15", label: "04:15" },
    { value: "04:30", label: "04:30" },
    { value: "04:45", label: "04:45" },
    { value: "05:00", label: "05:00" },
    { value: "05:15", label: "05:15" },
    { value: "05:30", label: "05:30" },
    { value: "05:45", label: "05:45" },
    { value: "06:00", label: "06:00" },
    { value: "06:15", label: "06:15" },
    { value: "06:30", label: "06:30" },
    { value: "06:45", label: "06:45" },
    { value: "07:00", label: "07:00" },
    { value: "07:15", label: "07:15" },
    { value: "07:30", label: "07:30" },
    { value: "07:45", label: "07:45" },
    { value: "08:00", label: "08:00" },
    { value: "08:15", label: "08:15" },
    { value: "08:30", label: "08:30" },
    { value: "08:45", label: "08:45" },
]

export const develpomentPhase = [
    {
        value: "Phase1",
        label: "Phase1"
    },
    {
        value: "Phase2",
        label: "Phase2"
    },
    {
        value: "Phase3",
        label: "Phase3"
    },
    {
        value: "Phase4",
        label: "Phase4"
    },
    {
        value: "Observational Study",
        label: "Observational Study"
    }
]

export const Causality = [
    {
        label: "Certain",
        value: "Certain"
    },
    {
        label: "Probable/Likely",
        value: "Probable/Likely"
    },
    {
        label: "Possible",
        value: "Possible"
    },
    {
        label: "Unlikely",
        value: "Unlikely"
    },
    {
        label: "Conditional/Unclassified",
        value: "Conditional/Unclassified"
    },
    {
        label: "Unassessable/Unclassifiable",
        value: "Unassessable/Unclassifiable"
    },
]
export const AE_Outcome = [
    {
        label: "Recovered/Resolved",
        value: "Recovered/Resolved"
    },
    {
        label: "Recovered with sequel",
        value: "Recovered with sequel"
    },
    {
        label: "Ongoing",
        value: "Ongoing"
    },
    {
        label: "Ongoing with change",
        value: "Ongoing with change"
    },
    {
        label: "Fatal",
        value: "Fatal"
    },
    {
        label: "Unknown",
        value: "Unknown"
    },
    {
        label: "Others",
        value: "Others"
    }
]

export const Races=[
    {name:"White/Caucasian",value:"White/Caucasian"},
    {name:"Black/African–American",value:"Black/African–American"},
    {name:"Asian",value:"Asian"},
    {name:"Native Hawaiian/Pacific Islander",value:"Native Hawaiian/Pacific Islander"},
    {name:"American–Indian/Native Alaskan",value:"American–Indian/Native Alaskan"},
    {name:"Hispanic/Latino",value:"Hispanic/Latino"},
    {name:"Other/unknown",value:"Other/unknown"}
]

export const Dropdowns = {
    Account_Type: [],
    Account_Status: [],
    Study_Status: [],
    Contact_Role: [],
    Contact_Status: [],
    SQ: [],
    Study_Site_Onboard_Status: [],
    Study_Site_Primary_Contact_Role: [],
    Protocol_Setup_IE_Criteria_Type: [],
    Protocol_Setup_IE_Criteria_Status: [],
    Protocol_Setup_AE_Type: [],
    Protocol_Setup_RBM_Severity: [],
    Languages: [],
    Type: [{ name: "General", value: "General" }, { name: "Study-related", value: "Study-related" }],
    Category: [{ name: "Sponsor", value: "Sponsor" }, { name: "Site", value: "Site" }, { name: "Subject", value: "Subject" }],
}
export const roles = async () => {
    let requestBody = {
        query: `query {
          getRoles(roleId:"*") {
            roleId
            roleName
            createdTs
          }
        }`
    };

    requestBody = JSON.stringify(requestBody);
    let res = await rolesLovApi('getRoles', requestBody);
    if (!res) {
        // Error handling
        console.log('Error fetching roles!')

    } else {
        Dropdowns.Roles = []
        if (res.data.getRoles != null && res.data.getRoles.length) {
            Dropdowns.Roles = res.data.getRoles
            //   setData(res.data.getRoles)
        } else {
            Dropdowns.Roles.push([])
        }
    }
}
export const funcDropdown = async () => {
    let requestBody = {
        query: `query {
            getAllLovs(searchStr:"*") {
                id:lovKey
                lovType
                value:value1
                name:value1
                sequence:value3
                  }
            }`
    };

    requestBody = JSON.stringify(requestBody);
    let res = await rolesLovApi('getAllLovs', requestBody);
    if (!res) {
        // Error handling
        console.log('Error!')

    } else {
        if (res.data.getAllLovs.length) {
            var output = res.data.getAllLovs
            Dropdowns.Account_Type = []
            Dropdowns.Account_Status = []
            Dropdowns.Study_Status = []
            Dropdowns.Contact_Role = []
            Dropdowns.Contact_Status = []
            Dropdowns.SQ = []
            Dropdowns.Study_Site_Onboard_Status = []
            Dropdowns.Study_Site_Primary_Contact_Role = []
            Dropdowns.Protocol_Setup_IE_Criteria_Type = []
            Dropdowns.Protocol_Setup_IE_Criteria_Status = []
            Dropdowns.Protocol_Setup_AE_Type = []
            Dropdowns.Protocol_Setup_RBM_Severity = []
            Dropdowns.Languages = []
            Dropdowns.Email_Template_Status = []
            for (var i = 0; i < output.length; i++) {
                if (output[i].lovType === "Account_Type") {
                    Dropdowns.Account_Type.push(output[i])
                } else if (output[i].lovType === "Account_Status") {
                    Dropdowns.Account_Status.push(output[i])
                } else if (output[i].lovType === "Study_Status") {
                    Dropdowns.Study_Status.push(output[i])
                } else if (output[i].lovType === "Contact_Role") {
                    Dropdowns.Contact_Role.push(output[i])
                } else if (output[i].lovType === "Contact_Status") {
                    Dropdowns.Contact_Status.push(output[i])
                } else if (output[i].lovType === "SQ") {
                    Dropdowns.SQ.push(output[i])
                } else if (output[i].lovType === "Study_Site_Onboard_Status") {
                    Dropdowns.Study_Site_Onboard_Status.push(output[i])
                } else if (output[i].lovType === "Study_Site_Primary_Contact_Role") {
                    Dropdowns.Study_Site_Primary_Contact_Role.push(output[i])
                } else if (output[i].lovType === "Protocol_Setup_IE_Criteria_Type") {
                    Dropdowns.Protocol_Setup_IE_Criteria_Type.push(output[i])
                } else if (output[i].lovType === "Protocol_Setup_RBM_Severity") {
                    Dropdowns.Protocol_Setup_RBM_Severity.push(output[i])
                } else if (output[i].lovType === "Protocol_Setup_IE_Criteria_Status") {
                    Dropdowns.Protocol_Setup_IE_Criteria_Status.push(output[i])
                } else if (output[i].lovType === "Languages") {
                    Dropdowns.Languages.push(output[i])
                } else if (output[i].lovType === "Protocol_Setup_AE_Type") {
                    Dropdowns.Protocol_Setup_AE_Type.push(output[i])
                } else if (output[i].lovType === "Email_Template_Status") {
                    Dropdowns.Email_Template_Status.push(output[i])
                }
            }
        }
    }
}

export let ddlist = [
    'Medical Condition',
    'Demographics',
    'Lab',
    'Drug',
    'Allergy'
]

export const removeDuplicates = (data, key) => {
    return [
        ...new Map(data.map(item => [key(item), item])).values()
    ]
};