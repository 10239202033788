import React from "react";
import SmallLogo from '../../../img/hekma-logo.svg';


const Headerwithicon = (props) => {
  return (
    <>
      {/* <!-- Content Wrapper --> */}
      <div id="content-wrapper" className="d-flex flex-column">
        {/* <!-- Main Content --> */}
        <div id="content">
          {/* <!-- Topbar --> */}
          <nav className="navbar navbar-expand navbar-light bg-header-shadow topbar static-top">
            {/* <!-- Sidebar Toggle (Topbar) --> */}
            <button id="sidebarToggleTop" className="btn btn-link d-md-none rounded-circle mr-2">
              <i className="fa fa-bars"></i>
            </button>
            <a href="#" className="ml-xs-1 ml-md-3 mr-xs-2 mr-md-5" ><img src={SmallLogo} alt="logo-small" /></a>
          </nav>
        </div>
      </div>
    </>
  )
}

export default Headerwithicon;

