import React, { useEffect, useState } from "react";
import SmallLogo from '../img/hekma-logo.svg';
import { loginApi, genericApi, oldgenericApi, apiCall, LocalizationAPiCall } from '../common/apiconfig';
import { appConfig } from '../common/constants'
import { withRouter, useLocation } from 'react-router-dom';
import ErrorIcon from '@material-ui/icons/Error';
import CircularProgress from '@material-ui/core/CircularProgress';
import { FormControl, FormLabel, RadioGroup, FormControlLabel, Radio } from '@material-ui/core';

import { jwt } from '../common/jwtAuthentication';
import FormHoc from '../globalcomponents/hoc/formHoc';
import { InputField, PasswordField } from '../globalcomponents/formElements';
import { connect } from 'react-redux';
import { GoogleLogin } from 'react-google-login'
import FacebookLogin from 'react-facebook-login';
import { regExpData } from '../common/constants';
import { elementClosest } from "@fullcalendar/react";
import SignUpForm from './signupform';
import { useSelector, dispatch } from "react-redux";
import { Dropdown } from "react-bootstrap";


const LoginForm = (propsobj) => {
  let [apiError, setApiError] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  let [progress, setProgress] = useState(false);
  let [showLogin, setShowLogin] = useState(true);
  let [showforgotPwd, setShowforgotPwd] = useState(false);
  let [open, setOpen] = useState(false);
  let [SSOError, setSSOError] = useState('')
  let location = useLocation();
  let [lang, setLang] = useState(propsobj.lang || 'en_US')
  let [errMsg, setErrMsg] = useState(propsobj.langData?.length > 0 && propsobj.langData.map(item => { if (item.keyword == "sigin_err_msg_lbl") { return item[lang] } }));

  let [showPassword, setShowPassword] = useState(false)
  let [showPage, setShowPage] = useState({
    login: true,
    forgotPwd: false,
    updatePwd: false
  })
  const showPages = {
    login: false,
    forgotPwd: false,
    updatePwd: false
  }



  const handleLanguageChange = (event) => {
    const selectedLang = event.target.value;
    setLang(selectedLang);
    setIsOpen(false);
    console.log(selectedLang);
    propsobj.setLang(selectedLang);
    window.location.reload();

  }

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  let hocObj = FormHoc(propsobj);

  var partnerId = ""
  if (window.location.hostname === appConfig.host1) {
    partnerId = "1001"
  } else if (window.location.hostname === appConfig.host2) {
    partnerId = "1002"
  } else {
    partnerId = "1001"
  }

  const formSubmit = async (event) => {
    event.preventDefault();
    if (propsobj.formValues.password.length > 0) {
      let phoneCheck = regExpData.passwordReg.test(propsobj.formValues.password)
      if (propsobj.formValues.password != "" && phoneCheck == false) {
        let obj = {
          name: 'password',
          flag: true,
          text: "Incorrect Password",
        }
        propsobj.updateErrorObj(obj);
        return;
      } else {
        let obj = {
          name: 'password',
          flag: false,
          text: ''
        }
        propsobj.updateErrorObj(obj);
        phoneCheck = true
      }
    }
    let setGo = await hocObj.handleSubmit('loginForm', event);
    if (setGo) {
      setProgress(true);
      let res = await loginApi(propsobj.formValues, false, '');
      console.log(res, "res")
      setProgress(false);
      if (res.error) {
        setApiError(true);
        setErrMsg(res.error)
        setTimeout(function () { setApiError(false) }, 1000 * 30);
        setProgress(false);
      } else {
        callActualMethod();
      }
    }
  }

  const callActualMethod = () => {
    try {
      let whichApp = localStorage.getItem('whichApp');
      propsobj.UpdateFormField({ name: "selectedLang", value: lang })
      switch (whichApp) {
        case 'Site':
          callGetUserDetails('Site');
          break;
        case 'Patient':
          patient_callGetUserDetails();
          break;
        case 'PAG':
          callGetUserDetails('PAG');
          break;
        case 'Clinic':
          callGetUserDetails('Clinic');
          break;
        case 'Hospital':
          callGetUserDetails('Hospital');
          break;
        case 'Admin':
          callAdminDetails('Admin');
          break;

      }
    } catch (error) {

    }
  }

  const callAdminDetails = () => {
    propsobj.history.push('/admin/admin/dashboard');
  }

  const patient_callGetUserDetails = async () => {
    let requestBody = `
        query {
        getPatientDetails {
            patientId
            subjectId
            alternateId
            firstName
            middleName
            lastName
            email
            phone
            address1
            address2
            city
            state
            zip
            country
            language
            }
        }
    `;

    requestBody = JSON.stringify({ query: requestBody });
    setProgress(true);

    let res = await oldgenericApi('getPatientDetails', requestBody);
    setProgress(false);

    //if token not validated allow user to get new token by showing link expired, click now to activate again
    if (!res) {
      console.log('Error')
    } else {
      let data = res.data.getPatientDetails;
      console.log(data, "apiuser")
      localStorage.setItem("loggedInUser", data.firstName)
      localStorage.setItem("loggedUserEmail", data.email)
      localStorage.setItem('loginUserId', data.patientId)
    }
    propsobj.history.push('/patient');
  }

  const callGetUserDetails = async (appName) => {
    let requestBody = `
                query {
                   getTobeActivatedAccountUser {
                             sponsorAccountUserId,
                               sponsorAccountId,
                               SponsorAccountName,
                               firstName,
                               middleName,
                               lastName,
                               email,
                               phone,
                               role,
                               language,
                               portalAccessInd,
                               invitedBy,
                               invitedDate,
                               activated,
                               primaryContactInd
                       }
                   }
               `;

    requestBody = JSON.stringify({ query: requestBody });
    setProgress(true);

    let res = await oldgenericApi('getTobeActivatedAccountUser', requestBody);
    setProgress(false);

    //if token not validated allow user to get new token by showing link expired, click now to activate again
    if (!res) {
      console.log('Error')
    } else {
      let data = res.data.getTobeActivatedAccountUser;
      console.log(data, "apiuser")
      localStorage.setItem("loggedInUser", data.firstName)
      localStorage.setItem("loggedUserEmail", data.email)
      localStorage.setItem('loginUserId', data.sponsorAccountUserId)
      localStorage.setItem('siterole', data.role)
    }

    // propsobj.clearForm();
    if (appName === 'Site') { propsobj.history.push('/site/siteselection'); }
    if (appName != 'Site') { propsobj.history.push('/app/app'); }
    // } else {
    //     setApiError(true)
    //     setErrMsg("Invalid credentials")
    // }
  }


  let textProps = {
    ...hocObj,
    propsobj
  }

  useEffect(async () => {
    jwt.setCookie('jwtToken', '', 0);//delete jwt token if any
    localStorage.removeItem("roles");
    let localizedata = await LocalizationAPiCall(lang)
    console.log(localizedata, "localizedata")
    propsobj.localizationdata(localizedata)
  }, []);


  const forgotpwd = (e) => {
    e.preventDefault()
    setShowPage({
      ...showPage,
      ...showPages,
      forgotPwd: true
    })
  }

  const back = () => {
    setShowPage({
      ...showPage,
      ...showPages,
      login: true
    })
  }

  const responseGoogle = async (response) => {
    console.log(response.tokenObj, "google");
    setProgress(true);
    if (response) {
      let res = await loginApi(propsobj.formValues, "Google", response.tokenObj.id_token);
      if (res.error) {
        setApiError(true);
        setErrMsg(res.error)
        setTimeout(function () { setApiError(false) }, 1000 * 30);
        setProgress(false);
      } else {
        callActualMethod();
      }
    }
  }


  const responseFacebook = async (response) => {
    console.log(response);
    if (response) {
      let res = await loginApi(propsobj.formValues, "Facebook", response.accessToken);
      if (res.error) {
        setApiError(true);
        setErrMsg(res.error)
        setTimeout(function () { setApiError(false) }, 1000 * 30);
        setProgress(false);
      } else {
        callActualMethod();
      }
    }
  }
  console.log(lang, "lang")

  const dropdown = () => {
    setIsOpen(!isOpen);
  }
  return (
    <>
      {progress && <div className="fullScreen">
        <CircularProgress
          disableShrink
          className="progressCircle"
          value={90}
          size={100}
          thickness={1.5}

        />
      </div>}
      <div className="language-selector" style={{ textAlign: 'right', paddingTop: '10px' }}>
        <select
          id="language-dropdown"
          value={lang}
          onChange={handleLanguageChange}
        >
          <option value="en_US">English - EN</option>
          <option value="es_ES">Español - ES</option>
          <option value="fr_FR">Français - FR</option>
          <option value="ar_AA">العربية - AR</option>
          <option value="de_DE">Deutsch - GE</option>
        </select>


      </div>
      <div className="card" style={{ border: "0px" }}>
        <div className="card-body">
          {/* <div className="logo-primary mt-2 mb-4">
            <img src={SmallLogo} alt="logo-small" />
          </div> */}
          <div className="h-tabs-1">
            <ul className="nav nav-tabs nav-fill" id="login-register" role="tablist">
              <li className="nav-item" onClick={() => window.location.reload()
              }>
                <a className="nav-link active" id="login-tab" data-toggle="tab" href="#login" role="tab" aria-controls="login" aria-selected="true">
                  {propsobj.langData?.length > 0 && propsobj.langData.map(item => { if (item.keyword == "signin_signin_lbl") { return item[lang] } })}
                </a>
              </li>
              <li className="nav-item">
                <a className="nav-link" id="signup-tab" data-toggle="tab" href="#signup" role="tab" aria-controls="signup" aria-selected="false"> {propsobj.langData && propsobj.langData.map(item => { if (item.keyword == "signin_signup_lbl") { return item[lang] } })}</a>
              </li>
            </ul>
            <div className="tab-content pt-4" id="login-register-tab">
              <div className="tab-pane fade show active" id="login" role="tabpanel" aria-labelledby="login-tab">

                {apiError && <>
                  <div className="hekError">
                    <span className='icon'>
                      <ErrorIcon />
                    </span>
                    <span className='texts'>
                      {/* {errMsg} */}
                      Invalid username or password
                    </span>
                  </div>
                </>}
                <form noValidate autoComplete="off" id="loginForm" className="loginForm" onSubmit={formSubmit}>

                  <InputField {...textProps} textDirection={lang === "ar_AA" ? true : false} name="username" label={propsobj.langData?.length > 0 && propsobj.langData.map(item => { if (item.keyword == "signin_username_lbl") { return item[lang] } })} must={'true'} type="text" />

                  <PasswordField {...textProps} textDirection={lang === "ar_AA" ? true : false} handleClickShowPassword={handleClickShowPassword} handleMouseDownPassword={handleMouseDownPassword} name="password" showPassword={showPassword} label={propsobj.langData && propsobj.langData.map(item => { if (item.keyword == "signin_password_lbl") { return item[lang] } })} must={'true'} type="password" />

                  <div style={{ display: "inline-block" }}>
                    {/* <div className="text-right mb-3 forgotpwd" >
                    
                    </div> */}
                    <div className="form-group form-check mt-4 mb-2">
                      <input type="checkbox" className="form-check-input" id="exampleCheck1" />
                      <label className="form-check-label" htmlFor="exampleCheck1">
                        {propsobj.langData?.length > 0 && propsobj.langData.map(item => { if (item.keyword == "signin_keep_logged_in_lbl") { return item[lang] } })}                        </label>
                      <span className="forgotpwd" style={{ float: 'right' }}>
                        <a href="#" className="form-link" >{propsobj.langData?.length > 0 && propsobj.langData.map(item => { if (item.keyword == "signin_forgot_password_lbl") { return item[lang] } })}?</a>
                      </span>
                    </div>
                  </div>
                  <div className="form-group">
                    <button type="submit" className="btn btn-primary-blue btn-rounded btn-large btn-block">{propsobj.langData?.length > 0 && propsobj.langData.map(item => { if (item.keyword == "signin_signin_lbl") { return item[lang] } })} </button>
                  </div>
                </form>

                <div className="social-login mb-2">
                  <ul className="list-inline d-flex justify-content-between align-items-center"  >
                    <li className="list-inline-item static-link-txt" style={{ whiteSpace: 'nowrap' }}>{propsobj.langData?.length > 0 && propsobj.langData.map(item => { if (item.keyword == "sigin_or_sigin_using_lbl") { return item[lang] } })}</li>
                    <li className="list-inline-item">
                      {/* <a href="#" className="social-link"><span className="social-icon mr-1 facebook"></span>Facebook</a> */}
                      <FacebookLogin
                        appId="1631763070619665"
                        // autoLoad={true}
                        size="small"
                        fields="name,email,picture"
                        style={{ whiteSpace: 'nowrap' }}
                        // cssClass="my-facebook-button-class"
                        icon="fa-facebook"
                        textButton={propsobj.langData?.length > 0 && propsobj.langData.map(item => { if (item.keyword == "signin_signin_lbl") { return item[lang] } })}
                        // onClick={componentClicked}
                        callback={responseFacebook} />
                    </li>
                    <li className="list-inline-item google" style={{ whiteSpace: 'nowrap' }}>
                      {/* <a href="#" className="social-link"><span className="social-icon mr-1 google"></span>Google</a> */}
                      <GoogleLogin
                        clientId="188642766391-dpunmqp1q1uohi1ri3o9r9dns6lhl7f4.apps.googleusercontent.com"
                        buttonText={propsobj.langData?.length > 0 && propsobj.langData.map(item => { if (item.keyword == "signin_signin_lbl") { return item[lang] } })}
                        onSuccess={responseGoogle}
                        // onFailure={responseGoogle}

                        cookiePolicy={'single_host_origin'}
                      />
                    </li>
                  </ul>
                </div>
              </div>
              <div className="tab-pane fade" id="signup" role="tabpanel" aria-labelledby="signup-tab"><SignUpForm /></div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

const mapStateToProps = (state) => {
  return {
    formValues: state.form,
    langData: state.langData,
    lang: state.lang,
    state
  }
}
const mapDispatchToProps = (dispatch) => {
  return {
    UpdateFormField: (obj) => {
      return dispatch(
        {
          type: 'UpdateFormField',
          value: obj.value,
          name: obj.name
        }
      )
    },
    clearForm: () => {
      return dispatch(
        {
          type: 'ClearForm',
        }
      )
    },
    updateErrorObj: (obj) => {
      return dispatch(
        {
          type: 'updateErrorObj',
          flag: obj.flag,
          name: obj.name,
          text: obj.text
        }
      )
    },
    updateEntireErrorObj: (errobj, errtextobj) => {
      return dispatch(
        {
          type: 'updateEntireErrorObj',
          errobj: errobj,
          errtextobj: errtextobj,
        }
      )
    },
    localizationdata: (data) => {
      return dispatch({
        type: 'localizationdata',
        value: data
      }
      )
    },
    setLang: (data) => {
      return dispatch({
        type: 'setLang',
        value: data
      }
      )
    }
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(LoginForm));



